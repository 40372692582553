import { Context } from 'frontend-container/components/Menu/components/Context';
import { isGlobalRegion } from 'frontend-container/components/Menu/utils/isGlobalRegion';
import { isModuleEditableInGlobalRegionOnly } from 'frontend-container/components/Menu/utils/isModuleEditableInGlobalRegionOnly';
import { findRegionData } from 'frontend-container/utils/region/findRegion';
import { redirectToRegion } from 'frontend-container/utils/region/redirectToRegion';

import { getCurrentRegionCode } from '@ac/library-utils/dist/utils/multi-region';

/**
 * Don't redirect if any tenant property is assigned to selected region
 * and module is editable in current region.
 * Redirect if currently selected property is part of other region
 */
export const redirectToProperRegionIfNecessary = (
  region: ReturnType<typeof findRegionData>,
  context: Context | undefined,
  contexts: Context[]
): void => {
  const currentRegionCode = getCurrentRegionCode();
  const tenantHasContextInCurrentRegion = contexts.some(
    (contextToCheck) => contextToCheck.regionCode === currentRegionCode
  );

  // Don't redirect if any tenant property is assigned to selected region
  // and module is editable in current region.
  if (
    tenantHasContextInCurrentRegion &&
    !isModuleEditableInGlobalRegionOnly()
  ) {
    return;
  }

  if (
    isModuleEditableInGlobalRegionOnly() &&
    isGlobalRegion(currentRegionCode)
  ) {
    return;
  }

  if (
    tenantHasContextInCurrentRegion &&
    context?.regionCode === currentRegionCode
  ) {
    return;
  }

  if (region && context?.id && !tenantHasContextInCurrentRegion) {
    redirectToRegion(region.code ?? '', region.domain ?? '', context);
  }
};
