import { MenuElement } from 'frontend-container/components/Menu/types';

import { FeatureToggleName } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const resourceManagement2Menu: MenuElement = {
  translation: 'MENU.RESOURCE_MANAGEMENT.TITLE',
  icon: IconName.concierge,
  id: 'menu-resource-management2',
  items: [
    {
      link: `${acConfig.newFrontendUrls.resourceManagement}/leave-calendar`,
      translation: 'MENU.RESOURCE_MANAGEMENT.ITEMS.LEAVE_CALENDAR',
    },
    {
      link: `${acConfig.newFrontendUrls.resourceManagement}/resources`,
      translation: 'MENU.RESOURCE_MANAGEMENT.ITEMS.RESOURCES',
    },
  ],
  featureToggles: [FeatureToggleName.ResourceManagementModule],
};
