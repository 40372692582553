import {
  isCroLandingPage,
  isLandingPage,
} from 'frontend-container/components/LandingPage/location';
import { getAllAccessConfiguration } from 'frontend-container/components/Menu/authorization/accessConfiguration';
import { getAllowedMenuItems } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import {
  CentralReservationOfficeDefaultLandingPage,
  SystemDefaultLandingPage,
} from 'frontend-container/components/Menu/components/User/UserPreferences/configuration';
import { getAllowedMainApplicationMenu } from 'frontend-container/components/Menu/configuration';
import { centralAvailabilityInquiryMenu } from 'frontend-container/components/Menu/configuration/centralAvailabilityInquiry';
import { customersDashboard } from 'frontend-container/components/Menu/configuration/customers';
import { reservationsLookupMenu } from 'frontend-container/components/Menu/configuration/reservationsLookup';

import { UserPreferences } from '@ac/library-api';

export const processLandingPagePathname = (pathname: string): string => {
  if (!isLandingPage(pathname)) {
    return pathname;
  }

  const { userDetails, userPreferences } = userService.getFullCurrentUser();

  if (userDetails.isSystemUser) {
    return getDefaultSystemLandingPageRoute();
  }

  return isCroLandingPage(pathname)
    ? getDefaultCROLandingPageRoute(userPreferences)
    : getDefaultPropertyLandingPageRoute(userPreferences);
};

const getDefaultSystemLandingPageRoute = (): string => {
  return customersDashboard.link;
};

const getDefaultPropertyLandingPageRoute = (
  userPreferences: UserPreferences | undefined
): string => {
  const defaultLandingPageCode =
    userPreferences?.preferences?.defaultLandingScreen ||
    SystemDefaultLandingPage.code;

  const allowedMenuElements = getAllowedMainApplicationMenu(
    getAllAccessConfiguration()
  );
  const allowedMenuItems = allowedMenuElements.flatMap(
    (menuElement) => menuElement.items
  );
  const relatedItem = allowedMenuItems.find(
    (item) => item.code === defaultLandingPageCode
  );

  return (
    relatedItem?.link ??
    allowedMenuItems[0]?.link ??
    SystemDefaultLandingPage.pathname
  );
};

const getDefaultCROLandingPageRoute = (
  userPreferences: UserPreferences | undefined
): string => {
  const defaultLandingPageCode =
    userPreferences?.preferences
      ?.defaultCentralReservationOfficeLandingScreen ||
    CentralReservationOfficeDefaultLandingPage.code;

  /** Custom menu elements to exclude configuration links as we don't want to change context */
  const menuElements = [centralAvailabilityInquiryMenu, reservationsLookupMenu];

  const allAccessConfiguration = getAllAccessConfiguration();
  const allowedMenuElements = getAllowedMenuItems(
    menuElements,
    allAccessConfiguration
  );
  const allowedMenuItems = allowedMenuElements.flatMap(
    (menuElement) => menuElement.items
  );
  const relatedItem = allowedMenuItems.find(
    (item) => item.code === defaultLandingPageCode
  );

  return (
    relatedItem?.link ??
    allowedMenuItems[0]?.link ??
    CentralReservationOfficeDefaultLandingPage.pathname
  );
};
