import { IconName } from '@ac/web-components';

export enum ContextType {
  PROPERTY = 'Property',
  CRO = 'CentralReservationOffice',
}

export interface Context {
  id: string;
  code: string;
  name?: string;
  regionCode?: string;
  type: ContextType;
  icon: IconName;
}
