import { Api } from 'frontend-container/publicApi';
import { getUnitForCurrentLocation } from 'frontend-container/shared/businessContext/getUnitForCurrentLocation';

import { BusinessContextUnitType, WebSocketFactory } from '@ac/library-api';
import { getGlobalACPNamespace } from '@ac/library-utils/dist/utils';

export const setUpWebSocketsApi = (): void => {
  const acp = getGlobalACPNamespace();
  const api = (acp.api ?? { webSockets: undefined }) as Api;

  const unit = getUnitForCurrentLocation();

  if (unit.type === BusinessContextUnitType.Property && unit.id) {
    api.webSockets = new WebSocketFactory().create({
      unitType: unit.type,
      unitId: unit.id,
      baseWebSocket: api.webSockets,
    });
  } else if (
    unit.type === BusinessContextUnitType.Tenant ||
    unit.type === BusinessContextUnitType.CentralReservationOffice
  ) {
    api.webSockets = new WebSocketFactory().create({
      unitType: unit.type,
      baseWebSocket: api.webSockets,
    });
  }
};
