import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoginLogoutLayoutBase } from 'frontend-container/components/LoginLogout/LoginLogoutLayoutBase';
import { getLoginBrandingFromStorage } from 'frontend-container/components/Menu/components/Branding/brandingSessionStorage';
import { popStoredURL } from 'frontend-container/utils/loginService/logoutService';

import { acConfig } from '@ac/library-utils/dist/declarations';
import { withTranslationsReady } from '@ac/react-infrastructure';

const Logout = (): JSX.Element => {
  const { t } = useTranslation();
  const loginBranding = getLoginBrandingFromStorage();

  const backgroundImageUrl = loginBranding?.loginBackgroundUrl;
  const customerLogoUrl = loginBranding?.loginLogoUrl;

  return (
    <LoginLogoutLayoutBase
      title={t('LOGIN_LOGOUT.LOGOUT.TITLE')}
      message={t('LOGIN_LOGOUT.LOGOUT.MESSAGE')}
      onBackToLoginClick={(): string =>
        (window.location.href = `${acConfig.containerFrontendUrl}${
          popStoredURL() || ''
        }`)
      }
      backgroundImageUrl={backgroundImageUrl}
      customerLogoUrl={customerLogoUrl}
    />
  );
};

const LogoutWithTranslations = withTranslationsReady(Logout);

export { LogoutWithTranslations as Logout };
