import { acConfig } from '@ac/library-utils/dist/declarations';

export const isIntegrationsModule = (
  pathname: string = window.location.pathname
): boolean => pathname?.startsWith(acConfig.newFrontendUrls.integrations ?? '');

export const isIntegrationsModuleWithoutProperty = (
  pathname: string = window.location.pathname
): boolean =>
  isIntegrationsModule(pathname) && !isIntegrationsModuleWithProperty();

export const isIntegrationsModuleWithProperty = (
  pathname: string = window.location.pathname
): boolean =>
  isIntegrationsModule(pathname) &&
  (pathname?.includes('exports/dashboard') ||
    pathname?.includes('data-migration'));

export default {
  isIntegrationsModule,
  isIntegrationsModuleWithoutProperty,
};
