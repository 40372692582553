import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  propertyPermissionKeys,
  ReservationSettingCode,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const reservationPermissions = propertyPermissionKeys.reservations;

export const frontdeskMenu: MenuElement = {
  translation: 'MENU.FRONTDESK.TITLE',
  icon: IconName.frontdesk,
  id: 'menu-frontdesk',
  items: [
    {
      link: `${acConfig.newFrontendUrls.reservations}/frontdesk`,
      code: 'FrontDeskDashboard',
      translation: 'MENU.FRONTDESK.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [reservationPermissions.dashboard.viewDashboard],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+f',
      keyboardShortcutMac: '⌥+⌘+f',
      keyCode: 70,
    },
    {
      link: `${acConfig.newFrontendUrls.reports}/house-status-dashboard`,
      code: 'HouseStatus',
      translation: 'MENU.FRONTDESK.ITEMS.HOUSE_STATUS_DASHBOARD',
      keyboardShortcutWindows: 'alt+ctrl+s',
      keyboardShortcutMac: '⌥+⌘+s',
      keyCode: 83,
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/:id/room-planner`,
      code: 'RoomPlanner',
      translation: 'MENU.FRONTDESK.ITEMS.ROOM_PLANNER',
      permissionsConfiguration: {
        permissions: [reservationPermissions.dashboard.viewDashboard],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [ReservationSettingCode.IsRoomPlannerActive],
      keyboardShortcutWindows: 'alt+ctrl+l',
      keyboardShortcutMac: '⌥+⌘+l',
      keyCode: 76,
    },
    {
      link: `${acConfig.newFrontendUrls.reservations}/reservations/walk-in`,
      translation: 'MENU.FRONTDESK.ITEMS.WALK_IN',
      permissionsConfiguration: {
        permissions: [
          reservationPermissions.reservation.manageReservation,
          reservationPermissions.reservation.checkInReservation,
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+/',
      keyboardShortcutMac: '⌥+⌘+/',
      keyCode: 191,
    },
    {
      link: `${acConfig.newFrontendUrls.reservations}/queue-rooms`,
      translation: 'MENU.FRONTDESK.ITEMS.QUEUE_ROOMS',
      permissionsConfiguration: {
        permissions: [propertyPermissionKeys.queueRooms.viewDashboard],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [ReservationSettingCode.queueRooms],
    },
    {
      link: `${acConfig.newFrontendUrls.individualReservations}/guest-messages`,
      translation: 'MENU.FRONTDESK.ITEMS.GUEST_MESSAGES',
      permissionsConfiguration: {
        permissions: [reservationPermissions.guestMessages.accessMessageCenter],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [ReservationSettingCode.GuestMessage],
      keyboardShortcutWindows: 'alt+ctrl+m',
      keyboardShortcutMac: '⌥+⌘+m',
      keyCode: 77,
    },
    {
      link: `${acConfig.newFrontendUrls.individualReservations}/wake-up-calls`,
      translation: 'MENU.FRONTDESK.ITEMS.WAKE_UP_CALLS',
      permissionsConfiguration: {
        permissions: [reservationPermissions.reservation.viewReservation],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [ReservationSettingCode.WakeUpCalls],
      keyboardShortcutWindows: 'alt+ctrl+w',
      keyboardShortcutMac: '⌥+⌘+w',
      keyCode: 87,
    },
  ],
};
