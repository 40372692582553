import { isSafariBrowser } from 'frontend-container/components/Menu/components/Logo/MaintenanceModal/getUserEnvironmentDetails';
import { shouldUseNewMenu } from 'frontend-container/components/Menu/service';

const isSafari = isSafariBrowser();

const useNew = shouldUseNewMenu();
export const scrollToSelectedMenuItem = (id: string): void => {
  const element = document.getElementById(id);
  const parent = document.querySelector('.items-container');
  // bugs in safari - JS scroll does not work when 'smooth' scroll is set
  // https://bugs.webkit.org/show_bug.cgi?id=242224
  // https://bugs.webkit.org/show_bug.cgi?id=238497
  const scrollBehavior: ScrollBehavior = isSafari ? 'auto' : 'smooth';
  if (element) {
    if (useNew) {
      const count = element.offsetTop - (parent?.scrollTop ?? 0);
      parent?.scrollBy({ top: count, left: 0, behavior: scrollBehavior });
    } else {
      const count = element.offsetLeft - (parent?.scrollLeft ?? 0) - 4;
      parent?.scrollBy({ top: 0, left: count, behavior: scrollBehavior });
    }
  }
};
