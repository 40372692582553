import React, { FC } from 'react';
import { getEmberAppLoadingStatus } from 'frontend-container/utils/externalResources/ember';
import { mountRootParcel } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';
import { safeLoadReactComponent } from '@ac/library-utils/dist/utils/micro-frontends/loading/component/react';
import { RenderExternalComponent } from '@ac/library-utils/dist/utils/micro-frontends/rendering/react';
import { ComponentToRender } from '@ac/library-utils/dist/utils/micro-frontends/rendering/shared';

const PANEL_COMPONENT_NAME = 'PublicPanelWrapper';

interface Props {
  isPanelVisible: boolean;
  isPropertyContext: boolean;
  onPanelClose: () => void;
  unreadNotificationsCount: number;
}

interface NotificationPanelRender extends ComponentToRender {
  name: string;
}

export const NotificationPanel: FC<Props> = ({
  isPanelVisible,
  isPropertyContext,
  onPanelClose,
  unreadNotificationsCount,
}) => {
  const loadNotificationsPanel = async (): Promise<NotificationPanelRender> => {
    await getEmberAppLoadingStatus();

    const parcelConfig = await safeLoadReactComponent(
      acConfig.frontendUrls.notifications,
      PANEL_COMPONENT_NAME
    );

    return {
      ...parcelConfig,
      name: PANEL_COMPONENT_NAME,
    };
  };

  return (
    <RenderExternalComponent
      loadComponent={loadNotificationsPanel}
      externalComponentProps={{
        isPanelVisible,
        isPropertyContext,
        onPanelClose,
        mountParcel: mountRootParcel,
        unreadNotificationsCount,
      }}
    />
  );
};
