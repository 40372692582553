import { useEffect, useState } from 'react';
import { fetchNotificationCount } from 'frontend-container/components/Menu/components/NotificationBell/notificationCountFetcher';
import { NotificationPanel } from 'frontend-container/components/Menu/components/NotificationPanel/NotificationPanel';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import { isPathnameWithoutProperty } from 'frontend-container/components/Menu/utils/isPathnameWithoutProperty';
import { isNotificationBellVisible } from 'frontend-container/components/Menu/utils/modules/notifications';
import { NotificationsNames } from 'frontend-container/shared/webSockets/types';
import debounce from 'lodash.debounce';

import { NotificationChannel } from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';
import { useTenantWebSocketClientsContext } from '@ac/react-infrastructure';
import {
  ButtonPattern,
  ButtonTheme,
  Components,
  CounterTheme,
  IconName,
} from '@ac/web-components';

export const isBellVisible = (): boolean =>
  LoginService.isLoggedIn() &&
  !LoginService.isSuperUser() &&
  userService.getCurrentUserDetails() &&
  isNotificationBellVisible();

export const NotificationBell = (): JSX.Element => {
  const [notificationsCount, setNotificationsCount] = useState<number>(0);
  const [isPanelVisible, setPanelVisibility] = useState<boolean>(false);

  const {
    state: { webSockets },
  } = useTenantWebSocketClientsContext();

  const isPropertyContext = !isPathnameWithoutProperty();

  useEffect(() => {
    const notificationChannels: NotificationChannel[] = [];

    (async (): Promise<void> => {
      notificationChannels.push(
        await webSockets.user.subscribe(
          { notificationType: NotificationsNames.UserNotificationCreated },
          debounced
        )
      );

      notificationChannels.push(
        await webSockets.user.subscribe(
          { notificationType: NotificationsNames.UserNotificationMarkedAsRead },
          debounced
        )
      );

      notificationChannels.push(
        await webSockets.user.subscribe(
          {
            notificationType: NotificationsNames.UserNotificationMarkedAsUnread,
          },
          debounced
        )
      );

      notificationChannels.push(
        await webSockets.user.subscribe(
          {
            notificationType: NotificationsNames.UserNotificationsMarkedAsRead,
          },
          debounced
        )
      );
    })();

    return (): void => {
      notificationChannels.forEach((channel: NotificationChannel) => {
        channel.unsubscribe();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async (): Promise<void> => updateNumberOfNotifications())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPropertyContext]);

  const closeNotificationsPanel = (): void => setPanelVisibility(false);
  const toggleNotificationsPanel = (): void =>
    setPanelVisibility(!isPanelVisible);

  const updateNumberOfNotifications = async (): Promise<void> => {
    const notificationCount = await fetchNotificationCount();
    setNotificationsCount(notificationCount.unreadCount);
  };

  const debounced = debounce(updateNumberOfNotifications, 500);

  const counter: Components.AcCounter = {
    count: notificationsCount,
    theme: CounterTheme.dark,
  };

  return (
    <ac-flex class="ac-spacing-right-sm">
      <ac-counter-container counter={counter}>
        <ac-button
          data-test-selector="notification-bell"
          theme={ButtonTheme.light}
          pattern={ButtonPattern.tertiary}
          onClick={toggleNotificationsPanel}
        >
          <ac-icon icon={IconName.bell} />
        </ac-button>
      </ac-counter-container>
      <NotificationPanel
        isPanelVisible={isPanelVisible}
        isPropertyContext={isPropertyContext}
        unreadNotificationsCount={notificationsCount}
        onPanelClose={closeNotificationsPanel}
      />
    </ac-flex>
  );
};
