import { isError403 } from 'frontend-container/components/Errors/Error403';
import { isError404 } from 'frontend-container/components/Errors/Error404';
import { isError503 } from 'frontend-container/components/Errors/Error503';
import { isLandingPage } from 'frontend-container/components/LandingPage/location';
import { isActivityConfigurationModule } from 'frontend-container/components/Menu/utils/modules/activityConfiguration';
import { isActivityReservationsModule } from 'frontend-container/components/Menu/utils/modules/activityReservations';
import { isConfigurationModule } from 'frontend-container/components/Menu/utils/modules/configuration';
import { isInventoryDashboardModule } from 'frontend-container/components/Menu/utils/modules/inventory';
import { isNotificationsModule } from 'frontend-container/components/Menu/utils/modules/notifications';
import { isPrestepNoPermissions } from 'frontend-container/components/Prestep/AccessDenied/NoPermissions/NoPermissions';
import { isPrestepPermissionsInProgress } from 'frontend-container/components/Prestep/AccessDenied/PermissionsInProgress';
import { isLoginUrl, isMainUrl } from 'frontend-container/utils/location';

export const isModuleWithoutMainMenuEntry = (): boolean =>
  isLandingPage() ||
  isConfigurationModule() ||
  isNotificationsModule() ||
  isError404() ||
  isError403() ||
  isError503() ||
  isPrestepNoPermissions() ||
  isPrestepPermissionsInProgress() ||
  isMainUrl() ||
  isLoginUrl() ||
  isActivityReservationsModule() ||
  isActivityConfigurationModule() ||
  isInventoryDashboardModule();
