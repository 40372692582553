import { ACPCustomEvents } from './../events';

export interface PropertyLocalDateTimeDetails {
  localPropertyDateTime: string;
}

export class PropertyLocalDateTimeUpdatedEvent extends CustomEvent<PropertyLocalDateTimeDetails> {
  constructor(localPropertyDateTime: string) {
    super(ACPCustomEvents.PropertyLocalDateTimeUpdated, {
      detail: { localPropertyDateTime },
    });
  }
}
