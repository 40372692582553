import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Props } from 'frontend-container/components/Menu/components/Logo/MaintenanceModal/types';
import { useVersions } from 'frontend-container/components/Menu/components/Logo/MaintenanceModal/useVersions';

import { acConfig } from '@ac/library-utils/dist/declarations';
import {
  IconName,
  InfotipSlot,
  OpeningBehavior,
  Orientation,
  TargetValueObject,
  TextSize,
  TextWeight,
} from '@ac/web-components';

export const MaintenanceModal: FC<Props> = ({ onClose }) => {
  const {
    backendVersions,
    frontendVersions,
    isLoading,
    frontendLibraryVersions,
    userEnvironmentDetails,
  } = useVersions();
  const { t } = useTranslation();
  const onCopyToClipboard = (): void => {
    navigator.clipboard.writeText(
      JSON.stringify(
        {
          BackendMicroserviceVersions: {
            ...backendVersions.reduce((aggregated, [name, version]) => {
              return {
                ...aggregated,
                [name]: version,
              };
            }, {}),
          },
          FrontendMicroservicesVersions: {
            ...frontendVersions.reduce((aggregated, feModule) => {
              const feLibraryVersions =
                frontendLibraryVersions[feModule.name] &&
                frontendLibraryVersions[feModule.name].length > 0 &&
                frontendLibraryVersions[feModule.name].reduce(
                  (aggregatedValue, next) => {
                    return {
                      ...aggregatedValue,
                      [t('MAINTENANCE_MODAL.BUILD_DATE')]: feModule.buildDate,
                      [next.name]: next.libraries,
                    };
                  },
                  {}
                );

              return {
                ...aggregated,
                [feModule.name]: {
                  version: feModule.version,
                  libraryVersions: {
                    feLibraryVersions,
                  },
                },
              };
            }, {}),
          },
          EnvironmentDetails: {
            ...userEnvironmentDetails.reduce((aggregated, environmentDatum) => {
              return {
                ...aggregated,
                [t(`MAINTENANCE_MODAL.${environmentDatum.name}`)]:
                  environmentDatum.details,
              };
            }, {}),
          },
        },
        undefined,
        '\t'
      )
    );
  };

  const orientation =
    acConfig.environmentType === 'cm' ||
    acConfig.environmentType === 'local' ||
    acConfig.environmentType === 'dev'
      ? Orientation.vertical
      : Orientation.horizontal;

  return (
    <ac-modal
      modalTitle={t('MAINTENANCE_MODAL.TITLE')}
      customButtons={[
        {
          content: {
            text: t('MAINTENANCE_MODAL.COPY_TO_CLIPBOARD'),
          },
          onClick: onCopyToClipboard,
        },
        {
          content: {
            text: t('COMMON.BUTTONS.OK'),
          },
          onClick: onClose,
        },
      ]}
    >
      <div>
        {isLoading && <ac-loader-covering />}
        <ac-box>
          <ac-text
            size={TextSize.lg}
            weight={TextWeight.semibold}
            class="ac-spacing-right-lg"
          >
            {t('MAINTENANCE_MODAL.BROWSER_SYSTEM_DETAILS')}
          </ac-text>
          <ac-infotip
            for="#user-environment-information"
            openingBehavior={OpeningBehavior.onMouseOver}
            targetValue={TargetValueObject.modal}
          >
            <ac-icon
              icon={IconName.information}
              id="user-environment-information"
              slot={InfotipSlot.component}
              class="user-environment-information"
              data-test-selector="user-environment-information"
            />
            <ac-box class="ac-padding-lg" slot={InfotipSlot.content}>
              {userEnvironmentDetails?.map((environmentDatum) => (
                <ac-paragraph
                  key={environmentDatum.name}
                  class="environment-details"
                >
                  <ac-text-group
                    label={t(`MAINTENANCE_MODAL.${environmentDatum.name}`)}
                    sizeLabel={TextSize.lg}
                    class="ac-spacing-bottom-sm"
                  >
                    {environmentDatum.details}
                  </ac-text-group>
                </ac-paragraph>
              ))}
            </ac-box>
          </ac-infotip>
        </ac-box>
        <ac-flex>
          <ac-box>
            <ac-paragraph>
              <ac-text
                size={TextSize.lg}
                weight={TextWeight.semibold}
                class="ac-spacing-bottom-sm"
              >
                {t('MAINTENANCE_MODAL.BACKEND_VERSIONS')}
              </ac-text>
            </ac-paragraph>
            {backendVersions.map(([name, version]) => (
              <ac-paragraph key={name} class="be-version">
                <ac-text-group
                  label={name}
                  sizeLabel={TextSize.md}
                  orientation={orientation}
                  class="break-word"
                >
                  {version}
                </ac-text-group>
              </ac-paragraph>
            ))}
          </ac-box>
          <ac-box>
            <ac-paragraph>
              <ac-text
                size={TextSize.lg}
                weight={TextWeight.semibold}
                class="ac-spacing-bottom-sm"
              >
                {t('MAINTENANCE_MODAL.FRONTEND_VERSIONS')}
              </ac-text>
            </ac-paragraph>
            {frontendVersions.map((feModule) => (
              <ac-paragraph key={feModule.name} class="fe-version">
                <ac-text-group
                  label={feModule.name}
                  sizeLabel={TextSize.md}
                  orientation={orientation}
                  class="break-word"
                >
                  {feModule.version}

                  {frontendLibraryVersions[feModule.name] &&
                    frontendLibraryVersions[feModule.name].length > 0 && (
                      <ac-infotip
                        for={`#libraries-versions-icon-${feModule.name}`}
                        targetValue={TargetValueObject.modal}
                        openingBehavior={OpeningBehavior.onMouseOver}
                        class="fe-lib-version-container"
                      >
                        <ac-icon
                          icon={IconName.information}
                          id={`libraries-versions-icon-${feModule.name}`}
                          class={`ac-spacing-left-sm libraries-versions-icon-${feModule.name}`}
                          slot="component"
                          data-test-selector={`libraries-versions-icon-${feModule.name}`}
                        />
                        <div className="ac-spacing-lg">
                          <ac-text-group
                            label={t('MAINTENANCE_MODAL.INFO_TITLE')}
                            sizeLabel={TextSize.lg}
                            orientation={Orientation.vertical}
                            class="ac-spacing-bottom-sm"
                          >
                            {`${t('MAINTENANCE_MODAL.BUILD_DATE')}: ${
                              feModule.buildDate
                            }`}
                          </ac-text-group>
                          {frontendLibraryVersions[feModule.name].map(
                            (library) => (
                              <ac-paragraph
                                key={library.name}
                                class="fe-library-version"
                              >
                                <ac-text-group
                                  label={library.name}
                                  sizeLabel={TextSize.lg}
                                  orientation={Orientation.vertical}
                                >
                                  {library.libraries &&
                                    library.libraries.length > 0 &&
                                    library.libraries.map((libraryVersion) => (
                                      <ac-paragraph key={libraryVersion.name}>
                                        <ac-text-group
                                          label={libraryVersion.name}
                                          sizeLabel={TextSize.md}
                                        >
                                          {libraryVersion.version}
                                        </ac-text-group>
                                      </ac-paragraph>
                                    ))}
                                </ac-text-group>
                              </ac-paragraph>
                            )
                          )}
                        </div>
                      </ac-infotip>
                    )}
                </ac-text-group>
              </ac-paragraph>
            ))}
          </ac-box>
        </ac-flex>
      </div>
    </ac-modal>
  );
};
