import { CSSProperties, FC, useMemo } from 'react';
import { CSS_VAR_MENU_BAR_COLOR } from 'frontend-container/components/Menu/constants';
import { getCssVariable } from 'frontend-container/components/Menu/utils/getCssVariable';

import { isDarkBackground } from '@ac/library-utils/dist/utils';
import {
  AlignItems,
  Color,
  FlexDirection,
  Size,
  TextColor,
} from '@ac/web-components';

import { Context } from '../../context';

import { BusinessDate } from './BusinessDate';

import './ContextDropdown.scss';

interface Props {
  id?: string;
  context?: Context;
  businessDate?: string;
  dateFormat?: string;
  propertyTime?: string;
  color?: string | Color | TextColor;
  isListVisible?: boolean;
  onClick?: () => void;
}

export const ContextDropdown: FC<Props> = ({
  id = 'context-dropdown-button',
  context,
  businessDate,
  dateFormat,
  propertyTime,
  color = getCssVariable(CSS_VAR_MENU_BAR_COLOR),
  isListVisible,
  onClick,
}) => {
  const textColor = useMemo(() => {
    return isDarkBackground(color) ? TextColor.white : TextColor.black;
  }, [color]);

  const iconColor = textColor === TextColor.white ? Color.white : Color.black;

  const classes = [
    'context-dropdown',
    isListVisible ? 'context-dropdown-active' : '',
  ]
    .filter(Boolean)
    .join(' ');

  const code = context?.code ?? '';

  return (
    <button
      id={id}
      className={classes}
      style={{ '--context-color': color } as CSSProperties}
      onClick={onClick}
    >
      <ac-flex class="context-dropdown-content" alignItems={AlignItems.center}>
        {context && (
          <ac-icon
            class="ac-spacing-right-sm"
            icon={context.icon}
            color={iconColor}
            size={Size.lg}
          />
        )}
        <ac-flex
          class="context-dropdown-text"
          direction={FlexDirection.column}
          alignItems={AlignItems.flexStart}
        >
          <ac-text
            uppercase
            class="no-wrap context-dropdown-code"
            color={textColor}
          >
            {code}
          </ac-text>
          {businessDate && propertyTime && (
            <BusinessDate
              businessDate={businessDate}
              dateFormat={dateFormat}
              propertyTime={propertyTime}
              color={textColor}
            />
          )}
        </ac-flex>
      </ac-flex>
    </button>
  );
};
