import {
  MENU_OPENED_KEY,
  OpenCloseValues,
} from 'frontend-container/components/ContextHelp/constants';
import { unpinContextHelp } from 'frontend-container/components/ContextHelp/utils/pinningHelpers';
import { ACPCustomEvents } from 'frontend-container/publicApi';

export const closeContextHelp = (): void => {
  sessionStorage.setItem(MENU_OPENED_KEY, OpenCloseValues.Closed);
  unpinContextHelp();
  window.dispatchEvent(new CustomEvent(ACPCustomEvents.ContextHelpClosed));
  (
    window.ACP?.container ?? { isContextHelpOpened: undefined }
  ).isContextHelpOpened = false;
};

export const isOpenedByUser = (): boolean =>
  sessionStorage.getItem(MENU_OPENED_KEY) === OpenCloseValues.Open;
