import { useEffect, useMemo, useState } from 'react';
import { getCroItems } from 'frontend-container/components/Menu/components/CroContext/service';
import { getPropertyUnits } from 'frontend-container/components/Menu/components/PropertyContext/service';
import {
  fetchAllowedCroLandingPages,
  fetchAllowedPropertyLandingPages,
} from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/data/allowedLandingPagesFetcher';
import {
  fetchDictionaries,
  UserPreferencesFormDictionaries,
} from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/data/dictionariesFetcher';
import { getAllowedLanguages } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/data/getAllowedLanguages';
import {
  mapCroToSelectOption,
  mapDictionaryEntityToSelectOption,
  mapUnitToSelectOption,
} from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/data/mapToSelectOption';
import { Resource } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/data/resource';

import { isDefined } from '@ac/library-utils/dist/utils';
import { AcSelectOption } from '@ac/web-components';

const pendingResource: Resource = { status: 'pending' };
const initialResource: Resource = { status: 'initial' };
const emptyDictionaries: UserPreferencesFormDictionaries = {
  languages: initialResource,
  supportedUiLangages: initialResource,
  workspaces: initialResource,
  landingPages: initialResource,
  croLandingPages: initialResource,
};

export type PreferencesFormData = {
  languages: Resource<Array<AcSelectOption<string>>>;
  properties: Resource<Array<AcSelectOption<string>>>;
  centralReservationOffices: Resource<Array<AcSelectOption<string>>>;
  allowedLandingPages: Resource<Array<AcSelectOption<string>>>;
  allowedCroLandingPages: Resource<Array<AcSelectOption<string>>>;
  workspaces: Resource<Array<AcSelectOption<string>>>;
};

type UsePreferencesFormDataParams = {
  propertyId?: string;
  croId?: string;
  defaultLandingScreen?: string;
  defaultCentralReservationOfficeLandingScreen?: string;
};

export const usePreferencesFormData = ({
  propertyId,
  croId,
  defaultLandingScreen,
  defaultCentralReservationOfficeLandingScreen,
}: UsePreferencesFormDataParams): PreferencesFormData => {
  const [dictionaries, setDictionaries] =
    useState<UserPreferencesFormDictionaries>(emptyDictionaries);
  const [propertyLandingPages, setPropertyLandingPages] =
    useState<Resource<Array<AcSelectOption<string>>>>(initialResource);
  const [croLandingPages, setCroLandingPages] =
    useState<Resource<Array<AcSelectOption<string>>>>(initialResource);

  const [properties, centralReservationOffices] = useMemo(() => {
    const propertyOptions = getPropertyUnits()
      .map((unit) => mapUnitToSelectOption(unit))
      .filter(isDefined);

    const croOptions = getCroItems()
      .map((cro) => mapCroToSelectOption(cro))
      .filter(isDefined);

    return [
      { status: 'fulfilled', value: propertyOptions } as Resource<
        Array<AcSelectOption<string>>
      >,
      { status: 'fulfilled', value: croOptions } as Resource<
        Array<AcSelectOption<string>>
      >,
    ];
  }, []);

  const languages: Resource<Array<AcSelectOption<string>>> = useMemo(() => {
    const { languages: languageEntries, supportedUiLangages } = dictionaries;
    if (languageEntries.status !== 'fulfilled') {
      return languageEntries;
    }

    if (supportedUiLangages.status !== 'fulfilled') {
      return supportedUiLangages;
    }

    return {
      status: 'fulfilled',
      value: getAllowedLanguages(
        languageEntries.value,
        supportedUiLangages.value
      ),
    };
  }, [dictionaries]);

  const workspaces: Resource<Array<AcSelectOption<string>>> = useMemo(() => {
    const { workspaces: workspaceEntries } = dictionaries;

    if (workspaceEntries.status !== 'fulfilled') {
      return workspaceEntries;
    }

    return {
      value: workspaceEntries.value
        .map((item) => mapDictionaryEntityToSelectOption(item))
        .filter(isDefined),
      status: 'fulfilled',
    };
  }, [dictionaries]);

  useEffect(() => {
    const loadDictionaries = async (): Promise<void> => {
      const nextDictionaries = await fetchDictionaries();
      setDictionaries(nextDictionaries);
    };

    loadDictionaries();
  }, []);

  useEffect(() => {
    if (dictionaries.landingPages.status !== 'fulfilled') {
      setPropertyLandingPages(dictionaries.landingPages);

      return;
    }

    const allLandingPages = dictionaries.landingPages.value;
    if (!propertyId || allLandingPages.length === 0) {
      setPropertyLandingPages({ status: 'fulfilled', value: [] });

      return;
    }

    setPropertyLandingPages(pendingResource);

    const loadPropertyLandingPages = async (): Promise<void> => {
      const allowedPropertyLandingPages =
        await fetchAllowedPropertyLandingPages(
          propertyId,
          allLandingPages,
          defaultLandingScreen
        );

      setPropertyLandingPages(allowedPropertyLandingPages);
    };

    loadPropertyLandingPages();
  }, [propertyId, dictionaries, defaultLandingScreen]);

  useEffect(() => {
    if (dictionaries.croLandingPages.status !== 'fulfilled') {
      setPropertyLandingPages(dictionaries.croLandingPages);

      return;
    }

    const allLandingPages = dictionaries.croLandingPages.value;

    if (!croId || allLandingPages.length === 0) {
      setPropertyLandingPages({ status: 'fulfilled', value: [] });

      return;
    }

    setCroLandingPages(pendingResource);

    const loadCroLandingPages = async (): Promise<void> => {
      const allowedCroLandingPages = await fetchAllowedCroLandingPages(
        croId,
        allLandingPages,
        defaultCentralReservationOfficeLandingScreen
      );

      setCroLandingPages(allowedCroLandingPages);
    };

    loadCroLandingPages();
  }, [croId, dictionaries, defaultCentralReservationOfficeLandingScreen]);

  return {
    workspaces,
    languages,
    centralReservationOffices,
    properties,
    allowedLandingPages: propertyLandingPages,
    allowedCroLandingPages: croLandingPages,
  };
};
