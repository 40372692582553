import { useEffect } from 'react';
import { saveLoginBrandingToStorage } from 'frontend-container/components/Menu/components/Branding/brandingSessionStorage';
import { ACPCustomEvents } from 'frontend-container/publicApi';
import {
  Branding as BrandingType,
  loadBranding,
} from 'frontend-container/shared/branding/loadBranding';

const favicon = document.querySelector('#favicon');
const faviconLg = document.querySelector('#favicon-lg');
const DEFAULT_FAVICON_HREF = '/assets/favicon-shiji.png';
const DEFAULT_LG_FAVICON_HREF = '/assets/favicon-shiji-lg.png';

export const Branding = (): null => {
  useEffect(() => {
    const updateFavicon = (branding?: BrandingType): void => {
      if (branding?.favicon) {
        const faviconUrl = URL.createObjectURL(branding?.favicon);
        favicon?.setAttribute('href', faviconUrl);
        faviconLg?.setAttribute('href', faviconUrl);

        return;
      }

      favicon?.setAttribute('href', DEFAULT_FAVICON_HREF);
      faviconLg?.setAttribute('href', DEFAULT_LG_FAVICON_HREF);
    };

    const updateBranding = async (): Promise<void> => {
      const branding = await loadBranding();
      updateFavicon(branding);

      saveLoginBrandingToStorage({
        loginBackgroundUrl: branding?.loginBackgroundUrl,
        loginLogoUrl: branding?.loginLogoUrl,
      });
    };

    updateBranding();

    window.addEventListener(
      ACPCustomEvents.BusinessContextDataUpdated,
      updateBranding
    );

    return () => {
      window.removeEventListener(
        ACPCustomEvents.BusinessContextDataUpdated,
        updateBranding
      );
    };
  }, []);

  return null;
};
