import { acConfig } from '@ac/library-utils/dist/declarations';
import { RegExpUtils } from '@ac/library-utils/dist/utils';

const inventoryRegExp = RegExpUtils.interpolate`^${acConfig.newFrontendUrls.availability}/inventory.*$`;
const inventoryPropertyRegExp = RegExpUtils.interpolate`^${acConfig.newFrontendUrls.availability}/inventory(?!/dashboard).*$`;
const inventoryDashboardRegExp = RegExpUtils.interpolate`^${acConfig.newFrontendUrls.availability}/inventory/dashboard.*$`;

export const isInventoryModule = (
  pathname: string = window.location.pathname
): boolean => {
  return inventoryRegExp.test(pathname);
};

export const isPropertyDependentInventoryModule = (
  pathname: string = window.location.pathname
): boolean => {
  return inventoryPropertyRegExp.test(pathname);
};

export const isInventoryDashboardModule = (
  pathname: string = window.location.pathname
): boolean => {
  return inventoryDashboardRegExp.test(pathname);
};

export default {
  isInventoryModule,
  isPropertyDependentInventoryModule,
  isInventoryDashboardModule,
};
