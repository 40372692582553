import { reactPathnameAppNameMap } from 'frontend-container/config/reactPathnameAppNameMap';
import { getAppScopeFromPathname } from 'frontend-container/utils/getAppScopeFromPathname';
import { getAppStatus, NOT_LOADED } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

type WindowWithSystemJS = {
  System: typeof System;
  webpackJsonp?: JSON;
} & typeof window;

export const clearSystemJSForNewLinkIfFEAppNotYetLoaded = (
  newLink: string
): void => {
  const scope = getAppScopeFromPathname(newLink);
  const appName = reactPathnameAppNameMap[scope];
  if (appName) {
    const SystemJS: typeof System = (window as WindowWithSystemJS).System;
    let file: string | undefined;
    const frontendUrlsName = Object.entries(acConfig.newFrontendUrls).find(
      ([, value]) => value === scope
    )?.[0];
    if (frontendUrlsName) {
      for (const [id] of SystemJS.entries()) {
        if (id.startsWith(acConfig.frontendUrls[frontendUrlsName])) {
          file = id;
        }
      }
      if (file && getAppStatus(appName) === NOT_LOADED) {
        SystemJS.delete(file);
        delete (window as WindowWithSystemJS).webpackJsonp;
      }
    }
  }
};
