import {
  ContextHelpSidePanelBodyId,
  ContextHelpSidePanelId,
  MENU_OPENED_KEY,
  OpenCloseValues,
} from 'frontend-container/components/ContextHelp/constants';
import { getCSHID } from 'frontend-container/components/ContextHelp/utils/getCSHID';

import { acConfig } from '@ac/library-utils/dist/declarations';
import { repeatableCall } from '@ac/library-utils/dist/utils';

export const openHelpTopic = async (topicToOpen?: string): Promise<void> => {
  const cshid = await getCSHID(topicToOpen);
  await repeatableCall(
    () => new Promise((resolve) => resolve(true)),
    () =>
      !!document.querySelector(`#${ContextHelpSidePanelId}.hydrated`) || false,
    {
      intervalTime: 500,
      repeatCount: 50,
    }
  );
  const sidePanel = document.getElementById(ContextHelpSidePanelId);
  if (sidePanel) {
    (
      window.ACP?.container ?? { isContextHelpOpened: undefined }
    ).isContextHelpOpened = true;
    sessionStorage.setItem(MENU_OPENED_KEY, OpenCloseValues.Open);
    const a = document.createElement('a');
    a.target = ContextHelpSidePanelBodyId;
    a.href = getHelpUrl(cshid);
    sidePanel.appendChild(a);
    a.click();
    sidePanel.removeChild(a);
  }
};

export const getHelpUrl = (cshid?: number): string =>
  `${acConfig.frontendUrls.userDocumentation}/index.html${
    cshid ? `#cshid=${cshid}` : ''
  }`;
