import { handleNoUnitsOrPermissions } from 'frontend-container/components/LoginLogout/utils/handleNoUnitsOrPermissions';
import { SystemDefaultLandingPage } from 'frontend-container/components/Menu/components/User/UserPreferences/configuration';
import { getCustomerContextData } from 'frontend-container/shared/businessContext/getBusinessContext';
import { navigateToUrl } from 'single-spa';

import {
  CentralReservationOfficeUserListItemDto,
  EffectiveSettingDetails,
  GeneralCustomerSettingsKeys,
} from '@ac/library-api';

import { Context, ContextMapper } from '../Context';

export const isCroEnabled = (): boolean => {
  const customerContext = getCustomerContextData();
  const settings = customerContext?.settings.effectiveSettings;

  if (!settings) {
    return false;
  }

  const croSetting = settings.find(
    (item) => item.code === GeneralCustomerSettingsKeys.CentralReservationOffice
  ) as EffectiveSettingDetails<{ value: boolean }> | undefined;

  return croSetting?.value?.value === true;
};

export const getCroItems = (): CentralReservationOfficeUserListItemDto[] => {
  const customerContext = getCustomerContextData();

  return customerContext?.customContexts.croItems ?? [];
};

export const getCroContexts = (): Context[] => {
  return getCroItems().map(ContextMapper.croToContext);
};

export const getCroContextById = (croId: string): Context | undefined => {
  return getCroContexts().find(({ id }) => id === croId);
};

export const hasAnyCroContext = (): boolean => {
  return Boolean(getCroItems().length);
};

export const redirectToPropertyOrNoPermissionsPage = async (
  propertyContextCount: number
): Promise<void> => {
  if (propertyContextCount === 0) {
    return handleNoUnitsOrPermissions();
  }

  navigateToUrl(SystemDefaultLandingPage.pathname);
};
