import { defaultActivityReservationsPathName } from 'frontend-container/components/Menu/utils/modules/activityReservations';
import { accountsReceivableAppName } from 'frontend-container/config/accountsReceivable';
import { activityConfigurationAppName } from 'frontend-container/config/activityConfiguration';
import { activityReservationsAppName } from 'frontend-container/config/activityReservations';
import { centralReservationOfficeAppName } from 'frontend-container/config/centralReservationOffice';
import { changelogAppName } from 'frontend-container/config/changelog';
import { configurationv2AppName } from 'frontend-container/config/configurationv2';
import { housekeepingAppName } from 'frontend-container/config/housekeeping';
import { integrationsAppName } from 'frontend-container/config/integrations';
import { meetingsAndEventsAppName } from 'frontend-container/config/meetingsAndEvents';
import { notificationsAppName } from 'frontend-container/config/notifications';
import { rateManagerAppName } from 'frontend-container/config/rateManager';
import { reportsAppName } from 'frontend-container/config/reports';
import { resourceManagementAppName } from 'frontend-container/config/resourceManagement';
import { taskManagementAppName } from 'frontend-container/config/taskManagement';
import { travelAgentCommissionsAppName } from 'frontend-container/config/travelAgentCommissions';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const reactPathnameAppNameMap = {
  [acConfig.newFrontendUrls.accountsReceivable ?? '/accounts-receivable']:
    accountsReceivableAppName,
  [acConfig.newFrontendUrls.changelog ?? '/changelog']: changelogAppName,
  [acConfig.newFrontendUrls.configurationv2 ?? '/configurationv2']:
    configurationv2AppName,
  [acConfig.newFrontendUrls.housekeeping ?? '/housekeeping']:
    housekeepingAppName,
  [acConfig.newFrontendUrls.meetingsAndEvents ?? '/meetings-and-events']:
    meetingsAndEventsAppName,
  [acConfig.newFrontendUrls.rateManager ?? '/rate-manager']: rateManagerAppName,
  [acConfig.newFrontendUrls.taskManagement ?? '/task-management']:
    taskManagementAppName,
  [acConfig.newFrontendUrls.travelAgentCommissions ??
  '/travel-agent-commissions']: travelAgentCommissionsAppName,
  [acConfig.newFrontendUrls.integrations ?? '/integrations']:
    integrationsAppName,
  [acConfig.newFrontendUrls.notifications ?? '/notifications']:
    notificationsAppName,
  [acConfig.newFrontendUrls.activityReservations ??
  defaultActivityReservationsPathName]: activityReservationsAppName,
  [acConfig.newFrontendUrls.centralReservationOffice ??
  '/central-reservation-office']: centralReservationOfficeAppName,
  [acConfig.newFrontendUrls.reports ?? '/reports']: reportsAppName,
  [acConfig.newFrontendUrls.activityConfiguration ?? '/activity-configuration']:
    activityConfigurationAppName,
  [acConfig.newFrontendUrls.resourceManagement ?? '/resource-management']:
    resourceManagementAppName,
};
