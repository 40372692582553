import {
  BusinessContextContainer,
  BusinessContextDataUpdatedEvent,
} from 'frontend-container/publicApi';
import { getBusinessContextData } from 'frontend-container/shared/businessContext/getBusinessContext';
import { loadBusinessContextData } from 'frontend-container/shared/businessContext/loadBusinessContextData';
import { setUpWebSocketsApi } from 'frontend-container/utils/setUpWebSocketsApi';

import {
  BusinessContextUnitIdentifier,
  SepModuleBusinessContextData,
} from '@ac/library-api';

export const selectUnitInBusinessContext = async (
  unit: BusinessContextUnitIdentifier
): Promise<SepModuleBusinessContextData> => {
  const contextContainer = window.ACP?.businessContext;
  if (!contextContainer) {
    throw new Error(
      'The context container is not defined. Please use setInitialBusinessContextForUnit() firstly.'
    );
  }

  const currentBusinessContextData = getBusinessContextData();
  const newBusinessContextData = await loadBusinessContextData(unit);

  if (currentBusinessContextData !== newBusinessContextData) {
    saveNewBusinessContext(unit, contextContainer, newBusinessContextData);

    setUpWebSocketsApi();
  }

  return newBusinessContextData;
};

const saveNewBusinessContext = async (
  unit: BusinessContextUnitIdentifier | undefined,
  contextContainer: BusinessContextContainer,
  context: SepModuleBusinessContextData
): Promise<void> => {
  contextContainer.context = context;
  contextContainer.onUnitChanged?.(context);

  window.dispatchEvent(new BusinessContextDataUpdatedEvent());

  // eslint-disable-next-line no-console
  console.log(
    `[BusinessContext] New unit selected: ${
      unit ? Object.values(unit).join(', ') : '-'
    }`
  );
};
