import { DEFAULT_LANGUAGE } from 'frontend-container/components/Menu/components/User/UserPreferences/configuration';
import i18n from 'frontend-container/i18n';

import { SessionService } from '@ac/library-utils/dist/services';
import { changeWebComponentLanguage } from '@ac/web-components';

export const getUILanguage = (): string => {
  return SessionService.getLanguage() || DEFAULT_LANGUAGE;
};

export const setUILanguage = async (newLanguage: string): Promise<void> => {
  SessionService.setLanguage(newLanguage);
  await i18n.changeLanguage(newLanguage);
  await changeWebComponentLanguage(newLanguage);
};
