import { mapFeatureTogglesArrayToObject } from 'frontend-container/components/Menu/authorization/mapFeatureTogglesArrayToObject';
import { mapPermissionArrayToObject } from 'frontend-container/components/Menu/authorization/mapPermissionArrayToObject';
import { mapSettingsArrayToSettingsObject } from 'frontend-container/components/Menu/authorization/mapSettingsArrayToSettingsObject';
import {
  AccessConfiguration,
  AccessSource,
  AllAccessConfiguration,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuPosition } from 'frontend-container/components/Menu/menuPosition';
import { getBusinessContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import {
  BusinessContextUnitType,
  CentralReservationOfficeContextDataLoader,
  CroBusinessContextUnitIdentifier,
  EffectiveSettingDetails,
  FeatureTogglesData,
  isSystemBusinessContextData,
  PropertyBusinessContextUnitIdentifier,
  PropertyContextDataLoader,
  SepModuleBusinessContextData,
} from '@ac/library-api';

export const getAllAccessConfiguration = (): AllAccessConfiguration => {
  const businessContextData = getBusinessContextData();
  const cache = {} as AllAccessConfiguration;

  return {
    currentSource: businessContextData.user.details.isSystemUser
      ? AccessSource.System
      : businessContextData.unitType,
    get [AccessSource.Property](): AccessConfiguration {
      return (
        cache[AccessSource.Property] ||
        (cache[AccessSource.Property] = getAccessConfiguration(
          businessContextData,
          AccessSource.Property
        ))
      );
    },
    get [AccessSource.CentralReservationOffice](): AccessConfiguration {
      return (
        cache[AccessSource.CentralReservationOffice] ||
        (cache[AccessSource.CentralReservationOffice] = getAccessConfiguration(
          businessContextData,
          AccessSource.CentralReservationOffice
        ))
      );
    },
    get [AccessSource.Tenant](): AccessConfiguration {
      return (
        cache[AccessSource.Tenant] ||
        (cache[AccessSource.Tenant] = getAccessConfiguration(
          businessContextData,
          AccessSource.Tenant
        ))
      );
    },
    get [AccessSource.System](): AccessConfiguration {
      return (
        cache[AccessSource.System] ||
        (cache[AccessSource.System] = getAccessConfiguration(
          businessContextData,
          AccessSource.System
        ))
      );
    },
  };
};

const getAccessConfiguration = (
  businessContextData: SepModuleBusinessContextData,
  source: AccessSource
): AccessConfiguration => {
  let featureToggles: FeatureTogglesData = [];
  let permissionIds: string[] = [];
  let settings: EffectiveSettingDetails[] = [];

  if (source === AccessSource.System && businessContextData.system) {
    featureToggles = businessContextData.system.featureToggles;
    permissionIds = businessContextData.system.permissions.permissionIds;
    settings = [];
  } else if (
    source === AccessSource.Property &&
    'property' in businessContextData
  ) {
    featureToggles = businessContextData.property.featureToggles;
    permissionIds = businessContextData.property.permissions.permissionIds;
    settings = businessContextData.property.settings.effectiveSettingsDetails;
  } else if (
    source === AccessSource.CentralReservationOffice &&
    'centralReservationOffice' in businessContextData
  ) {
    featureToggles = businessContextData.customer.featureToggles;
    permissionIds =
      businessContextData.centralReservationOffice.permissions.permissionIds;
    settings = businessContextData.customer.settings.effectiveSettings;
  } else if (
    source === AccessSource.Tenant &&
    'customer' in businessContextData
  ) {
    featureToggles = businessContextData.customer.featureToggles;
    permissionIds = businessContextData.customer.permissions.permissionIds;
    settings = businessContextData.customer.settings.effectiveSettings;
  }

  return {
    settings: mapSettingsArrayToSettingsObject(settings),
    permissions: mapPermissionArrayToObject(permissionIds),
    featureToggles: mapFeatureTogglesArrayToObject(featureToggles),
  };
};

export const loadAllAccessConfiguration = async (
  identifier:
    | CroBusinessContextUnitIdentifier
    | PropertyBusinessContextUnitIdentifier
): Promise<AllAccessConfiguration> => {
  const businessContextData = getBusinessContextData();
  const shouldFetchData = identifier.id !== businessContextData.unitId;

  const accessConfiguration = shouldFetchData
    ? await fetchAccessConfiguration(identifier)
    : getAccessConfiguration(businessContextData, identifier.type);

  return {
    ...getAllAccessConfiguration(),
    currentSource: identifier.type,
    [identifier.type]: accessConfiguration,
  };
};

const fetchAccessConfiguration = async (
  identifier:
    | CroBusinessContextUnitIdentifier
    | PropertyBusinessContextUnitIdentifier
): Promise<AccessConfiguration> => {
  const allAccessConfiguration = getAllAccessConfiguration();

  const [featureTogglesResult, settingsResult, permissionsResult] =
    await Promise.allSettled([
      identifier.type === BusinessContextUnitType.Property
        ? fetchPropertyFeatureToggles(identifier.id)
        : allAccessConfiguration.Tenant.featureToggles,
      identifier.type === BusinessContextUnitType.Property
        ? fetchPropertySettings(identifier.id)
        : allAccessConfiguration.Tenant.settings,
      fetchPermissionIds(identifier),
    ]);

  return {
    featureToggles:
      featureTogglesResult.status === 'fulfilled'
        ? featureTogglesResult.value
        : {},
    settings: settingsResult.status === 'fulfilled' ? settingsResult.value : {},
    permissions:
      permissionsResult.status === 'fulfilled' ? permissionsResult.value : {},
  };
};

const fetchPropertyFeatureToggles = (
  propertyId: string
): Promise<Record<string, boolean>> => {
  return new PropertyContextDataLoader(propertyId)
    .loadFeatureToggles()
    .then((featureTogglesData) =>
      mapFeatureTogglesArrayToObject(featureTogglesData)
    );
};

const fetchPropertySettings = async (
  propertyId: string
): Promise<Record<string, boolean | MenuPosition[] | unknown>> => {
  const businessContext = getBusinessContextData();
  if (isSystemBusinessContextData(businessContext)) {
    return {};
  }

  const settings = await new PropertyContextDataLoader(
    propertyId
  ).loadSettings();

  return mapSettingsArrayToSettingsObject(settings.effectiveSettingsDetails);
};

const fetchPermissionIds = async (
  identifier:
    | CroBusinessContextUnitIdentifier
    | PropertyBusinessContextUnitIdentifier
): Promise<Record<string, boolean>> => {
  if (identifier.type === BusinessContextUnitType.CentralReservationOffice) {
    return new CentralReservationOfficeContextDataLoader(identifier.id)
      .loadPermissions()
      .then((contextData) =>
        mapPermissionArrayToObject(contextData.permissionIds)
      );
  }

  return new PropertyContextDataLoader(identifier.id)
    .loadPermissions()
    .then((contextData) =>
      mapPermissionArrayToObject(contextData.permissionIds)
    );
};
