import { acConfig } from '@ac/library-utils/dist/declarations';

export const ENGLISH_LANGUAGE = 'en';
export const DEFAULT_LANGUAGE = ENGLISH_LANGUAGE;

export const SystemDefaultLandingPage = {
  code: 'HouseStatus',
  pathname: `${acConfig.newFrontendUrls.reports}/house-status-dashboard`,
};

export const CentralReservationOfficeDefaultLandingPage = {
  code: 'CentralAvailabilityInquiry',
  pathname: acConfig.newFrontendUrls.centralReservationOffice,
};
