import { FC, useMemo } from 'react';
import { getConfigurationEntityDescription } from 'frontend-container/utils/getConfigurationEntityDescription';

import { GenericOverrideEntity } from '@ac/library-api';
import {
  AlignItems,
  FlexDirection,
  IconName,
  TextColor,
  TextSize,
  TextWeight,
} from '@ac/web-components';

interface Props {
  firstName?: string;
  lastName?: string;
  email?: string;
  department?: GenericOverrideEntity;
  jobTitle?: GenericOverrideEntity;
}

export const UserInfotipHeader: FC<Props> = ({
  firstName,
  lastName,
  email,
  department,
  jobTitle,
}) => {
  const formattedName = `${lastName ?? ''}, ${firstName ?? ''}`;

  const jobDepatment = useMemo(() => {
    return `${
      jobTitle
        ? getConfigurationEntityDescription(jobTitle?.descriptionMap) + ', '
        : ''
    }${
      department
        ? getConfigurationEntityDescription(department?.descriptionMap)
        : ''
    }`;
  }, [department, jobTitle]);

  return (
    <>
      <ac-flex alignItems={AlignItems.center} class="ac-spacing-bottom-sm">
        <ac-text class="ac-spacing-right-sm">
          <ac-icon icon={IconName.profile} />
        </ac-text>
        <ac-flex direction={FlexDirection.column}>
          <ac-text weight={TextWeight.semibold}>{formattedName}</ac-text>
          <ac-text size={TextSize.sm}>{jobDepatment}</ac-text>
        </ac-flex>
      </ac-flex>
      <ac-flex class="ac-spacing-bottom-sm">
        <ac-box class="ac-spacing-left-lg">
          <ac-text class="ac-spacing-left-s" color={TextColor.lightGray}>
            {email}
          </ac-text>
        </ac-box>
      </ac-flex>
    </>
  );
};
