import {
  BusinessContextUnitType,
  CentralReservationOfficeContextData,
  CustomerContextData,
  isSystemBusinessContextData,
  PropertyContextData,
  SepModuleBusinessContextData,
} from '@ac/library-api';

export const getBusinessContextData = (): SepModuleBusinessContextData => {
  const businessContextData = window.ACP?.businessContext?.context;
  if (!businessContextData) {
    throw new Error('BusinessContext is undefined!');
  }

  return businessContextData;
};

export const getCustomerContextData = (): CustomerContextData | undefined => {
  const context = getBusinessContextData();
  if (isSystemBusinessContextData(context)) {
    return undefined;
  }

  return context.customer;
};

export const getPropertyContextData = (): PropertyContextData | undefined => {
  const context = getBusinessContextData();

  return context?.unitType === BusinessContextUnitType.Property
    ? context.property
    : undefined;
};

export const getCroContextData = ():
  | CentralReservationOfficeContextData
  | undefined => {
  const context = getBusinessContextData();

  return context?.unitType === BusinessContextUnitType.CentralReservationOffice
    ? context.centralReservationOffice
    : undefined;
};
