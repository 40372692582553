import { isCroLandingPage } from 'frontend-container/components/LandingPage/location';
import { CustomPredicateArgs } from 'frontend-container/components/Menu/authorization/types';
import { hasAnyCroContext } from 'frontend-container/components/Menu/components/CroContext/service';
import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';

import { acConfig } from '@ac/library-utils/dist/declarations';

const defaultModuleUrl = 'central-reservation-office';

export const isCentralReservationOfficeModule = (
  pathname: string = window.location.pathname
): boolean => {
  return (
    pathname?.startsWith(
      acConfig.newFrontendUrls.centralReservationOffice || defaultModuleUrl
    ) || isCroLandingPage(pathname)
  );
};

export const hasAnyCroContextGuard = (
  _: CustomPredicateArgs<MenuElement | MenuElementItem>
): boolean => {
  return hasAnyCroContext();
};

export const isCROReservationPathname = (
  pathname: string = window.location.pathname
): boolean => {
  const baseCROPath =
    acConfig.newFrontendUrls.centralReservationOffice || defaultModuleUrl;

  return pathname.startsWith(`${baseCROPath}/reservation/`);
};
