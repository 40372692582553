import { useEffect } from 'react';
import {
  getRegisteredDocumentTitle,
  getRouteTitle,
} from 'frontend-container/components/Menu/components/DocumentTitle/service';
import { loadBranding } from 'frontend-container/shared/branding/loadBranding';

import { updateDocumentTitle } from '@ac/library-utils/dist/utils';

export const DocumentTitle = (): null => {
  const handleRouteChange = (defaultTitle?: string): void => {
    const routeTitle =
      getRegisteredDocumentTitle() || getRouteTitle(defaultTitle);

    updateDocumentTitle(routeTitle);
  };

  useEffect(() => {
    const handleRouteChangeWithBranding = async (): Promise<void> => {
      const branding = await loadBranding();

      handleRouteChange(branding?.documentTitle);
    };

    handleRouteChangeWithBranding();

    window.addEventListener('popstate', handleRouteChangeWithBranding);

    return (): void => {
      window.removeEventListener('popstate', handleRouteChangeWithBranding);
    };
  }, []);

  return null;
};
