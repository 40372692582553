import React, { useState } from 'react';
import { Cashier } from 'frontend-container/components/Menu/components/Cashier/Cashier';
import { isCashierVisible } from 'frontend-container/components/Menu/components/Cashier/visibility';
import { CroContext } from 'frontend-container/components/Menu/components/CroContext/CroContext';
import { isCroContextVisible } from 'frontend-container/components/Menu/components/CroContext/isCroContextVisible';
import {
  isBellVisible,
  NotificationBellContainer,
} from 'frontend-container/components/Menu/components/NotificationBell';
import { PropertyContext } from 'frontend-container/components/Menu/components/PropertyContext/PropertyContext';
import { isPropertyContextVisible } from 'frontend-container/components/Menu/components/PropertyContext/visibility';
import { useIsWorkstationVisible } from 'frontend-container/components/Menu/components/Workstation/visibility';
import { WorkstationMenu } from 'frontend-container/components/Menu/components/Workstation/Workstation';
import { isError403IpWhitlelistPage } from 'frontend-container/utils/setupWhitelistingCatcher';

import { AlignItems, JustifyContent } from '@ac/web-components';

import './HorizontalBar.scss';

export const HorizontalBar = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const { isWorkstationVisible } = useIsWorkstationVisible();

  return (
    <ac-flex
      class="horizontal-bar-container"
      justifyContent={JustifyContent.flexEnd}
      alignItems={AlignItems.center}
    >
      {!isError403IpWhitlelistPage() && (
        <>
          {isPropertyContextVisible() && (
            <PropertyContext
              onLoadingEnd={(): void => setIsLoading(false)}
              onLoadingStart={(): void => setIsLoading(true)}
            />
          )}
          {isCroContextVisible() && <CroContext />}
          {isCashierVisible() && <Cashier />}
          {isWorkstationVisible && <WorkstationMenu />}
          {isBellVisible() && <NotificationBellContainer />}
          {isLoading && <ac-loader-covering />}
        </>
      )}
    </ac-flex>
  );
};
