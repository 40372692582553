import {
  handleEmptyModuleUrl,
  handleErrorLoadingModule,
  injectGlobalMount,
} from 'frontend-container/utils/externalResources/shared';

import { loadReactModule } from '@ac/library-utils/dist/utils/micro-frontends/loading/page/react';
import { AppToRender } from '@ac/library-utils/dist/utils/micro-frontends/rendering/shared';

export const loadReactApp = async (
  appUrl: string,
  appName: string
): Promise<AppToRender<unknown>> => {
  if (!appUrl) {
    handleEmptyModuleUrl(appName);

    return {
      bootstrap: [],
      mount: [],
      unmount: [],
    };
  }
  try {
    const acModule = await loadReactModule(appUrl);
    const exportedApp = await acModule.getIndexInContainer();

    return injectGlobalMount(exportedApp);
  } catch (e) {
    if (e instanceof Error) {
      handleErrorLoadingModule(e, appName);
    } else {
      handleErrorLoadingModule(new Error(String(e)), appName);
    }
  }

  return {
    bootstrap: [],
    mount: [],
    unmount: [],
  };
};
