import { Context } from 'frontend-container/components/Menu/components/Context';
import { isGlobalRegion } from 'frontend-container/components/Menu/utils/isGlobalRegion';
import { isModuleEditableInGlobalRegionOnly } from 'frontend-container/components/Menu/utils/isModuleEditableInGlobalRegionOnly';

import { getCurrentRegionCode } from '@ac/library-utils/dist/utils/multi-region';

export const selectInitialContext = (
  contexts: Context[],
  id?: string
): Context | undefined => {
  return isModuleEditableInGlobalRegionOnly() && isGlobalRegion()
    ? findById(contexts, id)
    : findFirstByIdInCurrentRegion(contexts, id) ??
        findFirstInCurrentRegion(contexts) ??
        findFirst(contexts);
};

const findById = (contexts: Context[], id?: string): Context | undefined => {
  if (!id) return undefined;

  return contexts.find((context) => context.id === id);
};

const findFirstByIdInCurrentRegion = (
  contexts: Context[],
  id: string | undefined
): Context | undefined => {
  if (!id) return undefined;

  const currentRegionCode = getCurrentRegionCode();

  return contexts.find(
    (context) => context.id === id && context.regionCode === currentRegionCode
  );
};

const findFirstInCurrentRegion = (contexts: Context[]): Context | undefined => {
  const currentRegionCode = getCurrentRegionCode();

  return contexts.find(({ regionCode }) => regionCode === currentRegionCode);
};

const findFirst = (contexts: Context[]): Context | undefined => contexts[0];
