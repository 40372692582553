import { useCallback, useEffect, useRef, useState } from 'react';
import { ConfigurationState } from 'frontend-container/components/AutomaticLogout/data/configuration';
import {
  getNextIdleTimeout,
  getSecondsLeftBeforeIdleTimeout,
  setIdleTimeout,
} from 'frontend-container/components/AutomaticLogout/idleTimeout';
import { useOnce } from 'frontend-container/components/AutomaticLogout/useOnce';
import { registerCallbackToUserEvents } from 'frontend-container/components/AutomaticLogout/userInteractionEvents';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import { isLoginUrl } from 'frontend-container/utils/location';
import { logger } from 'frontend-container/utils/logger/logger';

export interface IdleTimeout {
  shouldWarnUser: boolean;
  remainingSeconds: number | undefined;
  resetIdleTimeout: () => void;
  logoutIdleUser: () => void;
}

const logInfoAboutLogout = (): void => {
  logger?.information('Automatic logout');
};

export const useIdleTimeout = (
  remoteConfiguration: ConfigurationState | null
): IdleTimeout => {
  const [shouldWarnUser, setShouldWarnUser] = useState(false);
  const [remainingSeconds, setRemainingSeconds] = useState<
    number | undefined
  >();
  const intervalId = useRef<ReturnType<typeof setInterval>>();

  const cleanUpInterval = (): void => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = undefined;
    }
  };

  const logoutIdleUser = useCallback(() => {
    cleanUpInterval();
    setIdleTimeout(null);
    logInfoAboutLogout();
    userService.logout(true, true);
  }, []);

  const resetIdleTimeout = useCallback(() => {
    setIdleTimeout(null);
    setShouldWarnUser(false);
    setRemainingSeconds(undefined);
  }, []);

  useOnce(() => {
    if (isLoginUrl()) {
      setIdleTimeout(null);
    } else if (getSecondsLeftBeforeIdleTimeout() === 0) {
      logoutIdleUser();
    }
  });

  useEffect(() => {
    if (!remoteConfiguration) return;
    const { warningTimeInSecondsBeforeIdleTimeout } = remoteConfiguration;

    intervalId.current = setInterval(() => {
      const secondsLeft = getSecondsLeftBeforeIdleTimeout();
      if (secondsLeft === 0) {
        return logoutIdleUser();
      }

      const warningShouldBeVisible =
        warningTimeInSecondsBeforeIdleTimeout >= secondsLeft;

      setShouldWarnUser(warningShouldBeVisible);
      setRemainingSeconds(warningShouldBeVisible ? secondsLeft : undefined);
    }, 1_000);

    return cleanUpInterval;
  }, [logoutIdleUser, remoteConfiguration]);

  useEffect(() => {
    if (!remoteConfiguration || shouldWarnUser) return;
    const { idleTimeoutInSeconds } = remoteConfiguration;

    const handleUserActivity = (): void => {
      const nextIdleTimeout = getNextIdleTimeout(idleTimeoutInSeconds);
      setIdleTimeout(nextIdleTimeout);
    };

    handleUserActivity();

    return registerCallbackToUserEvents(handleUserActivity)
      .cleanUpAfterUserEventsRegistration;
  }, [remoteConfiguration, shouldWarnUser]);

  return {
    shouldWarnUser,
    remainingSeconds,
    resetIdleTimeout,
    logoutIdleUser,
  };
};
