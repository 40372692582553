import { shouldUseNewMenu } from 'frontend-container/components/Menu/service';
import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';

type MatchingFunction = (value: string) => boolean;

const findSelectedItem = (item: MenuElementItem): boolean => {
  const links = [item.link, ...(item.aliases ?? [])];

  return links.some((link) => {
    const index = link.indexOf('?');
    const length = index >= 0 ? index : link.length;

    const pathname = window.location.pathname;
    const clearedPathName = pathname.endsWith('/')
      ? pathname.substring(0, pathname.length - 1)
      : pathname;

    return (
      `${clearedPathName}`.match(
        new RegExp(
          `^${link.substr(0, length).replace(/:[^\s/]+/g, '([\\w-]+)')}$`
        )
      ) &&
      (index >= 0
        ? window.location.search &&
          link.substring(index, link.length).startsWith(window.location.search)
        : true)
    );
  });
};

const getFallbackElement = (
  menuElements: MenuElement[]
): MenuElement | undefined => {
  const createIsMatchnigFunction = (): MatchingFunction => {
    let prevMatchLength: number = 0;

    return (value: string): boolean => {
      if (
        window.location.pathname.startsWith(value) &&
        value.length > prevMatchLength
      ) {
        prevMatchLength = value.length;

        return true;
      }

      return false;
    };
  };

  const isMatching = createIsMatchnigFunction();

  return menuElements.reduce<MenuElement | undefined>(
    (prevElement, element) => {
      const hasMatchedElement = element.items.reduce<boolean>(
        (hasMatchedItem, item) => {
          if (isMatching(item.link)) {
            return true;
          }

          return (
            !!item.aliases?.reduce<boolean>((hasMatchedAlias, alias) => {
              return isMatching(alias) || hasMatchedAlias;
            }, false) || hasMatchedItem
          );
        },
        false
      );

      return hasMatchedElement ? element : prevElement;
    },
    undefined
  );
};

export const getSelectedMenuItem = (
  menuElements: MenuElement[]
): [MenuElement | undefined, MenuElementItem | undefined] => {
  const selectedItem = [...menuElements.flatMap((item) => item.items)]
    .reverse()
    .find(findSelectedItem);

  let item = menuElements.reverse().find((element) => {
    return !!element.items.find(findSelectedItem);
  });

  menuElements.reverse();

  if (!item) {
    item = getFallbackElement(menuElements);
  }

  return [item, selectedItem];
};

const useNew = shouldUseNewMenu();
const arrowsSpace: number = 105;
const menuHeightVar: number = 48;
const menuWidthVar: number = 58;
export const getAvailableMenuItemsCount = (
  isUsedModuleWithoutProperty: boolean,
  goForward: boolean = true,
  endIndex?: number
): number => {
  let availableSpace = 0;
  if (useNew) {
    availableSpace =
      document.querySelector('.items-container')?.getBoundingClientRect()
        .height || window.innerHeight - menuHeightVar - arrowsSpace;
  } else {
    const leftContainerSpace = isUsedModuleWithoutProperty ? 100 : 475;
    availableSpace = window.innerWidth - menuWidthVar - leftContainerSpace;
  }
  const menuItems = Array.prototype.slice.call(
    document.querySelectorAll('.container-menu-item'),
    0
  ) as Element[];
  const referenceIndex = goForward
    ? endIndex
    : menuItems.length - (endIndex ?? 0);
  let sum = 0;
  let count = 0;
  (goForward ? menuItems : menuItems.reverse()).forEach(
    (item: Element, index: number) => {
      if (sum >= availableSpace) {
        return;
      }
      if (
        referenceIndex &&
        index < referenceIndex /* || (goForward && index === referenceIndex) */
      ) {
        return;
      }
      sum += item.getBoundingClientRect()[useNew ? 'height' : 'width'];
      if (sum >= availableSpace) {
        return;
      }
      count++;
    }
  );
  if (!goForward) {
    menuItems.reverse();
  }

  return count;
};
