import { ACPCustomEvents } from './../events';

export interface PropertyBusinessDateDetails {
  businessDate: string;
}

export class PropertyBusinessDateUpdatedEvent extends CustomEvent<PropertyBusinessDateDetails> {
  constructor(businessDate: string) {
    super(ACPCustomEvents.PropertyBusinessDateUpdated, {
      detail: { businessDate },
    });
  }
}
