import { FC, MouseEvent as ReactMouseEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuElementItem } from 'frontend-container/components/Menu/types';

import { LoginService, SessionService } from '@ac/library-utils/dist/services';

import './MenuLinks.scss';

interface Props {
  wrapperClasses: string;
  onClick: (link: string) => void;
  item: MenuElementItem;
  active: boolean;
}

export const InternalMenuLink: FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const isMac = navigator.appVersion.indexOf('Mac') !== -1;
  const propertyId = SessionService.getPropertyId() ?? '';
  const fullLink = props.item.link
    .replace(/:id/g, propertyId)
    .replace(/:customerId/g, LoginService.authData()?.tenantId ?? '');

  const shortcutsClassNames = [
    'subitem-shortcut',
    !props.active ? 'not-selected' : undefined,
  ]
    .filter(Boolean)
    .join(' ');

  const handleOnClick = (
    event: ReactMouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.preventDefault();
    props.onClick(props.item.link);
  };

  const label = useMemo(
    () =>
      i18n.exists(props.item.translation)
        ? t(props.item.translation)
        : props.item.translation,
    [i18n, props.item.translation, t]
  );

  const keyboardShortcut = isMac
    ? props.item.keyboardShortcutMac
    : props.item.keyboardShortcutWindows;

  return (
    <div className={props.wrapperClasses} onClick={handleOnClick}>
      <a
        className="internal-link"
        target="_blank"
        rel="noopener noreferrer"
        href={fullLink}
      >
        <span className="subitem-title">{label}</span>
        {keyboardShortcut && (
          <span className={shortcutsClassNames}>{keyboardShortcut}</span>
        )}
      </a>
    </div>
  );
};
