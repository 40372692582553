import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { Header } from 'frontend-container/components/Menu/components/Item/Header/Header';
import { SubItem } from 'frontend-container/components/Menu/components/Item/SubItem/SubItem';
import { getPropertyUnits } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { getMainApplicationMenu } from 'frontend-container/components/Menu/configuration';
import { shouldUseNewMenu } from 'frontend-container/components/Menu/service';
import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';
import { isModuleEditableInGlobalRegionOnly } from 'frontend-container/components/Menu/utils/isModuleEditableInGlobalRegionOnly';
import { isReadOnlyRequired } from 'frontend-container/components/ReadOnlyMode/isReadOnlyRequired';
import {
  removeReadOnlyByUser,
  setReadOnlyMode,
} from 'frontend-container/components/ReadOnlyMode/setReadOnlyMode';
import { PresenterResultType } from 'frontend-container/publicApi';
import { CONTAINER_ROOT_SELECTOR } from 'frontend-container/shared/constants';
import { clearSystemJSForNewLinkIfFEAppNotYetLoaded } from 'frontend-container/utils/clearSystemJSForNewLinkIfFEAppNotYetLoaded';
import { navigateToUrl } from 'single-spa';

import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import { getCurrentRegionCode } from '@ac/library-utils/dist/utils/multi-region';
import {
  ButtonPattern,
  ButtonTheme,
  Placement,
  Size,
  TargetValueObject,
  TextColor,
} from '@ac/web-components';

import './Item.scss';

interface Props {
  selectedRoute: string | undefined;
  isSelected: boolean;
  menuElement: MenuElement;
  setSelectedRoute: (setSelectedRouteId: string, route: string) => void;
  class?: string;
}

export const Item = (props: Props): JSX.Element => {
  const [isElementActive, setIsElementActive] = useState(false);
  let timeout: ReturnType<typeof setTimeout>;
  const { t } = useTranslation();
  const useNew = shouldUseNewMenu();

  const handleOnMouseOverMenuItem = (): void => {
    if (timeout) {
      clearTimeout(timeout);
    }
    setIsElementActive(true);
  };

  const handleOnHideMenuItem = (): void => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => setIsElementActive(false), 50);
  };

  const redirectToLink = async (link: string): Promise<void> => {
    const propertyId = SessionService.getPropertyId() ?? '';
    const tenantId = LoginService.authData()?.tenantId ?? '';
    const fullLink = link
      .replace(/:id/g, propertyId)
      .replace(/:customerId/g, tenantId);
    setIsElementActive(false);
    const isLinkInMenu = props.menuElement.items.find(
      (element) => element.link === link
    );
    if (!isLinkInMenu) {
      return;
    }
    props.setSelectedRoute(props.menuElement.id, link);
    clearSystemJSForNewLinkIfFEAppNotYetLoaded(fullLink);

    const currentRegionCode = getCurrentRegionCode();
    // Only modules which are editable in global region care for readOnly mode.
    // Each time we enter into such module, if we are not in global region, we should be in readOnly mode.
    // That's why we active readOnly mode for modules other then the ones editable in global region.
    if (
      isModuleEditableInGlobalRegionOnly() &&
      !isModuleEditableInGlobalRegionOnly(fullLink)
    ) {
      if (isReadOnlyRequired()) {
        setReadOnlyMode(true);
        removeReadOnlyByUser();
      }

      const units = getPropertyUnits();
      const property = units.find((unit) => unit.unitId === propertyId);
      if (property?.regionCode !== currentRegionCode) {
        window.location.href = fullLink;

        return;
      }
    }

    if (link.indexOf('?currencyExchange=true') >= 0) {
      window.location.href = fullLink;
    } else {
      navigateToUrl(fullLink);
    }

    return;
  };

  const handleClickMenuSubItem = async (
    link: string,
    discardUnsavedChanges: boolean = false
  ): Promise<void> => {
    if (
      discardUnsavedChanges ||
      !window.ACP?.container?.hasUnsavedChanges?.(link)
    ) {
      redirectToLink(link);
    } else {
      const modal = window.ACP?.container?.modals?.unsavedChanges;
      const isUnsavedChangesModalVisible = modal?.isVisible ?? false;

      if (!isUnsavedChangesModalVisible) {
        const result = await modal?.show();

        if (result?.type === PresenterResultType.Confirmed) {
          redirectToLink(link);
        }
      }
    }
  };

  const handleOnClickMenuSubItem = async (link: string): Promise<void> =>
    await handleClickMenuSubItem(link);

  const menuElements = getMainApplicationMenu();
  const shortcutsWindows = menuElements.flatMap((element: MenuElement) =>
    element.items.filter((item) => item.keyboardShortcutWindows)
  );
  const shortcutsMac = menuElements.flatMap((element: MenuElement) =>
    element.items.filter((item) => item.keyboardShortcutMac)
  );
  const isMac = navigator.appVersion.indexOf('Mac') !== -1;
  const keyboardShortcuts = isMac
    ? shortcutsMac.map((item: MenuElementItem) => item.keyboardShortcutMac)
    : shortcutsWindows.map(
        (item: MenuElementItem) => item.keyboardShortcutWindows
      );
  useHotkeys(
    keyboardShortcuts.join(),
    (handler: {
      altKey: boolean;
      ctrlKey: boolean;
      metaKey: boolean;
      keyCode: number;
    }) => {
      const shortcuts = isMac ? shortcutsMac : shortcutsWindows;

      const foundMenuItem = shortcuts.find((item: MenuElementItem) => {
        const { altKey, ctrlKey, metaKey, keyCode } = handler;
        const isTheSameKeyCode = keyCode === item.keyCode;

        return altKey && (isMac ? metaKey : ctrlKey) && isTheSameKeyCode;
      });
      if (foundMenuItem) {
        handleClickMenuSubItem(foundMenuItem.link);
      }
    }
  );

  return (
    <>
      <div
        id={props.menuElement.id}
        className={`container-menu-item ${props.class || ''}`}
      >
        {props.isSelected && <div className="menu-status-bar" />}
        <ac-button
          class="container-menu-button"
          data-test-selector={props.menuElement.id}
          pattern={ButtonPattern.tertiary}
          theme={ButtonTheme.light}
          selected={isElementActive}
          onMouseOver={handleOnMouseOverMenuItem}
          onMouseLeave={handleOnHideMenuItem}
        >
          <ac-icon icon={props.menuElement.icon} size={Size.lg} />
          {!useNew && (
            <ac-text color={TextColor.white} class="ac-spacing-left-sm">
              {t(props.menuElement.translation)}
            </ac-text>
          )}
        </ac-button>
        <ac-position-wrapper
          dynamicClass="menu-subitems-wrapper"
          attachTo={CONTAINER_ROOT_SELECTOR}
          isBoundaryContainerApplied={false}
          contentHidden={!isElementActive}
          target={`#${props.menuElement.id}`}
          targetValue={TargetValueObject.mainMenu}
          placement={useNew ? Placement.right : Placement.bottomStart}
          offset={
            useNew
              ? {
                  shiftFromTheMiddle: props.menuElement.shift ?? 0,
                  distanceFromTarget: -4,
                }
              : undefined
          }
          onMouseOver={handleOnMouseOverMenuItem}
          onMouseLeave={handleOnHideMenuItem}
        >
          <div className="menu-subitems-container">
            {useNew ? (
              <Header titleTranslation={props.menuElement.translation} />
            ) : (
              <></>
            )}
            {props.menuElement.items.map((item, index) => (
              <SubItem
                key={index}
                active={props.selectedRoute === item.link}
                item={item}
                onClick={handleOnClickMenuSubItem}
              />
            ))}
          </div>
        </ac-position-wrapper>
      </div>
    </>
  );
};
