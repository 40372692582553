import { FC, ReactNode, useCallback } from 'react';

import {
  Placement,
  SelectableListSlot,
  TargetValueObject,
} from '@ac/web-components';

import { handleOnChange } from '../../../utils/listOnChangeHandler';
import { Context } from '../context';
import { mapContextsToOptions } from '../mapContextsToOptions';

interface Props {
  contextList: Context[];
  selected: Context | undefined;
  isVisible?: boolean;
  children: ReactNode;
  onSelect: (selected: Context | undefined) => void;
  onClose: () => void;
}

export const ContextList: FC<Props> = ({
  contextList,
  isVisible,
  selected,
  children,
  onSelect,
  onClose,
}) => {
  const onClick = useCallback(
    (context: Context | undefined) => {
      if (!context) return;

      onSelect(context);
    },
    [onSelect]
  );

  const onChange = handleOnChange(contextList, onClick);

  return (
    <div id="context-list-wrapper">
      <ac-selectable-list
        attachTo="#context-list-wrapper"
        isBoundaryContainerApplied={false}
        searchable
        autoFilter={true}
        isVisible={isVisible}
        placement={Placement.bottomEnd}
        target="#context-dropdown-button"
        targetValue={TargetValueObject.mainMenu}
        optionsMinWidth={50}
        optionsArray={mapContextsToOptions(contextList)}
        optionNameField="name"
        optionSelectedField="value"
        optionValueField="value"
        dropdownClass="selectable-list-dropdown-wrapper"
        onChangeCallback={onChange}
        onCloseCallback={onClose}
        value={[selected?.id || '']}
      >
        <div slot={SelectableListSlot.listHeader} className="list-header">
          {children}
        </div>
      </ac-selectable-list>
    </div>
  );
};
