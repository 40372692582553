import {
  AccessSource,
  AllAccessConfiguration,
  MenuAccessConfiguration,
  MenuAccessConfigurationEntry,
  PermissionConfiguration,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import { isDefined } from '@ac/library-utils/dist/utils';

export const getAllowedMenuItems = (
  menuElements: MenuElement[],
  configuration: AllAccessConfiguration
): MenuElement[] =>
  menuElements
    .map((menuElement) => {
      const isMenuElementAllowed = getIsMenuComponentAllowed(
        menuElement,
        configuration
      );

      if (!isMenuElementAllowed) return undefined;

      const allowedItems = menuElement.items.filter((item) => {
        return getIsMenuComponentAllowed(item, configuration);
      });

      return allowedItems.length
        ? { ...menuElement, items: allowedItems }
        : undefined;
    })
    .filter((menuElement) => isDefined(menuElement)) as MenuElement[];

export const getIsMenuComponentAllowed = (
  menuComponent: MenuAccessConfiguration,
  configuration: AllAccessConfiguration
): boolean => {
  return (
    (menuComponent.allowedWhen?.({ item: menuComponent, configuration }) ??
      true) &&
    checkIfHasPermissions(
      configuration,
      menuComponent.permissionsConfiguration
    ) &&
    checkIfHasSettings(configuration, menuComponent.settings) &&
    checkIfHasFeatureToggles(configuration, menuComponent.featureToggles)
  );
};

const getSourceAndKey = (
  item: MenuAccessConfigurationEntry,
  defaultSource: AccessSource
): [AccessSource, string] => {
  const key = typeof item === 'string' ? item : item.key;
  const source =
    defaultSource === AccessSource.System
      ? defaultSource
      : typeof item === 'string'
      ? defaultSource
      : item.source;

  return [source, key];
};

const checkIfHasSettings = (
  configuration: AllAccessConfiguration,
  settingsConfiguration?: MenuAccessConfigurationEntry[]
): boolean => {
  if (!settingsConfiguration) return true;

  const checkSetting = (setting: MenuAccessConfigurationEntry): boolean => {
    const [source, key] = getSourceAndKey(setting, configuration.currentSource);

    return Boolean(configuration[source].settings[key]);
  };

  return settingsConfiguration.every(checkSetting);
};

const checkIfHasFeatureToggles = (
  configuration: AllAccessConfiguration,
  featureTogglesConfiguration?: MenuAccessConfigurationEntry[]
): boolean => {
  if (!featureTogglesConfiguration) return true;

  const checkFeatureToggle = (
    featureToggle: MenuAccessConfigurationEntry
  ): boolean => {
    const [source, key] = getSourceAndKey(
      featureToggle,
      configuration.currentSource
    );

    return configuration[source].featureToggles[key];
  };

  return featureTogglesConfiguration.every(checkFeatureToggle);
};

const checkIfHasPermissions = (
  configuration: AllAccessConfiguration,
  permissionsConfiguration?: PermissionConfiguration
): boolean => {
  if (!permissionsConfiguration) return true;

  const checkPermission = (item: MenuAccessConfigurationEntry): boolean => {
    const [source, key] = getSourceAndKey(item, configuration.currentSource);

    return configuration[source].permissions[key];
  };

  return permissionsConfiguration.permissionsConjunction ===
    PermissionsConjunction.And
    ? permissionsConfiguration.permissions.every(checkPermission)
    : permissionsConfiguration.permissions.some(checkPermission);
};
