import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import { propertyPermissionKeys } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const cashieringDashboardPermissions =
  propertyPermissionKeys.cashiering.dashboard;
const cashieringAccountPermissions = propertyPermissionKeys.cashiering.account;
const cashieringBillingPermissions = propertyPermissionKeys.cashiering.billing;
const cashierPermissions = propertyPermissionKeys.cashier;
const foreignCurrencyExchangePermissions =
  propertyPermissionKeys.foreignCurrencyExchange;

export const cashieringMenu: MenuElement = {
  translation: 'MENU.CASHIERING.TITLE',
  icon: IconName.cashiering,
  id: 'menu-cashiering',
  items: [
    {
      link: `${acConfig.newFrontendUrls.cashiering}/dashboard`,
      aliases: [
        `${acConfig.newFrontendUrls.cashiering}/selected-accounts/summary`,
        `${acConfig.newFrontendUrls.cashiering}/cashier-closure`,
      ],
      code: 'CashierDashboard',
      translation: 'MENU.CASHIERING.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [cashieringDashboardPermissions.view],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+c',
      keyboardShortcutMac: '⌥+⌘+c',
      keyCode: 67,
    },
    {
      link: `${acConfig.newFrontendUrls.cashiering}/quick-posting`,
      translation: 'MENU.CASHIERING.ITEMS.QUICK_POSTING',
    },
    {
      link: `${acConfig.newFrontendUrls.cashiering}/policy/deposits`,
      translation: 'MENU.CASHIERING.ITEMS.POLICY_DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          cashieringDashboardPermissions.viewPolicyDepositsDashboard,
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/cashiering/policy/deposits`,
        `${acConfig.newFrontendUrls.cashiering}/policy/deposits/summary`,
      ],
      keyboardShortcutWindows: 'alt+ctrl+4',
      keyboardShortcutMac: '⌥+⌘+4',
      keyCode: 52,
    },
    {
      link: `${acConfig.newFrontendUrls.cashiering}/posting-journal`,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/cashiering/posting-journal`,
      ],
      translation: 'MENU.CASHIERING.ITEMS.POSTING_JOURNAL',
      permissionsConfiguration: {
        permissions: [cashieringBillingPermissions.postingJournal],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+j',
      keyboardShortcutMac: '⌥+⌘+j',
      keyCode: 74,
    },
    {
      link: `${acConfig.newFrontendUrls.cashiering}/number-list-view`,
      translation: 'MENU.CASHIERING.ITEMS.CASHIER_STATUS',
      permissionsConfiguration: {
        permissions: [cashierPermissions.viewCashierStatusDashboard],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+u',
      keyboardShortcutMac: '⌥+⌘+u',
      keyCode: 85,
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/prestep/cashiering`,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/cashiering/accounts/new`,

        // deprecated
        `${acConfig.newFrontendUrls.reservations}/profiles/prestep?originModule=Cashiering`,
        //
      ],
      translation: 'MENU.CASHIERING.ITEMS.NEW',
      permissionsConfiguration: {
        permissions: [cashieringAccountPermissions.manageAccountSetup],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.cashiering}/dashboard?currencyExchange=true`,
      translation: 'MENU.CASHIERING.ITEMS.CURRENCY_EXCHANGE',
      permissionsConfiguration: {
        permissions: [
          foreignCurrencyExchangePermissions.foreignCurrencyExchange,
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+[',
      keyboardShortcutMac: '⌥+⌘+[',
      keyCode: 219,
    },
  ],
};
