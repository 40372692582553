import './ExternalPageIFrame.scss';

interface Props {
  name: string;
  fullScreen?: boolean;
  src?: string;
}

export const ExternalPageIFrame = ({
  name,
  fullScreen,
  src,
}: Props): JSX.Element => {
  return (
    <iframe
      id={name}
      name={name}
      sandbox="allow-scripts allow-same-origin allow-modals"
      className={fullScreen ? 'external-page-iframe-fullscreen' : ''}
      src={src}
    />
  );
};
