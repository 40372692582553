import { Context } from 'frontend-container/components/Menu/components/Context';
import { findRegionData } from 'frontend-container/utils/region/findRegion';
import { redirectToRegion } from 'frontend-container/utils/region/redirectToRegion';

import { getGlobalRegionCode } from '@ac/library-utils/dist/utils/multi-region';

export const redirectIfContextRegionChanged = (context: Context): boolean => {
  const region = findRegionData(context.regionCode ?? getGlobalRegionCode());

  return redirectToRegion(region?.code ?? '', region?.domain ?? '', context);
};
