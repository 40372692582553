import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  FeatureToggleName,
  ProfilesSettingCode,
  propertyPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const profilesPermissions = propertyPermissionKeys.profiles;
const individualProfilePermissions = profilesPermissions.individual;
const profileSharedPermissions = profilesPermissions.shared;

export const profilesMenu: MenuElement = {
  translation: 'MENU.PROFILES.TITLE',
  icon: IconName.profile,
  id: 'menu-profiles',
  items: [
    {
      link: `${acConfig.newFrontendUrls.profiles}/dashboard`,
      code: 'ProfileDashboard',
      aliases: [
        `${acConfig.newFrontendUrls.profiles}/dashboard/all`,
        `${acConfig.newFrontendUrls.profiles}/dashboard/individual`,
        `${acConfig.newFrontendUrls.profiles}/dashboard/company`,
        `${acConfig.newFrontendUrls.profiles}/dashboard/travel-agent`,
        // deprecated
        `${acConfig.newFrontendUrls.reservations}/profiles/all`,
        `${acConfig.newFrontendUrls.reservations}/profiles/individual`,
        `${acConfig.newFrontendUrls.reservations}/profiles/company`,
        `${acConfig.newFrontendUrls.reservations}/profiles/travel-agent`,
        //
      ],
      translation: 'MENU.PROFILES.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          profileSharedPermissions.seeAllProfilesDashboard,
          profileSharedPermissions.dataProtectionOfficer.seeProfilesDashboard,
        ],
        permissionsConjunction: PermissionsConjunction.Or,
      },
      keyboardShortcutWindows: 'alt+ctrl+p',
      keyboardShortcutMac: '⌥+⌘+p',
      keyCode: 80,
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/duplicates`,
      aliases: [
        `${acConfig.newFrontendUrls.profiles}/duplicates/potential`,
        `${acConfig.newFrontendUrls.profiles}/duplicates/last-merged`,

        // deprecated
        `${acConfig.newFrontendUrls.reservations}/profiles/duplicates`,
        `${acConfig.newFrontendUrls.reservations}/profiles/duplicates/potential`,
        `${acConfig.newFrontendUrls.reservations}/profiles/duplicates/last-merged`,
        //
      ],
      translation: 'MENU.PROFILES.ITEMS.POTENTIAL_DUPLICATES',
      permissionsConfiguration: {
        permissions: [
          profileSharedPermissions.matchAndMerge.viewPotentialDuplicateScreen,
          profileSharedPermissions.matchAndMerge.changelogForMergedProfiles,
        ],
        permissionsConjunction: PermissionsConjunction.Or,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/prestep/individual`,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/profiles/individual/new`,

        // deprecated
        `${acConfig.newFrontendUrls.reservations}/profiles/prestep?originModule=Individual`,
        //
      ],
      translation: 'MENU.PROFILES.ITEMS.CREATE_INDIVIDUAL_PROFILE',
      permissionsConfiguration: {
        permissions: [individualProfilePermissions.manageDetails],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/prestep/company`,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/profiles/company/new`,

        // deprecated
        `${acConfig.newFrontendUrls.reservations}/profiles/prestep?originModule=Company`,
        //
      ],
      translation: 'MENU.PROFILES.ITEMS.CREATE_COMPANY_PROFILE',
      permissionsConfiguration: {
        permissions: [profilesPermissions.company.manageDetails],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/prestep/travel-agent`,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/profiles/travel-agent/new`,

        // deprecated
        `${acConfig.newFrontendUrls.reservations}/profiles/prestep?originModule=TravelAgent`,
        //
      ],
      translation: 'MENU.PROFILES.ITEMS.CREATE_TRAVELAGENT_PROFILE',
      permissionsConfiguration: {
        permissions: [profilesPermissions.travelAgent.manageDetails],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/consent-and-activity`,
      translation: 'MENU.PROFILES.ITEMS.CONSENT_AND_ACTIVITY',
      permissionsConfiguration: {
        permissions: [
          individualProfilePermissions.manageDetails,
          individualProfilePermissions.viewDetails,
          individualProfilePermissions.viewIncognito,
          individualProfilePermissions.viewConsentAndProfileActivityDashboard,
          individualProfilePermissions.manageConsentsAndPrivacy,
          profileSharedPermissions.dataProtectionOfficer.seeProfilesDashboard,
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/enrollment`,
      translation: 'MENU.PROFILES.ITEMS.ENROLLMENT',
      featureToggles: [FeatureToggleName.MembershipEnrollment],
      settings: [ProfilesSettingCode.PerformExternalMembershipEnrollment],
      permissionsConfiguration: {
        permissions: [individualProfilePermissions.enrollNewMember],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
  ],
};
