import { ContextType } from 'frontend-container/components/Menu/components/Context';
import { getCroItems } from 'frontend-container/components/Menu/components/CroContext/service';
import { getPropertyUnits } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import {
  getUserUnitsDetails,
  UserUnitsDetails,
} from 'frontend-container/components/Prestep/getUserUnitsDetails';
import { ROUTER_OUTLET_SELECTOR } from 'frontend-container/shared/constants';
import {
  createReactRoot,
  removeReactRoot,
} from 'frontend-container/utils/createReactRoot';
import { isLoginUrl, isMainUrl } from 'frontend-container/utils/location';

import { UserPreferencesDetails } from '@ac/library-api';

import { PrestepView } from './PrestepView';

const getIsUserWithUnsetPreferences = (
  {
    isMultiCroUser,
    isMultiPropertyUser,
    isUserWithoutCroUnits,
    isUserWithoutProperties,
    isSingleCroUser,
    isSinglePropertyUser,
  }: UserUnitsDetails,
  userPreferences?: UserPreferencesDetails
): boolean => {
  const isUserWithCroAndProperty =
    !isUserWithoutProperties && !isUserWithoutCroUnits;

  if (isUserWithCroAndProperty) {
    if (!userPreferences?.defaultWorkspace) {
      return true;
    }
  }

  if (
    (isMultiPropertyUser && isUserWithoutCroUnits) ||
    (isUserWithCroAndProperty &&
      !isSinglePropertyUser &&
      userPreferences?.defaultWorkspace === ContextType.PROPERTY)
  ) {
    if (!userPreferences?.defaultPropertyId) {
      return true;
    }
  }

  if (
    (isMultiCroUser && isUserWithoutProperties) ||
    (isUserWithCroAndProperty &&
      !isSingleCroUser &&
      userPreferences?.defaultWorkspace === ContextType.CRO)
  ) {
    if (!userPreferences?.defaultCentralReservationOfficeId) {
      return true;
    }
  }

  return false;
};

export const getShouldRedirectToPrestep = (): boolean => {
  const pathname = window.location.pathname;
  const hasPathnameFits = isMainUrl(pathname) || isLoginUrl(pathname);

  if (!hasPathnameFits) {
    return false;
  }

  const preferences = userService.getCurrentUserPreferences();
  const preferencesDetails = preferences?.preferences;

  const croUnits = getCroItems();
  const properties = getPropertyUnits();

  const userUnitsDetails = getUserUnitsDetails(properties, croUnits);

  const isUserWithUnsetPreferences = getIsUserWithUnsetPreferences(
    userUnitsDetails,
    preferencesDetails
  );

  if (
    userUnitsDetails.isUserWithoutCroUnits &&
    userUnitsDetails.isUserWithoutProperties
  ) {
    return false;
  }

  if (
    userUnitsDetails.isSinglePropertyUser &&
    userUnitsDetails.isUserWithoutCroUnits
  ) {
    return false;
  }

  if (
    userUnitsDetails.isSingleCroUser &&
    userUnitsDetails.isUserWithoutProperties
  ) {
    return false;
  }

  if (!isUserWithUnsetPreferences) {
    return false;
  }

  return true;
};

export const initPrestep = async (
  route: string,
  callback: (
    route: string,
    userPreferences?: UserPreferencesDetails
  ) => Promise<void>
): Promise<void> => {
  removeReactRoot(ROUTER_OUTLET_SELECTOR);
  const containerRoot = createReactRoot(ROUTER_OUTLET_SELECTOR);

  containerRoot.render(
    <PrestepView
      onConfirm={(userPreferences): Promise<void> =>
        callback(route, userPreferences)
      }
    />
  );
};
