import { useTranslation } from 'react-i18next';

export const LoadingDataStep = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ac-box class="ac-padding-botton-xl">
      <ac-flex class="ac-spacing-bottom-md">
        <ac-loader />
        <ac-text class="ac-spacing-left-sm">
          {t('MENU.WORKSTATION.READ_KEY.KEY_READING')}
        </ac-text>
      </ac-flex>

      <ac-text class="full-width">
        {t('MENU.WORKSTATION.READ_KEY.WAIT_MESSAGE')}
      </ac-text>
    </ac-box>
  );
};
