import { Context } from 'frontend-container/components/Menu/components/Context';
import { setSessionContext } from 'frontend-container/components/Menu/components/Context/setContext';
import { getCroContextById } from 'frontend-container/components/Menu/components/CroContext/service';
import { getPropertyContextById } from 'frontend-container/components/Menu/components/PropertyContext/service';
import {
  NavigateTo,
  NavigateToCro,
  NavigateToModule,
  NavigateToOptions,
  NavigateToProperty,
  Router,
} from 'frontend-container/publicApi/types';
import { findRegionData } from 'frontend-container/utils/region/findRegion';
import {
  contextTypeRegionChangeQueryParamMapper,
  getOriginForNewRegion,
} from 'frontend-container/utils/region/getUrlForNewRegion';
import { isEmpty } from 'lodash';

import { acConfig } from '@ac/library-utils/dist/declarations';
import {
  SEPFrontendUrlKey,
  SEPInternalFrontendUrlKeyType,
} from '@ac/library-utils/dist/declarations/config';
import { trim } from '@ac/library-utils/dist/utils';
import { getCurrentRegionCode } from '@ac/library-utils/dist/utils/multi-region';

const createRoute = (
  route: string,
  { subroute, queryParams }: NavigateToOptions = {}
): string => {
  return isEmpty(queryParams)
    ? trim`${route}${subroute}`
    : trim`${route}${subroute}?${new URLSearchParams(queryParams)}`;
};

const createRegionalRoute = (
  route: string,
  context: Context,
  { subroute, queryParams }: NavigateToOptions = {}
): string => {
  const region = findRegionData(context.regionCode ?? '');
  const origin = getOriginForNewRegion(
    region?.code ?? '',
    region?.domain ?? ''
  );
  const path = createRoute(route, { subroute, queryParams });

  return trim`${origin}${path}`;
};

const getContextParam = (context: Context): Record<string, string> => ({
  [contextTypeRegionChangeQueryParamMapper(context)]: context.id,
});

const getModulePath = (module: SEPInternalFrontendUrlKeyType): string =>
  acConfig.newFrontendUrls[module];

const navigate = (route: string, inNewTab: boolean = false): void =>
  inNewTab ? void window.open(route, '_blank') : window.location.replace(route);

const navigateTo: NavigateTo = (relativePath, { queryParams, newTab } = {}) => {
  const route = createRoute(relativePath, { queryParams });
  navigate(route, newTab);
};

const navigateToModule: NavigateToModule = (
  module,
  { subroute, queryParams, newTab } = {}
) => {
  const route = createRoute(getModulePath(module), { subroute, queryParams });
  navigate(route, newTab);
};

const navigateToProperty: NavigateToProperty = async (
  propertyId,
  module,
  { subroute, queryParams, newTab } = {}
) => {
  const propertyContext = getPropertyContextById(propertyId);

  if (!propertyContext)
    throw new Error(
      `Failed to navigate to property. Property context "${propertyId}" not found`
    );

  setSessionContext(propertyContext, newTab);

  const queryParamsWithContext = {
    ...queryParams,
    ...getContextParam(propertyContext),
  };

  if (propertyContext.regionCode === getCurrentRegionCode())
    return navigateToModule(module, {
      subroute,
      newTab,
      queryParams: newTab ? queryParamsWithContext : queryParams,
    });

  const regionalRoute = createRegionalRoute(
    getModulePath(module),
    propertyContext,
    {
      subroute,
      queryParams: queryParamsWithContext,
    }
  );

  navigate(regionalRoute, newTab);
};

const navigateToCro: NavigateToCro = async (
  croId,
  { subroute, queryParams, newTab } = {}
) => {
  const croContext = getCroContextById(croId);

  if (!croContext)
    throw new Error(
      `Failed to navigate to CRO. CRO context "${croId}" not found`
    );

  setSessionContext(croContext, newTab);

  const queryParamsWithContext = {
    ...queryParams,
    ...getContextParam(croContext),
  };

  if (croContext.regionCode === getCurrentRegionCode())
    return navigateToModule(SEPFrontendUrlKey.CENTRAL_RESERVATION_OFFICE, {
      subroute,
      newTab,
      queryParams: newTab ? queryParamsWithContext : queryParams,
    });

  const regionalRoute = createRegionalRoute(
    getModulePath(SEPFrontendUrlKey.CENTRAL_RESERVATION_OFFICE),
    croContext,
    {
      subroute,
      queryParams: queryParamsWithContext,
    }
  );

  navigate(regionalRoute, newTab);
};

export const createRouter = (): Router => ({
  navigateTo,
  navigateToModule,
  navigateToProperty,
  navigateToCro,
});
