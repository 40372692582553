import { useMemo } from 'react';
import { DateTimeHook } from 'frontend-container/components/Menu/components/Context';
import { getTimeFormatFromCode } from 'frontend-container/components/Menu/components/Context/shared/utils/getTimeFormat';
import {
  getCroContextData,
  getCustomerContextData,
} from 'frontend-container/shared/businessContext/getBusinessContext';
import { formatDate } from 'frontend-container/utils/dateUtils';

import { mapShortDateFormat } from '@ac/library-api';

import { useBrowserDateTime } from './useBrowserDateTime';

/**
 * TODO: Include time format from CRO Entity when It's implemented
 */
const CRO_DATE_FORMAT = 'YYYY-MM-DD';
const CRO_TIME_FORMAT = 'HH:mm';

export const useCroDateTime: DateTimeHook = () => {
  const { date } = useBrowserDateTime();

  const croContextData = getCroContextData();
  const croShortDateFormat = croContextData?.office.shortDateFormat;

  const customerContextData = getCustomerContextData();
  const customerShortDateFormat = customerContextData?.details?.shortDateFormat;
  const customerTimeFormatCode = customerContextData?.details?.timeFormatCode;

  const shortDateFormat = mapShortDateFormat(
    croShortDateFormat ?? customerShortDateFormat ?? CRO_DATE_FORMAT
  );
  const timeFormat = customerTimeFormatCode
    ? getTimeFormatFromCode(customerTimeFormatCode)
    : CRO_TIME_FORMAT;

  const stringDate = useMemo(
    () => formatDate(date, shortDateFormat.toUpperCase()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stringTime = useMemo(() => formatDate(date, timeFormat), [date]);

  return {
    date: stringDate,
    time: stringTime,
    dateFormat: shortDateFormat,
    timeFormat,
  };
};
