import {
  buildUniqueHashForContextData,
  BusinessContextUnitIdentifier,
  SepModuleBusinessContextData,
} from '@ac/library-api';

export interface BusinessContextDataCache {
  addItem: (item: SepModuleBusinessContextData) => void;
  getItem: (
    id: BusinessContextUnitIdentifier
  ) => SepModuleBusinessContextData | undefined;
  clear: () => void;
}

const createCache = (cacheSize: number): BusinessContextDataCache => {
  let entries: SepModuleBusinessContextData[] = [];

  const getItem = (
    unit: BusinessContextUnitIdentifier
  ): SepModuleBusinessContextData | undefined => {
    const unitHash = buildUniqueHashForContextData(unit);

    return entries.find(({ hash }) => hash === unitHash);
  };

  const addItem = (item: SepModuleBusinessContextData): void => {
    const oldEntries = entries.slice(0, cacheSize - 1);

    entries = [item, ...oldEntries];
  };

  const clear = (): void => {
    entries = [];
  };

  return {
    addItem,
    clear,
    getItem,
  };
};

const CACHE_SIZE = 10;

export const businessContextDataCache = createCache(CACHE_SIZE);
