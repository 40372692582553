import {
  CONTACT_US_URL,
  LEGAL_NOTICE_URL,
  PRIVACY_URL,
} from 'frontend-container/components/LoginLogout/linksConsts';
import i18n from 'frontend-container/i18n';
import { formatDate } from 'frontend-container/utils/dateUtils';

import {
  Color,
  DividerColor,
  FlexDirection,
  IconName,
  JustifyContent,
  Target,
  TextSize,
  TextWeight,
} from '@ac/web-components';

import shijiBackground from './assets/city-small-v2.jpg';
import shijiLogo from './assets/shiji-logo.svg';
import shijiLogoBig from './assets/shiji-logo-big.svg';

import './LoginLogoutLayoutBase.scss';

interface Props {
  title: string;
  message: string;
  backgroundImageUrl?: string;
  customerLogoUrl?: string;
  onBackToLoginClick: () => void;
}

export const LoginLogoutLayoutBase = ({
  title,
  message,
  onBackToLoginClick,
  backgroundImageUrl,
  customerLogoUrl,
}: Props): JSX.Element => (
  <ac-flex direction={FlexDirection.column} class="login-logout-page">
    <img
      className="login-logout-image"
      src={backgroundImageUrl || shijiBackground}
    />
    <ac-flex direction={FlexDirection.column} class="login-logout-body">
      <div>
        <ac-flex
          class="logo-wrapper"
          justifyContent={JustifyContent.spaceBetween}
        >
          <img
            data-custom-selector="ac-logo"
            src={customerLogoUrl || shijiLogoBig}
            className="logo"
          />
          {customerLogoUrl && <img src={shijiLogo} className="logo-small" />}
        </ac-flex>

        <ac-divider color={DividerColor.gray5} />

        <div className="ac-spacing-vertical-lg ac-padding-top-sm">
          <ac-text
            size={TextSize.h3}
            weight={TextWeight.semibold}
            data-test-selector="page-title"
          >
            {title}
          </ac-text>
        </div>
        <div
          className="ac-spacing-bottom-lg ac-padding-bottom-xs"
          data-test-selector="page-message"
        >
          {message}
        </div>
        <ac-button
          class="back-to-login-button"
          onClick={onBackToLoginClick}
          data-test-selector={'back-to-login'}
        >
          <ac-button-content text={i18n.t('LOGIN_LOGOUT.BACK')} />
        </ac-button>
      </div>
      <ac-flex direction={FlexDirection.column} class="footer">
        <div className="footer-links">
          <div className="footer-link link-with-margin-bottom ac-spacing-right-sm">
            <ac-hyperlink
              href={PRIVACY_URL}
              target={Target.blank}
              data-custom-selector="privacy-policy"
            >
              {i18n.t('LOGIN_LOGOUT.PRIVACY')}
            </ac-hyperlink>
            <ac-icon
              icon={IconName.dot}
              color={Color.primaryDark}
              class="icon-hidden-in-mobile"
            ></ac-icon>
          </div>
          <div className="footer-link link-with-margin-bottom ac-spacing-right-sm">
            <ac-hyperlink
              href={LEGAL_NOTICE_URL}
              target={Target.blank}
              data-custom-selector="legal-notice"
            >
              {i18n.t('LOGIN_LOGOUT.LEGAL')}
            </ac-hyperlink>
            <ac-icon
              icon={IconName.dot}
              color={Color.primaryDark}
              class="icon-hidden-in-mobile"
            ></ac-icon>
          </div>
          <div className="footer-link">
            <ac-hyperlink
              href={CONTACT_US_URL}
              target={Target.blank}
              data-custom-selector="contact-support"
            >
              {i18n.t('LOGIN_LOGOUT.SUPPORT')}
            </ac-hyperlink>
          </div>
        </div>
        <div className="shiji-footer">
          {i18n.t('LOGIN_LOGOUT.SHIJI_FOOTER', {
            year: formatDate(new Date(), 'YYYY'),
          })}
        </div>
      </ac-flex>
    </ac-flex>
  </ac-flex>
);
