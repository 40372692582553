import { useEffect, useState } from 'react';
import { getUserEnvironmentDetails } from 'frontend-container/components/Menu/components/Logo/MaintenanceModal/getUserEnvironmentDetails';
import {
  AcLibrariesGroup,
  AcLibrary,
  BackendVersion,
  FeLibraryVersionObject,
  FeModule,
  FeModuleVersionResponse,
  FormattedLibraryVersion,
  LibraryResponse,
  LibraryVersionList,
  Services,
  UserEnvironmentData,
} from 'frontend-container/components/Menu/components/Logo/MaintenanceModal/types';
import { stripTrailingSlash } from 'frontend-container/utils/stripTrailingSlash';

import {
  AxiosResponse,
  BaseApi,
  ConfigBuilder,
  VersionInfo,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';

const FE_MICROFRONTENDS_WITHOUT_URL = ['localhost'];

type Data<T> = { data: T };

export interface Versions {
  isLoading: boolean;
  backendVersions: Array<[string, string]>;
  frontendVersions: FeModule[];
  frontendLibraryVersions: FeLibraryVersionObject;
  userEnvironmentDetails: UserEnvironmentData[];
}

export const useVersions = (): Versions => {
  const FE_MICROFRONTENDS_WITHOUT_LIBRARY_VERSIONS = [
    'userDocumentation',
    'systemMaintenance',
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [localFrontendVersions, setLocalFrontendVersions] = useState<
    FeModule[]
  >([]);
  const [localBackendVersions, setLocalBackendVersions] = useState<
    Array<[string, string]>
  >([]);
  const [localFrontendLibraryVersions, setLocalFrontendLibraryVersions] =
    useState<FeLibraryVersionObject>({});
  const [localUserEnvironmentDetails, setLocalUserEnvironmentDetails] =
    useState<UserEnvironmentData[]>([]);

  useEffect(() => {
    const load = async (): Promise<void> => {
      setIsLoading(true);
      try {
        const backendPromises = (async (): Promise<Data<BackendVersion[]>> => {
          try {
            return await BaseApi.get<undefined, BackendVersion[]>(
              new ConfigBuilder()
                .setUrl(`${acConfig.apiUrl}/deployment/v0/system-version`)
                .getConfig()
            );
          } catch {
            return { data: [] as BackendVersion[] };
          }
        })();

        const frontendsEntries = addDefaultUrlForMicroFrontends();

        const userEnvironmentInformation = getUserEnvironmentDetails();

        type LibraryVersionResponse = Record<string, string>;
        type LibraryVersionsGroupResponse = Record<
          string,
          LibraryVersionResponse
        >;

        const formatLibrariesVersions = (
          librariesVersionData:
            | LibraryVersionResponse
            | LibraryVersionsGroupResponse
        ): LibraryResponse => {
          const versionsWithOldApiResponse = [
            {
              name: 'dependencies',
              libraries: Object.entries(librariesVersionData)
                .filter(([, value]) => typeof value === 'string')
                .map(([key, value]) => {
                  return { name: key, version: value as string };
                }),
            },
          ];
          const versionsWithNewApiResponse = Object.entries(
            librariesVersionData
          )
            .filter(([, value]) => typeof value === 'object' && value !== null)
            .map(([key, value]) => {
              return formatVersionsObjects(key, value as AcLibrary);
            });

          return versionsWithNewApiResponse.length > 0
            ? versionsWithNewApiResponse
            : versionsWithOldApiResponse;
        };

        const formatVersionsObjects = (
          key: string,
          versions: AcLibrary
        ): AcLibrariesGroup => {
          const versionTypes = Object.entries(versions).map(
            ([objKey, value]) => {
              return { name: objKey, version: value as string };
            }
          );

          return { name: key, libraries: versionTypes };
        };

        const getFrontendLibraryVersions = async (
          name: string
        ): Promise<Data<FormattedLibraryVersion>> => {
          try {
            const baseUrl = stripTrailingSlash(acConfig.frontendUrls[name]);
            const responseData = await BaseApi.get<undefined, VersionInfo>(
              new ConfigBuilder()
                .setUrl(`${baseUrl}/libraries-versions.json`)
                .setSkipCache(true)
                .getConfig()
            );

            const response = responseData.data;
            const formattedResponse = Array.isArray(response)
              ? response
              : typeof response === 'object' && response !== null
              ? formatLibrariesVersions(
                  response as unknown as Record<string, string>
                )
              : [];

            return { data: { moduleName: name, response: formattedResponse } };
          } catch {
            return { data: {} };
          }
        };

        const getMicroFrontEnds = async (
          name: string
        ): Promise<Data<Partial<FeModuleVersionResponse>>> => {
          try {
            const baseUrl = stripTrailingSlash(acConfig.frontendUrls[name]);
            const versionResponse = await BaseApi.get<undefined, VersionInfo>(
              new ConfigBuilder()
                .setUrl(`${baseUrl}/version.json`)
                .setSkipCache(true)
                .getConfig()
            );

            return {
              data: { moduleName: name, response: versionResponse.data },
            };
          } catch {
            return { data: {} };
          }
        };

        const frontendPromises = frontendsEntries
          .map(([name, url]) =>
            url
              ? (async (): Promise<
                  Data<Partial<FeModuleVersionResponse>> | undefined
                > => {
                  return getMicroFrontEnds(name);
                })()
              : undefined
          )
          .filter(Boolean) as Array<
          Promise<AxiosResponse<FeModuleVersionResponse>>
        >;

        const frontendLibraryVersionsPromises = frontendsEntries
          .map(([name, url]) =>
            url && !FE_MICROFRONTENDS_WITHOUT_LIBRARY_VERSIONS.includes(name)
              ? (async (): Promise<Data<FormattedLibraryVersion>> => {
                  return getFrontendLibraryVersions(name);
                })()
              : undefined
          )
          .filter(Boolean) as Array<Promise<AxiosResponse<LibraryVersionList>>>;

        const [
          backendResponses,
          frontendResponses,
          frontendLibraryVersionsResponses,
        ] = await Promise.all([
          backendPromises,
          Promise.all(frontendPromises),
          Promise.all(frontendLibraryVersionsPromises),
        ]);

        const backendVersions = backendResponses.data.reduce<
          Array<[string, string]>
        >((aggregat, nextBackend) => {
          aggregat.push([
            nextBackend.serviceName,
            `${(nextBackend.version || nextBackend.commitHash) ?? ''}`,
          ]);

          return aggregat;
        }, []);

        const frontendVersions = frontendsEntries.reduce<FeModule[]>(
          (versions, currentEntry) => {
            const feModuleResponse = frontendResponses.find(
              (feResponse) => feResponse.data.moduleName === currentEntry[0]
            );
            const versionInfo =
              feModuleResponse?.data?.response?.buildType === 'develop'
                ? feModuleResponse?.data.response?.commitHash
                : feModuleResponse?.data.response?.version;
            const moduleName =
              currentEntry[0] === Services.container
                ? 'container'
                : currentEntry[0];
            const buildDate = feModuleResponse?.data.response?.buildDate || '';

            versions.push({
              name: `${moduleName ?? ''}`,
              version: `${versionInfo ?? ''}`,
              buildDate: `${buildDate ?? ''}`,
            });

            return versions;
          },
          []
        );

        const frontendLibraryVersions = frontendLibraryVersionsResponses
          .map((item) => item.data)
          .reduce<FeLibraryVersionObject>((versions, nextFrontend) => {
            if (nextFrontend.moduleName) {
              const keyParam =
                nextFrontend.moduleName === Services.container
                  ? 'container'
                  : nextFrontend.moduleName;
              const sortedLibraryVersions = sortLibraryVersions(
                nextFrontend.response
              );
              versions[keyParam] = sortedLibraryVersions;
            }

            return versions;
          }, {});

        setLocalBackendVersions(backendVersions);
        setLocalFrontendVersions(sortFeModule(frontendVersions));
        setLocalFrontendLibraryVersions(frontendLibraryVersions);
        setLocalUserEnvironmentDetails(userEnvironmentInformation);
      } finally {
        setIsLoading(false);
      }
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    backendVersions: localBackendVersions,
    frontendVersions: localFrontendVersions,
    frontendLibraryVersions: localFrontendLibraryVersions,
    userEnvironmentDetails: localUserEnvironmentDetails,
  };
};

export const addDefaultUrlForMicroFrontends = (): Array<[string, string]> => {
  const frontendsEntries = Object.entries(acConfig.frontendUrls);

  FE_MICROFRONTENDS_WITHOUT_URL.forEach((feModuleName: string) => {
    const objIndex = frontendsEntries.findIndex(
      (obj) => obj[0] === feModuleName
    );
    if (objIndex > -1 && !frontendsEntries[objIndex][1]) {
      frontendsEntries[objIndex][1] = '/';
    }
  });

  return frontendsEntries;
};

const sortFeModule = (versions: FeModule[]): FeModule[] => {
  return versions.sort((firstVersionItem, secondVersionItem) => {
    return compareStrings(firstVersionItem.name, secondVersionItem.name);
  });
};

const sortLibraryVersions = (
  versionData: AcLibrariesGroup[]
): AcLibrariesGroup[] => {
  const feLibraryVersions = versionData.map((moduleVersions) => {
    const sortedLibraries = moduleVersions.libraries.sort(
      (firstVersionItem, secondVersionItem) => {
        return compareStrings(firstVersionItem.name, secondVersionItem.name);
      }
    );

    return { ...moduleVersions, libraries: sortedLibraries };
  });

  return feLibraryVersions;
};

const compareStrings = (
  firstElement: string,
  secondElement: string
): number => {
  const formattedFirstElement = firstElement.toUpperCase();
  const formattedSecondElement = secondElement.toUpperCase();

  return formattedFirstElement.localeCompare(formattedSecondElement);
};
