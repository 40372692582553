import { useState } from 'react';
import { ContextType } from 'frontend-container/components/Menu/components/Context';
import { getCroItems } from 'frontend-container/components/Menu/components/CroContext/service';
import { getPropertyUnits } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import {
  PrestepDictionaries,
  PrestepForm,
} from 'frontend-container/components/Prestep/form/PrestepForm';
import { PrestepFormFields } from 'frontend-container/components/Prestep/form/PrestepFormFields';

import { UserPreferencesDetails } from '@ac/library-api';
import { withTranslationsReady } from '@ac/react-infrastructure';

import './PrestepView.scss';

interface Props {
  onConfirm: (userPreferences?: UserPreferencesDetails) => Promise<void>;
}
const PrestepView = ({ onConfirm }: Props): JSX.Element => {
  const [dictionaries] = useState<PrestepDictionaries>(() => {
    return {
      units: getPropertyUnits(),
      croUnits: getCroItems(),
    };
  });

  const { preferences } = userService.getCurrentUserPreferences() || {};

  const formInitialValues: UserPreferencesDetails = {
    defaultLandingScreen: preferences?.defaultLandingScreen,
    defaultLanguage: preferences?.defaultLanguage,
    defaultPropertyId: preferences?.defaultPropertyId,
    defaultCentralReservationOfficeLandingScreen:
      preferences?.defaultCentralReservationOfficeLandingScreen,
    defaultWorkspace: preferences?.defaultWorkspace || ContextType.CRO,
    defaultCentralReservationOfficeId:
      preferences?.defaultCentralReservationOfficeId,
  };

  return (
    <PrestepForm
      initialValues={formInitialValues}
      onSubmit={(): void => undefined}
    >
      <PrestepFormFields dictionaries={dictionaries} onConfirm={onConfirm} />
    </PrestepForm>
  );
};

const PrestepViewWithTranslationsReady = withTranslationsReady(PrestepView);

export { PrestepViewWithTranslationsReady as PrestepView };
