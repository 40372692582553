import { useMemo } from 'react';
import { getCurrentPropertyIdentifierColor } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { CSS_VAR_MENU_BAR_COLOR } from 'frontend-container/components/Menu/constants';
import { getCssVariable } from 'frontend-container/components/Menu/utils/getCssVariable';

export const usePropertyIdentifierColor = (
  propertyId: string | undefined
): string => {
  return useMemo(() => {
    const fallbackColor = getCssVariable(CSS_VAR_MENU_BAR_COLOR);

    return getCurrentPropertyIdentifierColor() || fallbackColor;
  }, [propertyId]); // eslint-disable-line react-hooks/exhaustive-deps
};
