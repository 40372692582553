import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import { customerPermissionKeys, GeneralSettingCode } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const configurationMenu: MenuElement = {
  translation: 'MENU.CONFIGURATION.TITLE',
  icon: IconName.settings,
  id: 'menu-configuration',
  items: [
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/users`,
      translation: 'MENU.CONFIGURATION.ITEMS.USERS',
      permissionsConfiguration: {
        permissions: [
          customerPermissionKeys.identity.viewUsers,
          customerPermissionKeys.configuration.accessToConfigurationModule,
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+;',
      keyboardShortcutMac: '⌥+⌘+;',
      keyCode: 186,
    },
    {
      link: `${acConfig.newFrontendUrls.configurationv2}`,
      aliases: [
        `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/organization-structure`,
      ],
      translation: 'MENU.CONFIGURATION.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          customerPermissionKeys.configuration.accessToConfigurationModule,
          customerPermissionKeys.configuration.organizationUnit
            .viewOrganizationUnit,
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: "alt+ctrl+'",
      keyboardShortcutMac: "⌥+⌘+'",
      keyCode: 222,
    },
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/central-reservation-office`,
      translation: 'MENU.CONFIGURATION.ITEMS.CRO_DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          customerPermissionKeys.configuration.accessToConfigurationModule,
          customerPermissionKeys.configuration.centralReservationOffice.view,
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [GeneralSettingCode.CentralReservationOffice],
      permissionsConfiguration: {
        permissions: [
          {
            key: customerPermissionKeys.configuration.centralReservationOffice
              .view,
            source: AccessSource.Tenant,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
  ],
};

export const configurationMenuInMainApplication: MenuElement = {
  ...configurationMenu,
  items: [
    ...configurationMenu.items,
    // {  2020-12-17 POs requested hiding WOrkflows from users for now
    //   link: `${acConfig.newFrontendUrls.workflows}`,
    //   translation: workflowsMenu.translation,
    //   permissionsConfiguration: workflowsMenu.permissionsConfiguration,
    // },
  ],
};
