import { businessContextDataCache } from 'frontend-container/shared/businessContext/cache';
import { getBusinessContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import {
  BusinessContextUnitIdentifier,
  CentralReservationOfficeBusinessContextData,
  CroBusinessContextUnitIdentifier,
  isSameBusinessContextUnit,
  PropertyBusinessContextData,
  PropertyBusinessContextUnitIdentifier,
  SepModuleBusinessContextData,
  SepModuleBusinessContextLoader,
  SystemBusinessContextData,
  SystemBusinessContextUnitIdentifier,
  TenantBusinessContextData,
  TenantBusinessContextUnitIdentifier,
} from '@ac/library-api';

export async function loadBusinessContextData(
  unit: SystemBusinessContextUnitIdentifier
): Promise<SystemBusinessContextData>;
export async function loadBusinessContextData(
  unit: TenantBusinessContextUnitIdentifier
): Promise<TenantBusinessContextData>;
export async function loadBusinessContextData(
  unit: PropertyBusinessContextUnitIdentifier
): Promise<PropertyBusinessContextData>;
export async function loadBusinessContextData(
  unit: CroBusinessContextUnitIdentifier
): Promise<CentralReservationOfficeBusinessContextData>;
export async function loadBusinessContextData(
  unit: BusinessContextUnitIdentifier
): Promise<SepModuleBusinessContextData>;
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export async function loadBusinessContextData(
  unit: BusinessContextUnitIdentifier
): Promise<SepModuleBusinessContextData> {
  const currentBusinessContextData = getBusinessContextData();

  if (isSameBusinessContextUnit(currentBusinessContextData, unit)) {
    return currentBusinessContextData;
  }

  const cachedContext = businessContextDataCache.getItem(unit);
  if (cachedContext) {
    return cachedContext;
  }

  const newBusinessContextData = await fetchBusinessContextData(
    unit,
    currentBusinessContextData
  );

  businessContextDataCache.addItem(newBusinessContextData);

  return newBusinessContextData;
}

const fetchBusinessContextData = async (
  unit: BusinessContextUnitIdentifier,
  currentContextData?: SepModuleBusinessContextData
): Promise<SepModuleBusinessContextData> => {
  const loader = new SepModuleBusinessContextLoader();

  if (!currentContextData) {
    return loader.load(unit);
  }

  return loader.loadForDifferentScope(currentContextData, unit);
};
