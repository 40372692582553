import { acConfig } from '@ac/library-utils/dist/declarations';
import { RegExpUtils } from '@ac/library-utils/dist/utils';

const availabilityRegExp = RegExpUtils.interpolate`^${acConfig.newFrontendUrls.availability}(?!/inventory).*$`;

export const isAvailabilityModule = (
  pathname: string = window.location.pathname
): boolean => {
  return availabilityRegExp.test(pathname);
};

export default {
  isAvailabilityModule,
};
