import {
  CentralReservationOfficeUserListItemDto,
  DictionaryEntity,
  UnitAssignmentListItem,
} from '@ac/library-api';
import { AcSelectOption } from '@ac/web-components';

export const mapDictionaryEntityToSelectOption = (
  value: Partial<DictionaryEntity>,
  isDisabled: boolean = false
): AcSelectOption<string> | undefined => {
  if (!value.name || !value.code) {
    return undefined;
  }

  return {
    name: value.name,
    value: value.code,
    isDisabled,
  };
};

export const mapUnitToSelectOption = (
  value: UnitAssignmentListItem
): AcSelectOption<string> | undefined => {
  if (!value.name) {
    return undefined;
  }

  return {
    name: value.name,
    value: value.unitId,
  };
};

export const mapCroToSelectOption = (
  value: CentralReservationOfficeUserListItemDto
): AcSelectOption<string> | undefined => {
  if (!value.name) {
    return undefined;
  }

  return {
    name: value.name,
    value: value.id,
  };
};
