import { registerError403 } from 'frontend-container/components/Errors/Error403';
import { registerError404 } from 'frontend-container/components/Errors/Error404';
import { registerError503 } from 'frontend-container/components/Errors/Error503';
import { registerLandingPage } from 'frontend-container/components/LandingPage';
import { registerPrestepNoPermissions } from 'frontend-container/components/Prestep/AccessDenied/NoPermissions/NoPermissions';
import { registerPrestepPermissionsInProgress } from 'frontend-container/components/Prestep/AccessDenied/PermissionsInProgress';
import { registerAccountsReceivable } from 'frontend-container/config/accountsReceivable';
import { registerActivityConfiguration } from 'frontend-container/config/activityConfiguration';
import { registerActivityReservations } from 'frontend-container/config/activityReservations';
import { registerAvailability } from 'frontend-container/config/availability';
import { registerCashiering } from 'frontend-container/config/cashiering';
import { registerCentralReservationOffice } from 'frontend-container/config/centralReservationOffice';
import { registerChangelog } from 'frontend-container/config/changelog';
import { registerConfiguration } from 'frontend-container/config/configuration';
import { registerConfigurationv2 } from 'frontend-container/config/configurationv2';
import { getRegisterExternalPageFunction } from 'frontend-container/config/externalPage';
import { registerHousekeeping } from 'frontend-container/config/housekeeping';
import { registerIndividualReservations } from 'frontend-container/config/individual-reservations';
import { registerIntegrations } from 'frontend-container/config/integrations';
import { registerItinerary } from 'frontend-container/config/itinerary';
import { registerMeetingsAndEvents } from 'frontend-container/config/meetingsAndEvents';
import { registerNotifications } from 'frontend-container/config/notifications';
import { registerProfiles } from 'frontend-container/config/profiles';
import { registerRateManager } from 'frontend-container/config/rateManager';
import { registerReports } from 'frontend-container/config/reports';
import { registerReservations } from 'frontend-container/config/reservations';
import { registerReservationsGroups } from 'frontend-container/config/reservations-groups';
import { registerResourceManagement } from 'frontend-container/config/resourceManagement';
import { registerTaskManagement } from 'frontend-container/config/taskManagement';
import { registerTravelAgentCommissions } from 'frontend-container/config/travelAgentCommissions';
import { registerWorkflows } from 'frontend-container/config/workflows';
import { externalPagesConfig } from 'frontend-container/externalPages/externalPagesConfig';

import { acConfig } from '@ac/library-utils/dist/declarations';

const externalPagesToRegister = externalPagesConfig.map(
  getRegisterExternalPageFunction
);

export const registerExternalPages = (): void => {
  externalPagesToRegister.forEach((registerFunction) => registerFunction());
};

export const registerNoPermissionsPages = (): void =>
  [registerPrestepNoPermissions, registerPrestepPermissionsInProgress].forEach(
    (registerFunction) => registerFunction()
  );

export const registerApplications = (): void =>
  [
    registerLandingPage,
    registerChangelog,
    registerConfiguration,
    registerConfigurationv2,
    registerAccountsReceivable,
    registerReservations,
    registerIndividualReservations,
    registerItinerary,
    registerReservationsGroups,
    registerIntegrations,
    registerNotifications,
    registerProfiles,
    registerCashiering,
    registerActivityReservations,
    registerActivityConfiguration,
    registerCentralReservationOffice,
    registerHousekeeping,
    registerRateManager,
    registerTaskManagement,
    registerMeetingsAndEvents,
    registerTravelAgentCommissions,
    registerWorkflows,
    registerError404,
    registerError403,
    registerError503,
    registerPrestepNoPermissions,
    registerPrestepPermissionsInProgress,
    registerReports,
    registerAvailability,
    registerResourceManagement,
  ].forEach((registerFunction) => registerFunction());

export const mapFrontendUrlsToNewFrontendUrls = (
  frontendUrl: string
): string => {
  const map = {
    [acConfig.frontendUrls.accountsReceivable]:
      acConfig.newFrontendUrls.accountsReceivable,
    [acConfig.frontendUrls.centralReservationOffice]:
      acConfig.newFrontendUrls.centralReservationOffice,
    [acConfig.frontendUrls.changelog]: acConfig.newFrontendUrls.changelog,
    [acConfig.frontendUrls.configuration]:
      acConfig.newFrontendUrls.configuration,
    [acConfig.frontendUrls.configurationv2 || '/configurationv2']:
      acConfig.newFrontendUrls.configurationv2,
    [acConfig.frontendUrls.housekeeping]: acConfig.newFrontendUrls.housekeeping,
    [acConfig.frontendUrls.meetingsAndEvents]:
      acConfig.newFrontendUrls.meetingsAndEvents,
    [acConfig.frontendUrls.integrations || '/integrations']:
      acConfig.newFrontendUrls.integrations,
    [acConfig.frontendUrls.notifications || '/notifications']:
      acConfig.newFrontendUrls.notifications,
    [acConfig.frontendUrls.profiles || '/profiles']:
      acConfig.newFrontendUrls.profiles,
    [acConfig.frontendUrls.cashiering || '/cashiering']:
      acConfig.newFrontendUrls.cashiering,
    [acConfig.frontendUrls.rateManager]: acConfig.newFrontendUrls.rateManager,
    [acConfig.frontendUrls.reports]: acConfig.newFrontendUrls.reports,
    [acConfig.frontendUrls.reservations]: acConfig.newFrontendUrls.reservations,
    [acConfig.frontendUrls.taskManagement]:
      acConfig.newFrontendUrls.taskManagement,
    [acConfig.frontendUrls.travelAgentCommissions]:
      acConfig.newFrontendUrls.travelAgentCommissions,
    [acConfig.frontendUrls.reports]: acConfig.newFrontendUrls.reports,
    [acConfig.frontendUrls.availability]: acConfig.newFrontendUrls.availability,
  };

  return map[frontendUrl] || acConfig.newFrontendUrls.reservations || '/';
};
