import { ACPCustomEvents } from './../events';

export interface CashierStatusOpenedDetails {
  cashierNumber: number;
  cashierFullName: string;
}

export class CashierStatusOpenedEvent extends CustomEvent<CashierStatusOpenedDetails> {
  constructor(cashierNumber: number, cashierFullName: string) {
    super(ACPCustomEvents.CashierStatusOpened, {
      detail: { cashierNumber, cashierFullName },
    });
  }
}
