import * as React from 'react';
import { shouldUseNewMenu } from 'frontend-container/components/Menu/service';

import {
  AlignItems,
  ButtonPattern,
  ButtonTheme,
  DividerDirection,
  FlexDirection,
  IconName,
  JustifyContent,
  Size,
} from '@ac/web-components';

interface Props {
  onScrollUp: () => void;
  onScrollDown: () => void;
  scrollUpDisabled?: boolean;
  scrollDownDisabled?: boolean;
}

export const Arrows = (props: Props): JSX.Element => {
  const useNew = shouldUseNewMenu();

  return (
    <ac-flex
      alignItems={AlignItems.center}
      justifyContent={JustifyContent.center}
      direction={useNew ? FlexDirection.column : FlexDirection.row}
      class="container-menu-arrows"
    >
      <ac-divider
        direction={DividerDirection.vertical}
        dynamicClass={`bg-gray2 ${
          useNew ? '' : 'ac-spacing-left-mf=d ac-spacing-right-s'
        }`}
      />
      <ac-button
        dynamicClass={useNew ? 'ac-spacing-bottom-s' : 'ac-spacing-right-s'}
        pattern={ButtonPattern.tertiary}
        theme={ButtonTheme.light}
        onClick={props.onScrollUp}
        disabled={props.scrollUpDisabled}
      >
        <ac-icon
          icon={useNew ? IconName.arrowTop : IconName.arrowLeft}
          size={Size.lg}
        />
      </ac-button>
      <ac-button
        dynamicClass={useNew ? 'ac-spacing-bottom-s' : ''}
        pattern={ButtonPattern.tertiary}
        theme={ButtonTheme.light}
        onClick={props.onScrollDown}
        disabled={props.scrollDownDisabled}
      >
        <ac-icon
          icon={useNew ? IconName.arrowDown : IconName.arrowRight}
          size={Size.lg}
        />
      </ac-button>
      <ac-divider
        direction={DividerDirection.vertical}
        dynamicClass={`bg-gray2 ${
          useNew ? '' : 'ac-spacing-left-none ac-spacing-right-s'
        }`}
      />
    </ac-flex>
  );
};
