import { ReactNode } from 'react';
import { setDefaultWorkstation } from 'frontend-container/utils/defaultWorkstation';

import { GenericEntity } from '@ac/library-api';
import {
  Placement,
  SelectableListSlot,
  TargetValueObject,
} from '@ac/web-components';

import { handleOnChange } from '../../../utils/listOnChangeHandler';
import { mapWorkstationsToOptions } from '../mapWorkstationsToOptions';

interface Props {
  workstation?: GenericEntity;
  workstations: GenericEntity[];
  isVisible?: boolean;
  children?: ReactNode;
  setWorkstation: (value: GenericEntity) => void;
  onClose: () => void;
}

export const WorkstationList = ({
  workstation,
  workstations,
  isVisible,
  children,
  setWorkstation,
  onClose,
}: Props): JSX.Element => {
  const newHandleSelect = (value: GenericEntity | undefined): void => {
    if (!value) return;

    setWorkstation(value);
    setDefaultWorkstation(value);
  };

  const onChange = handleOnChange(workstations, newHandleSelect);

  return (
    <>
      <div id="workstation-list-wrapper">
        <ac-selectable-list
          attachTo="#workstation-list-wrapper"
          isBoundaryContainerApplied={false}
          searchable
          autoFilter={true}
          isVisible={isVisible}
          placement={Placement.bottomEnd}
          target="#workstation-trigger-button"
          targetValue={TargetValueObject.mainMenu}
          optionsMinWidth={50}
          optionsArray={mapWorkstationsToOptions(workstations)}
          optionNameField="name"
          optionSelectedField="value"
          optionValueField="value"
          dropdownClass="selectable-list-dropdown-wrapper"
          onChangeCallback={onChange}
          onCloseCallback={onClose}
          style={{ marginTop: '0' }}
          value={[workstation?.id || '']}
          offset={undefined}
        >
          <div slot={SelectableListSlot.listHeader} className="list-header">
            {children}
          </div>
        </ac-selectable-list>
      </div>
    </>
  );
};
