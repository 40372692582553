import React, { FC } from 'react';
import { MenuElementItem } from 'frontend-container/components/Menu/types';

import { Target } from '@ac/web-components';

import './MenuLinks.scss';

interface Props {
  wrapperClasses: string;
  item: MenuElementItem;
}

export const ExternalMenuLink: FC<Props> = (props) => (
  <div className={props.wrapperClasses}>
    <ac-hyperlink
      href={props.item.link}
      target={Target.blank}
      class="external-link"
    >
      {props.item.translation}
    </ac-hyperlink>
  </div>
);
