import { isAvailabilityModule } from 'frontend-container/components/Menu/utils/modules/availability';
import { isInventoryModule } from 'frontend-container/components/Menu/utils/modules/inventory';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const availabilityAppName = 'frontend-availability';
export const registerAvailability = (): void => {
  registerApplication(
    availabilityAppName,
    () => loadReactApp(acConfig.frontendUrls.availability, availabilityAppName),
    (location: Location) =>
      isAvailabilityModule(location.pathname) ||
      isInventoryModule(location.pathname)
  );
};
