import { Branding } from 'frontend-container/shared/branding/loadBranding';
import { LocalStorage } from 'frontend-container/shared/storage/localStorage';

type LoginBranding = Pick<Branding, 'loginBackgroundUrl' | 'loginLogoUrl'>;

export const LOGIN_BRANDING_KEY = 'aboveCloud.loginBranding';

export const saveLoginBrandingToStorage = (
  loginBranding: LoginBranding
): void => {
  LocalStorage.set(LOGIN_BRANDING_KEY, loginBranding);
};

export const getLoginBrandingFromStorage = (): LoginBranding | null =>
  LocalStorage.getParsed<LoginBranding>(LOGIN_BRANDING_KEY);
