import {
  AcMediaSm,
  MENU_PINNED_KEY,
  OpenCloseValues,
  PinnedHelpContainerClass,
  SpContentWidthSmName,
  SpContextHelpWidthName,
} from 'frontend-container/components/ContextHelp/constants';
import { ACPCustomEvents } from 'frontend-container/publicApi';

export const pinContextHelp = (): void => {
  document.documentElement.style.setProperty(
    `${SpContextHelpWidthName}`,
    `var(${SpContentWidthSmName})`
  );
  document.body.classList.add(PinnedHelpContainerClass);
  window.dispatchEvent(new CustomEvent(ACPCustomEvents.ContextHelpPinned));
};

export const pinContextHelpOnUserAction = (): void => {
  pinContextHelp();
  sessionStorage.setItem(MENU_PINNED_KEY, OpenCloseValues.Open);
};

export const unpinContextHelp = (): void => {
  document.documentElement.style.setProperty(
    `${SpContextHelpWidthName}`,
    '0px'
  );
  document.body.classList.remove(PinnedHelpContainerClass);
  window.dispatchEvent(new CustomEvent(ACPCustomEvents.ContextHelpUnpinned));
};

export const unpinContextHelpOnUserAction = (): void => {
  unpinContextHelp();
  sessionStorage.setItem(MENU_PINNED_KEY, OpenCloseValues.Closed);
};

export const checkIsPinAvailable = (): boolean => {
  const panelWidth =
    parseInt(
      getComputedStyle(document.documentElement)
        .getPropertyValue(SpContentWidthSmName)
        ?.substring(-2),
      10
    ) || 0;

  return window.innerWidth - panelWidth >= AcMediaSm;
};

export const checkIsPinnedByUser = (): boolean =>
  sessionStorage.getItem(MENU_PINNED_KEY) === OpenCloseValues.Open ||
  sessionStorage.getItem(MENU_PINNED_KEY) === null;

export const checkIsAlreadyPinned = (): boolean =>
  document.body.classList.contains(PinnedHelpContainerClass);
