import {
  croLandingPagePathname,
  propertyLandingPagePathname,
} from 'frontend-container/components/LandingPage/location';
import { ContextType } from 'frontend-container/components/Menu/components/Context';
import { setSessionContext } from 'frontend-container/components/Menu/components/Context/setContext';
import {
  getCroContextById,
  getCroContexts,
  hasAnyCroContext,
  isCroEnabled,
} from 'frontend-container/components/Menu/components/CroContext/service';
import {
  getPropertyContexts,
  getPropertyUnitById,
} from 'frontend-container/components/Menu/components/PropertyContext/service';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import {
  DEFAULT_LANGUAGE,
  SystemDefaultLandingPage,
} from 'frontend-container/components/Menu/components/User/UserPreferences/configuration';
import { getLanguageSettings } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/data/getLanguageSettings';
import { configurationMenu } from 'frontend-container/components/Menu/configuration/configuration';
import { isErrorPage } from 'frontend-container/components/Menu/utils/isErrorPage';
import { selectInitialContext } from 'frontend-container/components/Menu/utils/selectInitialContext';
import { updateUserPreferences } from 'frontend-container/shared/businessContext/mutators';
import { AcPropertyIdHeaderName } from 'frontend-container/shared/mainHeaders';
import {
  isAnyConfiguration,
  isLoginUrl,
  isMainUrl,
} from 'frontend-container/utils/location';
import { isError403IpWhitlelistPage } from 'frontend-container/utils/setupWhitelistingCatcher';
import { setUILanguage } from 'frontend-container/utils/UILanguage';

import {
  BaseApi,
  buildHostForRegionCode,
  ConfigBuilder,
  CUSTOM_CN1_REGION_CODE,
  getCN1HeaderForReadWriteMode,
  getIfMatchConfig,
  UserPreferences,
  UserPreferencesDetails,
} from '@ac/library-api';
import { UserPreferencesApi } from '@ac/library-api/dist/api/v0/configuration/users';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import { isValidIpAddress } from '@ac/library-utils/dist/utils';
import {
  getCurrentRegionCode,
  getGlobalRegionCode,
  RegionsCodes,
} from '@ac/library-utils/dist/utils/multi-region';

const getPreferencesHost = (): string => {
  const currentRegionCode = getCurrentRegionCode();
  const cn1Code = (CUSTOM_CN1_REGION_CODE ?? RegionsCodes.China).toLowerCase();
  if (
    currentRegionCode === cn1Code ||
    window.location.hostname.includes('localhost') ||
    isValidIpAddress(window.location.hostname)
  ) {
    return BaseApi.host;
  }

  const globalCode = getGlobalRegionCode();
  const region = acConfig.regions.find(
    (item) => item.code.toLowerCase() === globalCode
  );

  return buildHostForRegionCode(globalCode, region?.domain ?? '');
};

const saveUserPreferences = async (
  preferences: UserPreferencesDetails
): Promise<boolean> => {
  try {
    const loadedData = userService.getCurrentUserPreferences();

    if (!loadedData) {
      throw new Error('No user preferences found');
    }

    await UserPreferencesApi.putMyPreferences({
      data: {
        preferences,
      },
      customConfig: new ConfigBuilder()
        .setHost(getPreferencesHost())
        .setHeaders({
          ...getIfMatchConfig(loadedData.version).headers,
          ...getCN1HeaderForReadWriteMode(true),
          [AcPropertyIdHeaderName]: null,
        })
        .getConfig(),
    });

    await updateUserPreferences();
  } catch {
    return false;
  }

  return true;
};

const initializeUserPreferences = async (
  newRoute?: string,
  userPreferencesOverwrite?: UserPreferencesDetails
): Promise<void> => {
  if (isError403IpWhitlelistPage()) {
    return;
  }

  const { userPreferences: savedUserPreferences } =
    userService.getFullCurrentUser();

  const userPreferences = {
    ...savedUserPreferences,
    preferences: {
      ...savedUserPreferences?.preferences,
      ...userPreferencesOverwrite,
    },
  } as UserPreferences;

  initializeDefaultProperty(userPreferences);
  initializeDefaultCRO(userPreferences);
  await initializeDefaultLanguage(userPreferences);
  initializeDefaultLandingPage(userPreferences, newRoute);
};

const initializeDefaultProperty = (
  userPreferences: UserPreferences | undefined
): void => {
  const propertyId = SessionService.getPropertyId();

  if (propertyId) return;

  setDefaultProperty(userPreferences?.preferences);
};

const initializeDefaultCRO = (userPreferences?: UserPreferences): void => {
  const croId = SessionService.getCentralReservationOfficeId();

  if (croId) return;

  setDefaultCRO(userPreferences?.preferences);
};

const setDefaultCRO = (userPreferences?: UserPreferencesDetails): void => {
  const croContexts = getCroContexts();

  const context = selectInitialContext(
    croContexts,
    userPreferences?.defaultCentralReservationOfficeId
  );

  if (!context) return;

  setSessionContext(context);
};

const setDefaultProperty = (userPreferences?: UserPreferencesDetails): void => {
  const contexts = getPropertyContexts();

  const context = selectInitialContext(
    contexts,
    userPreferences?.defaultPropertyId
  );

  if (!context) return;

  setSessionContext(context);
};

const initializeDefaultLanguage = async (
  userPreferences: UserPreferences | undefined
): Promise<void> => {
  const languageSettings = getLanguageSettings();

  const defaultLanguage = (
    userPreferences?.preferences?.defaultLanguage ??
    languageSettings?.value.languageCode ??
    DEFAULT_LANGUAGE
  ).toLowerCase();
  await setUILanguage(defaultLanguage);
};

const initializeDefaultLandingPage = (
  userPreferences: UserPreferences | undefined,
  newRoute: string | undefined
): void => {
  const pathname = window.location.pathname;
  const isCurrentUrlConfiguration = isAnyConfiguration(pathname);
  const shouldRedirectToConfiguration =
    !isCurrentUrlConfiguration &&
    getIsEmptyTenant() &&
    !LoginService.isSuperUser();

  if (shouldRedirectToConfiguration) {
    const customerId = LoginService.authData()?.tenantId;
    const organizationStructureLink =
      configurationMenu.items[1].link.replace(
        /:customerId/g,
        customerId ?? ''
      ) ?? SystemDefaultLandingPage.pathname;

    return history.replaceState({}, '', organizationStructureLink);
  }

  const defaultLandingPageRoute = shouldNavigateToCroLandingPage(
    userPreferences
  )
    ? croLandingPagePathname
    : propertyLandingPagePathname;

  if (newRoute) {
    const newPathname = !(isMainUrl(newRoute) || isErrorPage(newRoute))
      ? newRoute
      : defaultLandingPageRoute;
    if (!newPathname.startsWith(pathname)) {
      return history.replaceState({}, '', newPathname);
    }
  }

  if (isLoginUrl(pathname) || isMainUrl(pathname)) {
    return history.replaceState({}, '', defaultLandingPageRoute);
  }
};

const getIsEmptyTenant = (): boolean => {
  return getPropertyContexts().length + getCroContexts().length === 0;
};

const shouldNavigateToCroLandingPage = (
  userPreferences?: UserPreferences
): boolean => {
  if (LoginService.isSuperUser()) {
    return false;
  }

  const isDefaultUserWorkspaceCRO =
    userPreferences?.preferences?.defaultWorkspace === ContextType.CRO;

  return isCroEnabled() && hasAnyCroContext() && isDefaultUserWorkspaceCRO;
};

const validateUserPreferences = async (): Promise<void> => {
  const preferences = userService.getCurrentUserPreferences();
  const preferencesDetails = preferences?.preferences;

  if (!preferencesDetails) {
    return;
  }

  const { defaultCentralReservationOfficeId, defaultPropertyId } =
    preferencesDetails;
  const validatedPreferences = { ...preferencesDetails };

  if (defaultCentralReservationOfficeId) {
    const isDefaultCroEnabled = getCroContextById(
      defaultCentralReservationOfficeId
    );

    if (!isDefaultCroEnabled) {
      validatedPreferences.defaultCentralReservationOfficeId = undefined;
      validatedPreferences.defaultCentralReservationOfficeLandingScreen =
        undefined;
    }
  }

  if (defaultPropertyId) {
    const isDefaultPropertyEnabled = getPropertyUnitById(defaultPropertyId);

    if (!isDefaultPropertyEnabled) {
      validatedPreferences.defaultPropertyId = undefined;
      validatedPreferences.defaultLandingScreen = undefined;
    }
  }

  const isValidated =
    validatedPreferences.defaultCentralReservationOfficeId !==
      defaultCentralReservationOfficeId ||
    validatedPreferences.defaultPropertyId !== defaultPropertyId;

  if (isValidated) {
    setDefaultCRO(validatedPreferences);
    setDefaultProperty(validatedPreferences);

    await saveUserPreferences(validatedPreferences);
  }
};

export const userPreferencesService = {
  saveUserPreferences,
  initializeUserPreferences,
  validateUserPreferences,
  setDefaultCRO,
  setDefaultProperty,
};
