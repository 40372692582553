import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AlignItems,
  FlexDirection,
  Orientation,
  Size,
  TextSize,
  TextWeight,
} from '@ac/web-components';

import { Context } from '../context';

import './ContextHeader.scss';

interface Props {
  context: Context | undefined;
  date: string | undefined;
  dateLabel: string;
  time: string | undefined;
  timeLabel: string;
  isInsideTooltip?: boolean;
}

export const ContextHeader: FC<Props> = ({
  context,
  date,
  dateLabel,
  time,
  timeLabel,
  isInsideTooltip,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${
        isInsideTooltip ? 'context-header-in-tooltip' : ''
      } container-menu-item-header`}
    >
      <ac-flex alignItems={AlignItems.center} class="ac-spacing-bottom-sm">
        {context && (
          <ac-icon
            class="ac-spacing-right-sm"
            icon={context.icon}
            size={Size.lg}
          />
        )}
        <ac-flex direction={FlexDirection.column}>
          <ac-text weight={TextWeight.semibold} uppercase>
            {context?.code}
          </ac-text>
          <ac-text size={TextSize.sm}>{context?.name}</ac-text>
        </ac-flex>
      </ac-flex>
      <ac-flex alignItems={AlignItems.center}>
        <ac-text-group
          label={t('MENU.CONTEXT.REGION')}
          orientation={Orientation.vertical}
          class="ac-spacing-right-sm"
        >
          <ac-text uppercase>{context?.regionCode}</ac-text>
        </ac-text-group>
        {date && context && (
          <ac-text-group
            label={dateLabel}
            orientation={Orientation.vertical}
            class="ac-spacing-right-sm"
          >
            {date}
          </ac-text-group>
        )}
        {time && context && (
          <ac-text-group
            label={timeLabel}
            orientation={Orientation.vertical}
            class="ac-spacing-right-sm"
          >
            <span>{time}</span>
          </ac-text-group>
        )}
      </ac-flex>
    </div>
  );
};
