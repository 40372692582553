import { SyntheticEvent } from 'react';
import { getAvailableMenuItemsCount } from 'frontend-container/components/Menu/menuItems';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { isPathnameWithoutProperty } from 'frontend-container/components/Menu/utils/isPathnameWithoutProperty';
import throttle from 'lodash.throttle';

import { repeatableCall } from '@ac/library-utils/dist/utils';

export interface OnScrollDownIndex {
  newStartIndex: number;
  newEndIndex: number;
}

export let startIndex: number | undefined;
export const setStartIndex = (
  newStartIndex: number | undefined
): number | undefined => (startIndex = newStartIndex);
export let endIndex: number | undefined;
export const setEndIndex = (
  newEndIndex: number | undefined
): number | undefined => (endIndex = newEndIndex);

export const adjustScrollToCurrentMenuItemBase = async (
  id: string,
  menuElements: MenuElement[] = [],
  onScrollDown: (
    event: SyntheticEvent<Element, Event> | undefined,
    menuElements: MenuElement[]
  ) => void,
  onScrollUp: (
    event: SyntheticEvent<Element, Event> | undefined,
    menuElements: MenuElement[]
  ) => void
): Promise<void> => {
  startIndex = 0;
  endIndex = 0;
  await repeatableCall(
    () => new Promise((resolve) => resolve(true)),
    () => !!document.querySelector(`[data-test-selector=${id}].hydrated`),
    {
      intervalTime: 500,
      repeatCount: 50,
    }
  );
  const foundIndex: number | undefined = menuElements.findIndex(
    (item) => item.id === id
  );
  let sum = 0;
  let count = getAvailableMenuItemsCount(
    isPathnameWithoutProperty(),
    true,
    endIndex > 0 ? endIndex + 1 : undefined
  );
  startIndex = 0;
  endIndex = count;
  sum += count;
  if ((foundIndex ?? 0) >= sum) {
    do {
      count = getAvailableMenuItemsCount(
        isPathnameWithoutProperty(),
        true,
        endIndex > 0 ? endIndex + 1 : undefined
      );
      sum += count || menuElements.length;
      if ((foundIndex ?? 0) >= sum) {
        const { newStartIndex, newEndIndex } =
          getNewStartEndIndexOnScrollDown();
        startIndex = newStartIndex;
        endIndex = newEndIndex;
      }
    } while ((foundIndex ?? 0) > sum);
    onScrollDown(undefined, menuElements);
  } else {
    onScrollUp(undefined, menuElements);
  }
};

export const adjustScrollToCurrentMenuItem = throttle(
  adjustScrollToCurrentMenuItemBase,
  3000
);

export const getNewStartEndIndexOnScrollDown = (): OnScrollDownIndex => {
  const count = getAvailableMenuItemsCount(
    isPathnameWithoutProperty(),
    true,
    startIndex ?? 0
  );
  const newStartIndex = (startIndex ?? 0) + count;
  const countEnd = getAvailableMenuItemsCount(
    isPathnameWithoutProperty(),
    true,
    newStartIndex
  );
  const newEndIndex = newStartIndex + countEnd - 1;

  return {
    newStartIndex,
    newEndIndex,
  };
};
