import { useCallback, useState } from 'react';
import { DateTimeHook } from 'frontend-container/components/Menu/components/Context';

import {
  AlignItems,
  Color,
  FlexDirection,
  TextColor,
} from '@ac/web-components';

import { ContextDropdown } from './components/ContextDropdown';
import { ContextHeader } from './components/ContextHeader';
import { ContextList } from './components/ContextList';
import { Context } from './context';

interface Props {
  contextList: Context[];
  selected: Context | undefined;
  onSelect: (selected: Context | undefined) => void;
  useDateTime: DateTimeHook;
  dateLabel: string;
  timeLabel: string;
  color?: string | Color | TextColor;
}

export const ContextComponent = ({
  contextList,
  selected,
  onSelect,
  useDateTime,
  dateLabel,
  timeLabel,
  color,
}: Props): JSX.Element => {
  const [isListVisible, setIsListVisible] = useState<boolean>(false);

  const { date, dateFormat, time } = useDateTime();

  const openList = (): void => setIsListVisible(true);

  const closeList = (): void => setIsListVisible(false);

  const handleSelect = useCallback(
    (context: Context) => {
      onSelect(context);
      closeList();
    },
    [onSelect]
  );

  return (
    <ac-flex
      id="context-select-wrapper"
      class="ac-spacing-right-s"
      direction={FlexDirection.row}
      alignItems={AlignItems.center}
    >
      <ac-click-outside onClickOutsideCallback={closeList}>
        <ContextDropdown
          context={selected}
          businessDate={date}
          dateFormat={dateFormat}
          propertyTime={time}
          color={color}
          isListVisible={isListVisible}
          onClick={openList}
        />
        <ContextList
          contextList={contextList}
          selected={selected}
          isVisible={isListVisible}
          onSelect={handleSelect}
          onClose={closeList}
        >
          <ContextHeader
            context={selected}
            date={date}
            dateLabel={dateLabel}
            time={time}
            timeLabel={timeLabel}
          />
        </ContextList>
      </ac-click-outside>
    </ac-flex>
  );
};
