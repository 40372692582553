import { handleNoUnitsOrPermissions } from 'frontend-container/components/LoginLogout/utils/handleNoUnitsOrPermissions';
import {
  Context,
  ContextMapper,
} from 'frontend-container/components/Menu/components/Context';
import { isCroEnabled } from 'frontend-container/components/Menu/components/CroContext/service';
import { CentralReservationOfficeDefaultLandingPage } from 'frontend-container/components/Menu/components/User/UserPreferences/configuration';
import { BRANDING_PROPERTY_IDENTIFIER_COLOR } from 'frontend-container/components/Menu/constants';
import {
  getBusinessContextData,
  getPropertyContextData,
} from 'frontend-container/shared/businessContext/getBusinessContext';
import { isEmpty } from 'lodash';
import { navigateToUrl } from 'single-spa';

import { OrganizationUnitType, UnitAssignmentListItem } from '@ac/library-api';

export const getTenantUnit = (): UnitAssignmentListItem | undefined => {
  const { user } = getBusinessContextData();

  if (user.units.defaultUnit?.type === OrganizationUnitType.Tenant) {
    return user.units.defaultUnit;
  }

  return;
};

export const getPropertyUnits = (): UnitAssignmentListItem[] => {
  const { user } = getBusinessContextData();

  return user.units.units.filter(
    ({ type }) => type === OrganizationUnitType.Property
  );
};

export const getPropertyContexts = (): Context[] => {
  const userProperties = getPropertyUnits();

  return userProperties.map(ContextMapper.unitToContext);
};

export const getPropertyContextById = (
  propertyId: string
): Context | undefined => {
  return getPropertyContexts().find(({ id }) => id === propertyId);
};

export const getPropertyUnitById = (
  propertyId: string
): UnitAssignmentListItem | undefined => {
  return getPropertyUnits().find(({ unitId }) => unitId === propertyId);
};

export const getCurrentPropertyRegionCode = (): string | undefined => {
  const currentProperty = getPropertyContextData();

  return currentProperty?.details.regionCode;
};

export const getCurrentPropertyIdentifierColor = (): string | undefined => {
  const currentProperty = getPropertyContextData();
  const propertySettings = currentProperty?.settings.effectiveSettingsDetails;

  if (!propertySettings) {
    return;
  }

  const setting = propertySettings.find(
    (item) => item.code === BRANDING_PROPERTY_IDENTIFIER_COLOR
  );

  return setting ? (setting.value as { value: string }).value : undefined;
};

export const redirectToCroOrNoPermissionsPage = async (
  croContexts: Context[]
): Promise<void> => {
  if (isEmpty(croContexts) || !isCroEnabled()) {
    await handleNoUnitsOrPermissions();

    return;
  }

  navigateToUrl(CentralReservationOfficeDefaultLandingPage.pathname);
};
