import { isDefined } from '@ac/library-utils/dist/utils';

import { Context } from './context';

export const mapContextsToOptions = (
  contextList: Context[]
): Array<{ name: string; value: string }> =>
  contextList.map((context) => ({
    name: [context.code, context.name].filter(isDefined).join(' - '),
    value: context.id,
  }));
