import { FC } from 'react';
import { SubItem } from 'frontend-container/components/Menu/components/Item/SubItem/SubItem';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import { useTranslationWithEnglishInParentheses } from 'frontend-container/utils/hooks/useTranslationWithEnglishParentheses';
import { isError403IpWhitlelistPage } from 'frontend-container/utils/setupWhitelistingCatcher';

interface Props {
  togglePasswordModal: () => void;
  toggleUserPreferencesModal: () => void;
}

export const UserInfotipBody: FC<Props> = ({
  togglePasswordModal,
  toggleUserPreferencesModal,
}) => {
  const logout = async (): Promise<void> => {
    await userService.logout(false);
  };

  const translateWithEnglishParentheses =
    useTranslationWithEnglishInParentheses();

  return (
    <>
      {/* TODO, remove if will be in code for too long unused and without the future perspective :P
      <SubItem
      key={'new-menu'}
      active={false}
      item={{
        link: '',
        translation: useNew ? 'MENU.LOGGED_USER.OLD_MENU' : 'MENU.LOGGED_USER.NEW_MENU'
      }}
      onClick={toggleMenuStyle}
    /> */}
      {!isError403IpWhitlelistPage() && (
        <>
          <SubItem
            key={'password-menu'}
            active={false}
            item={{
              link: '',
              translation: 'MENU.LOGGED_USER.CHANGE_PASSWORD',
            }}
            onClick={togglePasswordModal}
          />

          <SubItem
            key={'language-menu'}
            active={false}
            item={{
              link: '',
              translation: translateWithEnglishParentheses(
                'MENU.LOGGED_USER.USER_PREFERENCES.TITLE'
              ),
            }}
            onClick={toggleUserPreferencesModal}
          />
        </>
      )}
      <SubItem
        key={'logout'}
        active={false}
        item={{
          link: '',
          translation: 'MENU.LOGGED_USER.LOGOUT',
        }}
        onClick={logout}
      />
    </>
  );
};
