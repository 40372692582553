import { useCallback } from 'react';
import { navigateToUrl } from 'single-spa';

import {
  BusinessContextUnitType,
  CroBusinessContextUnitIdentifier,
  PropertyBusinessContextUnitIdentifier,
} from '@ac/library-api';
import { SessionService } from '@ac/library-utils/dist/services';
import {
  ButtonPattern,
  ButtonTheme,
  DividerDirection,
  IconName,
  Size,
  TextColor,
} from '@ac/web-components';

interface Props {
  title: string;
  route: string;
  icon: IconName;
  targetUnit:
    | CroBusinessContextUnitIdentifier
    | PropertyBusinessContextUnitIdentifier;
}

export const SwitchToContextType = ({
  title,
  route,
  icon,
  targetUnit,
}: Props): JSX.Element => {
  const switchToContextAction = useCallback(() => {
    navigateToUrl(route);
    if (targetUnit.type === BusinessContextUnitType.CentralReservationOffice) {
      SessionService.setCentralReservationOfficeId(targetUnit.id);
    } else {
      SessionService.setPropertyId(targetUnit.id);
    }
  }, [route, targetUnit]);

  return (
    <div className="container-menu-item">
      <ac-button
        pattern={ButtonPattern.tertiary}
        theme={ButtonTheme.light}
        onClick={switchToContextAction}
      >
        <ac-icon icon={icon} size={Size.lg} />
        <ac-text color={TextColor.white} class="ac-spacing-left-sm">
          {title}
        </ac-text>
      </ac-button>
      <ac-divider direction={DividerDirection.vertical} class="bg-gray2" />
    </div>
  );
};
