import { isAccountsReceivableModule } from 'frontend-container/components/Menu/utils/modules/accountsReceivable';
import { isActivityReservationsModule } from 'frontend-container/components/Menu/utils/modules/activityReservations';
import { isAvailabilityModule } from 'frontend-container/components/Menu/utils/modules/availability';
import { isCashieringModule } from 'frontend-container/components/Menu/utils/modules/cashiering';
import { isHousekeepingModule } from 'frontend-container/components/Menu/utils/modules/housekeeping';
import { isIndividualReservationsModule } from 'frontend-container/components/Menu/utils/modules/individualReservations';
import { isPropertyDependentInventoryModule } from 'frontend-container/components/Menu/utils/modules/inventory';
import { isMeetingsAndEventsModule } from 'frontend-container/components/Menu/utils/modules/meetingsAndEvents';
import { isProfilesModule } from 'frontend-container/components/Menu/utils/modules/profiles';
import { isRateManagerModule } from 'frontend-container/components/Menu/utils/modules/rateManager';
import { isReportsModule } from 'frontend-container/components/Menu/utils/modules/reports';
import { isReservationsModule } from 'frontend-container/components/Menu/utils/modules/reservations';
import { isReservationsGroupsModule } from 'frontend-container/components/Menu/utils/modules/reservations-groups';
import { isTaskManagementModule } from 'frontend-container/components/Menu/utils/modules/taskManagement';
import { isTravelAgentCommisionsModule } from 'frontend-container/components/Menu/utils/modules/travelAgentCommissions';

export const isCashierVisible = (): boolean => {
  const pathUtilFunctions = [
    isAccountsReceivableModule,
    isMeetingsAndEventsModule,
    isTravelAgentCommisionsModule,
    isTaskManagementModule,
    isReportsModule,
    isReservationsModule,
    isIndividualReservationsModule,
    isReservationsGroupsModule,
    isReservationsModule,
    isHousekeepingModule,
    isRateManagerModule,
    isProfilesModule,
    isCashieringModule,
    isActivityReservationsModule,
    isAvailabilityModule,
    isPropertyDependentInventoryModule,
  ];

  return pathUtilFunctions.some((func) => func());
};
