import { FC, MouseEvent, useEffect, useState } from 'react';
import { MaintenanceModal } from 'frontend-container/components/Menu/components/Logo/MaintenanceModal/MaintenanceModal';
import { useBranding } from 'frontend-container/shared/branding/useBranding';

import shijiLogo from './assets/shiji-logo-white-small_v2.png';

import './Logo.scss';

type TapHandler = (event: MouseEvent<HTMLElement>) => boolean;

export const Logo: FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [mainMenuLogo, setMainMenuLogo] = useState<string>();
  const branding = useBranding();

  const onDoubleClick = (): void => {
    setShowModal(true);
  };

  useEffect(() => {
    const mainMenuLogoBlob = branding?.mainMenuLogo;
    const mainMenuLogoObjUrl =
      mainMenuLogoBlob && URL.createObjectURL(mainMenuLogoBlob);

    setMainMenuLogo(mainMenuLogoObjUrl);

    return () => {
      if (mainMenuLogoObjUrl) {
        URL.revokeObjectURL(mainMenuLogoObjUrl);
      }
    };
  }, [branding?.mainMenuLogo]);

  return (
    <>
      <div
        className="menu-logo-container"
        onClick={tapHandler(setShowModal)}
        onDoubleClick={onDoubleClick}
      >
        <div className="menu-logo">
          <img src={mainMenuLogo || shijiLogo} />
        </div>
      </div>
      {showModal && (
        <MaintenanceModal onClose={(): void => setShowModal(false)} />
      )}
    </>
  );
};

const tapHandler = (setShowModal: (value: boolean) => void): TapHandler => {
  let tapedTwice = false;

  return (event: MouseEvent<HTMLElement>): boolean => {
    if (!tapedTwice) {
      tapedTwice = true;
      setTimeout(() => {
        tapedTwice = false;
      }, 300);

      return false;
    }
    event.preventDefault();
    setShowModal(true);

    return false;
  };
};
