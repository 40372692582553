import { useEffect, useState } from 'react';
import { ACPCustomEvents } from 'frontend-container/publicApi';
import {
  Branding,
  loadBranding,
} from 'frontend-container/shared/branding/loadBranding';

export const useBranding = (): Branding | undefined => {
  const [branding, setBranding] = useState<Branding | undefined>();
  useEffect(() => {
    const load = async (): Promise<void> => {
      const loadBrandingResult = await loadBranding();

      if (loadBrandingResult) {
        setBranding(loadBrandingResult);
      }
    };

    load();

    window.addEventListener(ACPCustomEvents.BusinessContextDataUpdated, load);

    return () =>
      window.removeEventListener(
        ACPCustomEvents.BusinessContextDataUpdated,
        load
      );
  }, []);

  return branding;
};
