import { auditChangelogUrl } from 'frontend-container/components/Menu/configuration/changelog';

import { acConfig } from '@ac/library-utils/dist/declarations';
import { LoginService } from '@ac/library-utils/dist/services';

export const isChangelogModule = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(acConfig.newFrontendUrls.changelog ?? '');
};

export const isChangelogModuleForSuperAdmin = (): boolean => {
  return (
    isChangelogModule(window.location.pathname ?? '') &&
    !isAuditChangelog(window.location.pathname ?? '') &&
    Boolean(LoginService.isSuperUser())
  );
};

export const isAuditChangelog = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(auditChangelogUrl);
};

export default {
  isChangelogModule,
  isAuditChangelog,
};
