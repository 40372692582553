import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import { propertyPermissionKeys, TasksSettingCode } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const taskManagementMenu: MenuElement = {
  translation: 'MENU.TASK_MANAGEMENT.TITLE',
  icon: IconName.activities,
  id: 'menu-task-management',
  settings: [TasksSettingCode.UseTaskManagement],
  items: [
    {
      link: `${acConfig.newFrontendUrls.taskManagement}/dashboard`,
      aliases: [acConfig.newFrontendUrls.taskManagement],
      code: 'TaskManagementDashboard',
      translation: 'MENU.TASK_MANAGEMENT.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [propertyPermissionKeys.tasks.viewAndUpdateTasks],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+t',
      keyboardShortcutMac: '⌥+⌘+t',
      keyCode: 84,
    },
  ],
};
