import { isErrorPage } from 'frontend-container/components/Menu/utils/isErrorPage';
import { isPathnameWithoutProperty } from 'frontend-container/components/Menu/utils/isPathnameWithoutProperty';
import { isCentralReservationOfficeModule } from 'frontend-container/components/Menu/utils/modules/centralReservationOffice';
import { getBusinessContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import {
  BusinessContextUnitIdentifier,
  BusinessContextUnitType,
} from '@ac/library-api';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import { isUUIDV4 } from '@ac/library-utils/dist/utils';

export type UnitIdentifierForCurrentLocation = {
  id?: string;
  tenantId?: string;
  propertyId?: string;
  type: BusinessContextUnitIdentifier['type'];
};

export const getUnitForCurrentLocation = (
  pathname: string = window.location.pathname,
  fallbackCurrentUnit?: UnitIdentifierForCurrentLocation
): UnitIdentifierForCurrentLocation => {
  if (isErrorPage(pathname)) {
    return fallbackCurrentUnit || getCurrentIdentifier();
  }

  const { tenantIdFromUrl, propertyIdFromUrl } = getIdsFromUrl(pathname);
  const tenantId = tenantIdFromUrl || LoginService.authData()?.tenantId;
  const propertyId = propertyIdFromUrl || SessionService.getPropertyId();

  const isSystemUser = LoginService.isSuperUser();

  if (isSystemUser && !tenantId) {
    return {
      id: undefined,
      type: BusinessContextUnitType.System,
    };
  }

  if (isCentralReservationOfficeModule(pathname)) {
    return {
      id: SessionService.getCentralReservationOfficeId(),
      tenantId,
      propertyId,
      type: BusinessContextUnitType.CentralReservationOffice,
    };
  }

  if (isPathnameWithoutProperty(pathname)) {
    return {
      id: tenantId,
      type: BusinessContextUnitType.Tenant,
    };
  }

  return {
    id: propertyId,
    tenantId,
    type: BusinessContextUnitType.Property,
  };
};

const getCurrentIdentifier = (): UnitIdentifierForCurrentLocation => {
  const businessContextData = getBusinessContextData();

  return { id: businessContextData.unitId, type: businessContextData.unitType };
};

const getIdsFromUrl = (
  pathname: string
): {
  tenantIdFromUrl?: string;
  propertyIdFromUrl?: string;
} => {
  let tenantIdFromUrl: string | undefined;
  let propertyIdFromUrl: string | undefined;

  pathname.split('/').forEach((part, index, array) => {
    if (part.includes('customers')) {
      const tenantIdCandidate = array[index + 1];
      if (isUUIDV4(tenantIdCandidate)) {
        tenantIdFromUrl = tenantIdCandidate;
      }
    }

    if (part.includes('properties')) {
      const propertyIdCandidate = array[index + 1];
      if (isUUIDV4(propertyIdCandidate)) {
        propertyIdFromUrl = propertyIdCandidate;
      }
    }
  });

  return {
    tenantIdFromUrl,
    propertyIdFromUrl,
  };
};
