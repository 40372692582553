import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from 'frontend-container/components/Menu/components/Context';
import { ContextDropdown } from 'frontend-container/components/Menu/components/Context/components/ContextDropdown';
import { ContextHeader } from 'frontend-container/components/Menu/components/Context/components/ContextHeader';
import { getPropertyContexts } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { fetchPropertyBrandingColor } from 'frontend-container/components/Menu/components/PropertySelectorReadOnly/fetchPropertyBrandingColor';
import { usePropertyReadOnlyDate } from 'frontend-container/components/Menu/components/PropertySelectorReadOnly/usePropertyReadOnlyDateTime';
import { selectInitialContext } from 'frontend-container/components/Menu/utils/selectInitialContext';

import {
  SessionDataChangedEventType,
  SessionService,
} from '@ac/library-utils/dist/services';
import {
  DividerDirection,
  InfotipSize,
  InfotipSlot,
  TargetValueObject,
} from '@ac/web-components';

interface Props {
  isHidden?: boolean;
}

export const PropertySelectorReadOnly = ({
  isHidden,
}: Props): JSX.Element | null => {
  const [context, setContext] = useState<Context | undefined>(undefined);
  const [brandingColor, setBrandingColor] = useState<string>();
  const propertyContexts = getPropertyContexts();
  const hasAnyProperty = Boolean(propertyContexts.length);
  const propertyDate = usePropertyReadOnlyDate();

  const { t } = useTranslation();

  useEffect(() => {
    const setInitialContext = (): void => {
      const initialContext = selectInitialContext(
        propertyContexts,
        SessionService.getPropertyId()
      );

      setContext(initialContext);
    };
    setInitialContext();

    window.addEventListener(SessionDataChangedEventType, setInitialContext);

    return () =>
      window.removeEventListener(
        SessionDataChangedEventType,
        setInitialContext
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!context || !context.id) {
      return;
    }

    const loadPropertyBrandingColor = async (): Promise<void> => {
      const result = await fetchPropertyBrandingColor(context.id);

      if (result) {
        setBrandingColor(result);
      }
    };

    loadPropertyBrandingColor();
  }, [context]);

  if (!hasAnyProperty || isHidden) {
    return null;
  }

  return (
    <div id="context-list-header-wrapper">
      <ac-flex>
        <ac-infotip
          for="#read-only-property-selector-button"
          attachTo="#context-list-header-wrapper"
          targetValue={TargetValueObject.mainMenu}
          size={InfotipSize.sm}
        >
          <div slot={InfotipSlot.component}>
            <ContextDropdown
              id="read-only-property-selector-button"
              context={context}
              businessDate={propertyDate?.date}
              dateFormat={propertyDate?.dateFormat}
              propertyTime={propertyDate?.time}
              color={brandingColor}
            />
          </div>
          <div slot={InfotipSlot.content}>
            <ContextHeader
              date={propertyDate?.date}
              time={propertyDate?.time}
              dateLabel={t('MENU.CONTEXT.PROPERTY.DATE')}
              timeLabel={t('MENU.CONTEXT.PROPERTY.TIME')}
              context={context}
              isInsideTooltip
            />
          </div>
        </ac-infotip>
        <ac-divider direction={DividerDirection.vertical} class="bg-gray2" />
      </ac-flex>
    </div>
  );
};
