import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setCashierSecret } from 'frontend-container/components/Menu/components/Cashier/service';
import { PasswordField } from 'frontend-container/components/Menu/components/PasswordField/PasswordField';

import { ErrorDetail } from '@ac/library-api';
import { Color, ErrorObject, ErrorType, IconName } from '@ac/web-components';

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  isFirstAttempt: boolean;
  isOpenCashierAllowed: boolean;
}

export const ChangeCashierSecretModal: FC<Props> = (props) => {
  const { onClose, onConfirm, isFirstAttempt = false } = props;
  const { t } = useTranslation();
  const [secret, setSecret] = useState('');
  const [secretConfirmation, setSecretConfirmation] = useState('');
  const [apiErrors, setApiErrors] = useState<ErrorDetail[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const confirmButtonText = apiErrors.length
    ? 'MENU.MODAL.COMMON.TRY_AGAIN'
    : 'MENU.MODAL.COMMON.CONFIRM';

  const validationStatus: ErrorObject[] =
    secret && secretConfirmation && secret !== secretConfirmation
      ? [
          {
            description: t(
              'MENU.CASHIER.CHANGE_SECRET.MODAL.SAME_SECRETS_ERROR'
            ),
            severity: ErrorType.error,
          },
        ]
      : [];

  const isConfirmButtonDisabled = !(
    secret &&
    secretConfirmation &&
    secret === secretConfirmation
  );

  const handleSubmit = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await setCashierSecret(secret);
      onConfirm();
    } catch (error) {
      setApiErrors([...apiErrors, error]);
      setIsLoading(false);
    }
  };

  const isError = !props.isOpenCashierAllowed || Boolean(apiErrors.length);
  const errorMessage = !props.isOpenCashierAllowed
    ? 'MENU.CASHIER.CHANGE_SECRET.ERROR.NO_PERMISSIONS'
    : 'MENU.CASHIER.CHANGE_SECRET.ERROR.REQUEST_FAILED';

  return (
    <ac-modal
      confirmButton={{
        disabled: !props.isOpenCashierAllowed || isConfirmButtonDisabled,
        content: {
          text: t(confirmButtonText),
        },
        onClick: handleSubmit,
      }}
      cancelButton={{
        onClick: onClose,
        content: {
          text: t('MENU.MODAL.COMMON.CANCEL'),
        },
      }}
      modalTitle={t('MENU.CASHIER.CHANGE_SECRET.MODAL.TITLE')}
      loading={isLoading}
    >
      <form>
        <ac-box>
          {isFirstAttempt ? (
            <ac-flex class="ac-spacing-top-sm">
              <ac-validation-message
                iconName={IconName.alert}
                iconColor={Color.alert}
                label={t('MENU.CASHIER.OPEN.FIRST_ATTEMPT')}
              />
            </ac-flex>
          ) : (
            <ac-box class="ac-spacing-bottom-md">
              <ac-text>
                {t('MENU.CASHIER.CHANGE_SECRET.MODAL.DESCRIPTION')}
              </ac-text>
            </ac-box>
          )}
          <ac-box>
            <PasswordField
              id="secret"
              label={t('MENU.CASHIER.CHANGE_SECRET.MODAL.FIELD_LABEL_NEW')}
              required
              value={secret}
              onChange={setSecret}
            />
          </ac-box>
          <ac-box>
            <PasswordField
              id="secret-confirm"
              label={t('MENU.CASHIER.CHANGE_SECRET.MODAL.FIELD_LABEL_CONFIRM')}
              required
              value={secretConfirmation}
              onChange={setSecretConfirmation}
              validationStatus={validationStatus}
            />
          </ac-box>
          {isError && (
            <ac-flex class="ac-spacing-top-sm">
              <ac-validation-message
                iconName={IconName.alert}
                iconColor={Color.alert}
                label={t(errorMessage)}
              />
            </ac-flex>
          )}
        </ac-box>
      </form>
    </ac-modal>
  );
};
