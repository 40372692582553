import { pathnamePrestepNoPermissions } from 'frontend-container/components/Prestep/AccessDenied/NoPermissions/config';
import { pathnamePrestepPermissionsInProgress } from 'frontend-container/components/Prestep/AccessDenied/PermissionsInProgress/config';
import { AcPropertyIdHeaderName } from 'frontend-container/shared/mainHeaders';
import { navigateToUrl } from 'single-spa';

import { ConfigBuilder, UserDetails } from '@ac/library-api';
import { UsersApi } from '@ac/library-api/dist/api/v0/identity';

export const handleNoUnitsOrPermissions = async (): Promise<void> => {
  const myDetails = (await UsersApi.getMe({
    customConfig: new ConfigBuilder()
      .setHeaders({ [AcPropertyIdHeaderName]: null })
      .getConfig(),
  })) as UserDetails;
  if (myDetails.externalId) {
    navigateToUrl(pathnamePrestepPermissionsInProgress);
  } else {
    navigateToUrl(pathnamePrestepNoPermissions);
  }
};
