import { ButtonTrigger } from 'frontend-container/components/Menu/components/MainMenuInfotip/ButtonTrigger';

import {
  Color,
  DividerDirection,
  DividerSpacing,
  IconName,
} from '@ac/web-components';

interface Props {
  isWorkstationSelected: boolean;
}

export const WorkstationTrigger = ({
  isWorkstationSelected,
}: Props): JSX.Element => (
  <>
    <ButtonTrigger
      triggerId="workstation-trigger-button"
      buttonIcon={IconName.workstation}
      statusInactiveIcon={IconName.cancel}
      inactiveColor={Color.gray}
      active={isWorkstationSelected}
      additionalClass="ac-spacing-right-md"
    />
    <ac-divider
      direction={DividerDirection.vertical}
      spacing={DividerSpacing.none}
    />
  </>
);
