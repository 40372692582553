import { getTimeFormatFromCode } from 'frontend-container/components/Menu/components/Context/shared/utils/getTimeFormat';
import { getCurrentPropertyRegionCode } from 'frontend-container/components/Menu/components/PropertyContext/service';
import {
  getCustomerContextData,
  getPropertyContextData,
} from 'frontend-container/shared/businessContext/getBusinessContext';
import { formatDate } from 'frontend-container/utils/dateUtils';

import {
  BaseApi,
  CurrentPropertyDateTime,
  getCustomConfigWithPropertyRegionSpecificHost,
  mapShortDateFormat,
} from '@ac/library-api';
import { CurrentDateApi } from '@ac/library-api/dist/api/v0/propertyManagement';

export const DATE_FORMAT = 'YYYY-MM-DD';

export interface FormattedDate {
  date: string;
  dateFormat: string;
}

export const getFormattedBusinessDate = async (): Promise<FormattedDate> => {
  const propertyContext = getPropertyContextData();
  const customerContext = getCustomerContextData();

  if (!propertyContext) {
    return {
      date: '',
      dateFormat: '',
    };
  }

  const dateFormat = mapShortDateFormat(
    propertyContext.details.shortDateFormat ||
      customerContext?.details.shortDateFormat ||
      DATE_FORMAT
  );

  return {
    date: formatDate(propertyContext.businessDate, dateFormat.toUpperCase()),
    dateFormat,
  };
};

export interface FormattedTime {
  time: string;
  currentDateTime: CurrentPropertyDateTime;
}

export const getCurrentPropertyDateTime =
  async (): Promise<CurrentPropertyDateTime> => {
    BaseApi.clearCache(
      new RegExp(CurrentDateApi.ApiCacheKeyPropertyLocalDateTime)
    );
    const currentPropertyRegionCode = getCurrentPropertyRegionCode();
    const currentPropertyDateTime =
      (await CurrentDateApi.getPropertyLocalDateTime({
        customConfig: currentPropertyRegionCode
          ? getCustomConfigWithPropertyRegionSpecificHost(
              currentPropertyRegionCode
            )
          : undefined,
      })) as CurrentPropertyDateTime;

    return currentPropertyDateTime;
  };

export const getFormattedTime = async (): Promise<FormattedTime> => {
  const customerDetails = getCustomerContextData()?.details;
  const propertyDetails = getPropertyContextData()?.details;

  const currentDateTime = await getCurrentPropertyDateTime();

  const timeFormatCode =
    propertyDetails?.timeFormatCode || customerDetails?.timeFormatCode || 'H24';
  const format = getTimeFormatFromCode(timeFormatCode);
  const time = formatDate(currentDateTime.localPropertyDateTime, format);

  return { time, currentDateTime };
};
