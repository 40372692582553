export interface Cancellation {
  cancel: () => void;
  invoke: () => Promise<void>;
}

export const withCancellation = <T>({
  beforeCancel,
  partWhichCanBeCancelled,
}: {
  beforeCancel: () => Promise<T>;
  partWhichCanBeCancelled: (data: T) => unknown;
}): Cancellation => {
  let isCancelled = false;
  const cancel = (): void => {
    isCancelled = true;
  };
  const invoke = async (): Promise<void> => {
    const data = await beforeCancel();
    if (!isCancelled) {
      partWhichCanBeCancelled(data);
    }
  };

  return {
    cancel,
    invoke,
  };
};
