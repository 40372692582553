import { NonEmptyArray } from 'frontend-container/utils/types';

import { BusinessContextUnitType as AccessSource } from '@ac/library-api';

export { BusinessContextUnitType as AccessSource } from '@ac/library-api';

export interface AllAccessConfiguration {
  currentSource: AccessSource;
  [AccessSource.System]: AccessConfiguration;
  [AccessSource.Tenant]: AccessConfiguration;
  [AccessSource.Property]: AccessConfiguration;
  [AccessSource.CentralReservationOffice]: AccessConfiguration;
}

export interface AccessConfiguration {
  permissions: Record<string, boolean>;
  settings: Record<string, unknown>;
  featureToggles: Record<string, boolean>;
}

export interface CustomPredicateArgs<T> {
  item: T;
  configuration: AllAccessConfiguration;
}

export enum PermissionsConjunction {
  And = 'And',
  Or = 'Or',
}

export interface MenuAccessConfiguration<TComponent = unknown> {
  permissionsConfiguration?: PermissionConfiguration;
  settings?: NonEmptyArray<MenuAccessConfigurationEntry>;
  featureToggles?: NonEmptyArray<MenuAccessConfigurationEntry>;
  allowedWhen?: (args: CustomPredicateArgs<TComponent>) => boolean;
}

export type MenuAccessConfigurationEntry =
  | string
  | { key: string; source: AccessSource };

export interface PermissionConfiguration {
  permissions: NonEmptyArray<MenuAccessConfigurationEntry>;
  permissionsConjunction: PermissionsConjunction;
}
