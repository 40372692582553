import { BusinessContextDataUpdatedEvent } from 'frontend-container/publicApi';
import { businessContextDataCache } from 'frontend-container/shared/businessContext/cache';

import {
  BaseApi,
  CurrentDate,
  isPropertyBusinessContextData,
} from '@ac/library-api';
import { CurrentDateApi } from '@ac/library-api/dist/api/v0/propertyManagement';

export const updatePropertyBusinessDate = async (): Promise<void> => {
  const container = window.ACP?.businessContext;

  if (!container || !isPropertyBusinessContextData(container.context)) {
    return;
  }

  BaseApi.clearCache(new RegExp(CurrentDateApi.ApiCacheKeyCurrentDate));
  const currentDate = (await CurrentDateApi.getCurrentDate()) as CurrentDate;

  container.context.property.businessDate = currentDate.businessDate;

  businessContextDataCache.clear();
  container.onUnitChanged?.(container.context);
  window.dispatchEvent(new BusinessContextDataUpdatedEvent());
};
