import { MenuElement } from 'frontend-container/components/Menu/types';

import { FeatureToggleName } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const integrationsMenu: MenuElement = {
  translation: 'MENU.INTEGRATIONS.TITLE',
  icon: IconName.structure,
  id: 'menu-integrations',
  items: [
    {
      link: `${acConfig.newFrontendUrls.integrations}/dashboard`,
      aliases: [acConfig.newFrontendUrls.integrations],
      translation: 'MENU.INTEGRATIONS.ITEMS.INTERFACES',
      keyboardShortcutWindows: 'alt+ctrl+\\',
      keyboardShortcutMac: '⌥+⌘+\\',
      keyCode: 220,
    },
    {
      link: `${acConfig.newFrontendUrls.integrations}/webhook-management`,
      translation: 'MENU.INTEGRATIONS.ITEMS.WEBHOOK',
    },
    {
      link: `${acConfig.newFrontendUrls.integrations}/:id/data-migration/dashboard`,
      aliases: [acConfig.newFrontendUrls.integrations],
      translation: 'MENU.INTEGRATIONS.ITEMS.DATA_MIGRATION',
      featureToggles: [FeatureToggleName.ComposedMigrations],
    },
  ],
};
