import { isCroEnabled } from 'frontend-container/components/Menu/components/CroContext/service';
import { Resource } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/data/resource';

import { DictionaryEntity, SupportedUiLanguageDto } from '@ac/library-api';
import {
  GenericDictionaryApi,
  LandingScreenApi,
  LanguageApi,
  SupportedUiLanguageApi,
} from '@ac/library-api/dist/api/v0/configuration/systemDictionaries';

export type UserPreferencesFormDictionaries = {
  languages: Resource<DictionaryEntity[]>;
  supportedUiLangages: Resource<SupportedUiLanguageDto[]>;
  workspaces: Resource<DictionaryEntity[]>;
  landingPages: Resource<DictionaryEntity[]>;
  croLandingPages: Resource<DictionaryEntity[]>;
};

export const fetchDictionaries =
  async (): Promise<UserPreferencesFormDictionaries> => {
    const [
      languages,
      supportedUiLangages,
      workspaces,
      landingPages,
      croLandingPages,
    ] = await Promise.allSettled([
      LanguageApi.getList(),
      SupportedUiLanguageApi.getList().then((result) => result.data),
      GenericDictionaryApi.getList({
        pathParams: { dictionaryType: 'Workspace' },
      }),
      LandingScreenApi.getList(),
      isCroEnabled()
        ? GenericDictionaryApi.getList({
            pathParams: {
              dictionaryType: 'CentralReservationOfficeLandingScreen',
            },
          })
        : [],
    ]);

    return {
      languages,
      supportedUiLangages,
      workspaces,
      landingPages,
      croLandingPages,
    } as UserPreferencesFormDictionaries;
  };
