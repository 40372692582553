import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  fetchCashierOpeningOptions,
  openCashier,
} from 'frontend-container/components/Menu/components/Cashier/service';
import { PasswordField } from 'frontend-container/components/Menu/components/PasswordField/PasswordField';
import { DEFAULT_LANGUAGE } from 'frontend-container/components/Menu/components/User/UserPreferences/configuration';
import {
  ApiError,
  isCurrentErrorWrongSecretError,
} from 'frontend-container/components/Menu/utils/isCurrentErrorWrongSecretError';
import { updateActivityTrackerWorkerData } from 'frontend-container/utils/activityTracker/activityTrackerInitializer';
import {
  getDefaultWorkstation,
  setDefaultWorkstation,
} from 'frontend-container/utils/defaultWorkstation';
import { getUILanguage } from 'frontend-container/utils/UILanguage';

import {
  AcSelectOption,
  Color,
  ErrorObject,
  ErrorType,
  IconName,
  TargetValueObject,
  TextSize,
  TextWeight,
} from '@ac/web-components';

interface Option {
  value?: string | number;
  name?: string;
  isOnlyExclusive?: boolean;
}

interface Props {
  onClose: () => Promise<void>;
  onConfirm: (cashierNumber: number, workstationId: string) => Promise<void>;
  isOpenCashierAllowed: boolean;
  isManageExclusiveCashierAllowed: boolean;
}

export const OpenCashierModal: FC<Props> = (props) => {
  const [workstations, setWorkstations] = useState<Option[]>([]);
  const [cashierNumbers, setCashierNumbers] = useState<Option[]>([]);
  const [workstation, setWorkstation] = useState<Option | null>(null);
  const [cashier, setCashier] = useState<Option | null>(null);
  const [secret, setSecret] = useState('');
  const [apiErrors, setApiErrors] = useState<ApiError[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCashierSelectDisabled, setCashierSelectDisabled] =
    useState<boolean>(false);

  const { onClose, onConfirm } = props;
  const { t } = useTranslation();

  const isWrongSecret = apiErrors.some(isCurrentErrorWrongSecretError());
  const language = getUILanguage();

  const initialize = async (): Promise<void> => {
    const { cashiers, workstations: initialWorkstations } =
      await fetchCashierOpeningOptions();
    const parsedCashiers = Array.isArray(cashiers)
      ? cashiers.map((c) => ({
          name: `#${c.cashierId} - ${
            c.descriptionMap[language] || c.descriptionMap[DEFAULT_LANGUAGE]
          }`,
          value: c.cashierId,
          isOnlyExclusive: c.isOnlyExclusive,
        }))
      : [];

    const parsedWorkstations = Array.isArray(initialWorkstations)
      ? initialWorkstations
          .filter(({ isActive }) => isActive)
          .map(({ descriptionMap, id }) => ({
            name: descriptionMap[language] || descriptionMap[DEFAULT_LANGUAGE],
            value: id,
          }))
      : [];

    const predefinedCashier =
      (!props.isManageExclusiveCashierAllowed &&
        parsedCashiers.find(
          ({ isOnlyExclusive }: Option) => isOnlyExclusive
        )) ||
      null;

    const initialWorkstation = getDefaultWorkstation();

    setCashierNumbers(parsedCashiers);
    setWorkstations(parsedWorkstations);
    setCashier(predefinedCashier);
    setWorkstation({
      name: initialWorkstation?.descriptionMap?.[language],
      value: initialWorkstation?.id,
    });
    setCashierSelectDisabled(Boolean(predefinedCashier));
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (): Promise<void> => {
    const cashierNumber = (cashier?.value as number) || 0;
    setApiErrors([]);
    try {
      setIsLoading(true);

      await openCashier(secret, cashierNumber);
      const { workstations: originalWorkstations } =
        await fetchCashierOpeningOptions();
      setDefaultWorkstation(
        originalWorkstations.find((item) => item.id === workstation?.value),
        cashierNumber
      );
      window.ACP?.container?.updateWorkstation?.();
      await onConfirm(cashierNumber, workstation?.value as string);
      updateActivityTrackerWorkerData();
    } catch (error) {
      setApiErrors([...apiErrors, error]);
      setIsLoading(false);
    }
  };

  const confirmButtonText = apiErrors.length
    ? 'MENU.MODAL.COMMON.TRY_AGAIN'
    : 'MENU.MODAL.COMMON.CONFIRM';

  const isConfirmButtonDisabled = !(workstation?.value && cashier && secret);

  const wrongSecretError: ErrorObject = {
    severity: ErrorType.error,
    description: t('MENU.CASHIER.OPEN.ERROR.WRONG_SECRET'),
  };

  const requestErrors = apiErrors.filter(
    isCurrentErrorWrongSecretError({ reverse: true })
  );

  const isError = !props.isOpenCashierAllowed || Boolean(requestErrors.length);
  const errorMessage = !props.isOpenCashierAllowed
    ? 'MENU.CASHIER.OPEN.ERROR.NO_PERMISSIONS'
    : 'MENU.CASHIER.OPEN.ERROR.REQUEST_FAILED';

  const handleSetWorksation = (option: AcSelectOption | undefined): void => {
    const selectedWorkstation =
      workstations.find(({ value }) => value === option?.value) || null;

    setWorkstation(selectedWorkstation);
  };

  const handleSetCashier = (option: AcSelectOption | undefined): void => {
    const selectedCashier =
      cashierNumbers.find(({ value }) => value === option?.value) || null;

    setCashier(selectedCashier);
  };

  const clearCashier = (): void => setCashier(null);

  const clearWorkstation = (): void => setWorkstation(null);

  return (
    <ac-modal
      confirmButton={{
        disabled: !props.isOpenCashierAllowed || isConfirmButtonDisabled,
        content: {
          text: t(confirmButtonText),
        },
        onClick: handleSubmit,
      }}
      cancelButton={{
        content: {
          text: t('MENU.MODAL.COMMON.CANCEL'),
        },
        onClick: onClose,
      }}
      modalTitle={t('MENU.CASHIER.OPEN.MODAL.TITLE')}
      loading={isLoading}
    >
      <form>
        <div>
          <ac-flex class="bottom-separator">
            <ac-box class="ac-padding-right-sm">
              <ac-select
                disabled={isCashierSelectDisabled}
                allowClear={isCashierSelectDisabled}
                id="cashier-select-cashier-number"
                label={t('MENU.CASHIER.OPEN.MODAL.NUMBER')}
                value={cashier?.value}
                optionsArray={cashierNumbers}
                onChangeCallback={handleSetCashier}
                optionNameField="name"
                hideMessageContainer
                translations={{ placeholder: t('MENU.MODAL.COMMON.SELECT') }}
                targetValue={TargetValueObject.modal}
                required
                onClearCallback={clearCashier}
                data-test-selector="cashier-select-cashier-number"
              />
            </ac-box>
            <ac-box class="ac-padding-left-sm">
              <PasswordField
                id="secret"
                label={t('MENU.CASHIER.OPEN.MODAL.SECRET')}
                required
                value={secret}
                onChange={setSecret}
                validationStatus={
                  isWrongSecret ? [wrongSecretError] : undefined
                }
                dataTestSelector="cashier-select-cashier-secret"
              />
            </ac-box>
          </ac-flex>
          <ac-box class="ac-spacing-bottom-md">
            <ac-text weight={TextWeight.semibold} uppercase size={TextSize.sm}>
              {t('MENU.WORKSTATION.WORKSTATION_SELECTION')}
            </ac-text>
          </ac-box>
          <ac-box>
            <ac-select
              id="cashier-select-workstation"
              label={t('MENU.WORKSTATION.WORKSTATION')}
              value={workstation?.value}
              optionsArray={workstations}
              onChangeCallback={handleSetWorksation}
              optionNameField="name"
              translations={{ placeholder: t('MENU.MODAL.COMMON.SELECT') }}
              hideMessageContainer={isError}
              targetValue={TargetValueObject.modal}
              required
              allowClear
              onClearCallback={clearWorkstation}
              data-test-selector="cashier-select-cashier-workstation"
            />
          </ac-box>
          {isError && (
            <ac-flex class="ac-spacing-top-sm">
              <ac-validation-message
                iconName={IconName.alert}
                iconColor={Color.alert}
                label={t(errorMessage)}
              />
            </ac-flex>
          )}
        </div>
      </form>
    </ac-modal>
  );
};
