import { loadAllAccessConfiguration } from 'frontend-container/components/Menu/authorization/accessConfiguration';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { mapDictionaryEntityToSelectOption } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/data/mapToSelectOption';
import { Resource } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/data/resource';
import {
  getAllowedCentralReservationOfficeMenu,
  getAllowedMainApplicationMenu,
} from 'frontend-container/components/Menu/configuration';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  BusinessContextUnitType,
  CroBusinessContextUnitIdentifier,
  DictionaryEntity,
  PropertyBusinessContextUnitIdentifier,
} from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';
import { AcSelectOption } from '@ac/web-components';

export const fetchAllowedPropertyLandingPages = async (
  propertyId: string,
  allLandingPages: DictionaryEntity[],
  defaultLandingPage: string | undefined
): Promise<Resource<Array<AcSelectOption<string>>>> => {
  return fetchAllowedLandingPages(
    { id: propertyId, type: BusinessContextUnitType.Property },
    allLandingPages,
    defaultLandingPage,
    getAllowedMainApplicationMenu
  );
};

export const fetchAllowedCroLandingPages = async (
  croId: string,
  allLandingPages: DictionaryEntity[],
  defaultLandingPage: string | undefined
): Promise<Resource<Array<AcSelectOption<string>>>> => {
  return fetchAllowedLandingPages(
    { id: croId, type: BusinessContextUnitType.CentralReservationOffice },
    allLandingPages,
    defaultLandingPage,
    getAllowedCentralReservationOfficeMenu
  );
};

const fetchAllowedLandingPages = async (
  identifier:
    | PropertyBusinessContextUnitIdentifier
    | CroBusinessContextUnitIdentifier,
  allLandingPages: DictionaryEntity[],
  defaultLandingPage: string | undefined,
  getMenuItems: (accessConfiguration: AllAccessConfiguration) => MenuElement[]
): Promise<Resource<Array<AcSelectOption<string>>>> => {
  try {
    const allAccessConfiguration = await loadAllAccessConfiguration(identifier);
    const menuElements = getMenuItems(allAccessConfiguration);

    const allowedLandingPages = allLandingPages.filter((page) =>
      menuElements.some((element) =>
        element.items.some((item) => item.code === page.code)
      )
    );
    const isDefaultLandingScreenNotAllowed = !allowedLandingPages.some(
      (page) => page.code === defaultLandingPage
    );
    const landingPagesOptions = allowedLandingPages.map((page) =>
      mapDictionaryEntityToSelectOption(page)
    );

    if (isDefaultLandingScreenNotAllowed) {
      const missingLandingPage = allLandingPages.find(
        (page) => page.code === defaultLandingPage
      );

      if (missingLandingPage) {
        landingPagesOptions.unshift(
          mapDictionaryEntityToSelectOption(missingLandingPage, true)
        );
      }
    }

    return {
      status: 'fulfilled',
      value: landingPagesOptions.filter(isDefined),
    };
  } catch (reason: unknown) {
    return { status: 'rejected', reason };
  }
};
