import { useEffect, useState } from 'react';
import { isAccountsReceivableModule } from 'frontend-container/components/Menu/utils/modules/accountsReceivable';
import { isAvailabilityModule } from 'frontend-container/components/Menu/utils/modules/availability';
import { isCashieringModule } from 'frontend-container/components/Menu/utils/modules/cashiering';
import { isHousekeepingModule } from 'frontend-container/components/Menu/utils/modules/housekeeping';
import { isIndividualReservationsModule } from 'frontend-container/components/Menu/utils/modules/individualReservations';
import { isInventoryModule } from 'frontend-container/components/Menu/utils/modules/inventory';
import { isMeetingsAndEventsModule } from 'frontend-container/components/Menu/utils/modules/meetingsAndEvents';
import { isProfilesModule } from 'frontend-container/components/Menu/utils/modules/profiles';
import { isRateManagerModule } from 'frontend-container/components/Menu/utils/modules/rateManager';
import { isReportsModule } from 'frontend-container/components/Menu/utils/modules/reports';
import { isReservationsModule } from 'frontend-container/components/Menu/utils/modules/reservations';
import { isReservationsGroupsModule } from 'frontend-container/components/Menu/utils/modules/reservations-groups';
import { isTaskManagementModule } from 'frontend-container/components/Menu/utils/modules/taskManagement';
import { isTravelAgentCommisionsModule } from 'frontend-container/components/Menu/utils/modules/travelAgentCommissions';
import { getCustomerContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import {
  EffectiveSettingDetails,
  GeneralCustomerSettingsKeys,
  PropertyAdaptersConfigurationList,
  PropertyAdapterTypes,
} from '@ac/library-api';
import { AdapterConfigurationsApi } from '@ac/library-api/dist/api/v0/integrationPropertyInterface';

export interface WorkstationVisible {
  isWorkstationVisible: boolean;
}

const pathUtilFunctions = [
  isAccountsReceivableModule,
  isMeetingsAndEventsModule,
  isTravelAgentCommisionsModule,
  isTaskManagementModule,
  isReportsModule,
  isReservationsModule,
  isIndividualReservationsModule,
  isReservationsGroupsModule,
  isHousekeepingModule,
  isRateManagerModule,
  isProfilesModule,
  isCashieringModule,
  isAvailabilityModule,
  isInventoryModule,
];

export const useIsWorkstationVisible = (): WorkstationVisible => {
  const [isWorkstationVisible, setIsWorkstationVisible] = useState(false);

  useEffect(() => {
    const isCloudPrintingEnabled = (): boolean => {
      const customerContext = getCustomerContextData();
      const settings = customerContext?.settings.effectiveSettings;

      if (!settings) {
        return false;
      }

      const cloudPrintingSetting = settings.find(
        (item) => item.code === GeneralCustomerSettingsKeys.CloudPrinting
      ) as EffectiveSettingDetails<{ value: boolean }> | undefined;

      return cloudPrintingSetting?.value?.value === true;
    };

    const setup = async (): Promise<void> => {
      const correctModule = pathUtilFunctions.some((func) => func());
      if (correctModule) {
        const cloudPrintingEnabled = isCloudPrintingEnabled();

        try {
          const interfaces =
            (await AdapterConfigurationsApi.getAll()) as PropertyAdaptersConfigurationList[];
          const validInterfaces = interfaces.filter(
            (item) =>
              item.isActive &&
              item.adapterType === PropertyAdapterTypes.ShijiIFC
          );
          setIsWorkstationVisible(
            cloudPrintingEnabled || !!validInterfaces.length
          );
        } catch {
          setIsWorkstationVisible(cloudPrintingEnabled);
        }
      } else {
        setIsWorkstationVisible(false);
      }
    };
    void setup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return {
    isWorkstationVisible,
  };
};
