import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import { customerPermissionKeys, GeneralSettingCode } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const croStructureMenu: MenuElement = {
  translation: 'MENU.CRO_STRUCTURE.TITLE',
  icon: IconName.cro,
  id: 'menu-cro-structure',
  settings: [GeneralSettingCode.CentralReservationOffice],
  permissionsConfiguration: {
    permissions: [
      customerPermissionKeys.configuration.centralReservationOffice.view,
    ],
    permissionsConjunction: PermissionsConjunction.And,
  },
  items: [
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/central-reservation-office`,
      aliases: [
        `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/central-reservation-office/:croId`,
        `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/central-reservation-office/:croId/details`,
        `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/central-reservation-office/:croId/properties`,
        `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/central-reservation-office/:croId/settings`,
      ],
      translation: 'MENU.CRO_STRUCTURE.ITEMS.DASHBOARD',
    },
  ],
};
