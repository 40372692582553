import {
  Context,
  ContextType,
} from 'frontend-container/components/Menu/components/Context';

import { SessionService } from '@ac/library-utils/dist/services';
import { getCurrentRegionCode } from '@ac/library-utils/dist/utils/multi-region';

export const setSessionContext = (
  context: Context | undefined,
  nextContextWillExistsInNewTab: boolean = false
): void => {
  if (!context || !context.id || nextContextWillExistsInNewTab) {
    return;
  }

  // We leave the current session data intact, because
  // New region = new domain, new domain = fresh localStorage/sessionStorage data
  if (
    context.regionCode &&
    !compareRegionsCode(context.regionCode, getCurrentRegionCode())
  ) {
    return;
  }

  if (context.type === ContextType.PROPERTY) {
    SessionService.setPropertyId(context.id);
  }

  if (context.type === ContextType.CRO) {
    SessionService.setCentralReservationOfficeId(context.id);
  }
};

const compareRegionsCode = (
  regionCode1: string,
  regionCode2: string
): boolean => {
  return regionCode1.toLowerCase() === regionCode2.toLowerCase();
};
