import { businessContextDataCache } from 'frontend-container/shared/businessContext/cache';
import { loadBusinessContextData } from 'frontend-container/shared/businessContext/loadBusinessContextData';
import { selectUnitInBusinessContext } from 'frontend-container/shared/businessContext/selectUnitInBusinessContext';

import {
  BusinessContextUnitIdentifier,
  BusinessContextUnitType,
  SepModuleBusinessContextData,
  SepModuleBusinessContextLoader,
} from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';

export const setInitialBusinessContextForUnit =
  async (): Promise<SepModuleBusinessContextData> => {
    const tenantId = LoginService.authData()?.tenantId;
    const initialUnit: BusinessContextUnitIdentifier = tenantId
      ? { type: BusinessContextUnitType.Tenant, id: tenantId }
      : { type: BusinessContextUnitType.System, id: undefined };

    const businessContextData = await new SepModuleBusinessContextLoader().load(
      initialUnit
    );

    businessContextDataCache.addItem(businessContextData);

    if (window.ACP) {
      window.ACP.businessContext = {
        context: businessContextData,
        onUnitChanged: undefined,
        selectUnit: selectUnitInBusinessContext,
        loadBusinessContextData,
      };
    }

    // eslint-disable-next-line no-console
    console.log(
      `[BusinessContext] Initial context saved with selected unit: ${
        initialUnit ? Object.values(initialUnit).join(', ') : '-'
      }`
    );

    return businessContextData;
  };
