import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { hasAnyCroContextGuard } from 'frontend-container/components/Menu/utils/modules/centralReservationOffice';

import {
  centralReservationOfficePermissionKeys,
  GeneralSettingCode,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const reservationsLookupMenu: MenuElement = {
  translation: 'MENU.RESERVATIONS_LOOKUP.TITLE',
  icon: IconName.reservations,
  id: 'menu-reservations-lookup',
  settings: [GeneralSettingCode.CentralReservationOffice],
  items: [
    {
      link: `${acConfig.newFrontendUrls.centralReservationOffice}/reservations-lookup`,
      translation: 'MENU.RESERVATIONS_LOOKUP.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          centralReservationOfficePermissionKeys.useReservationLookup,
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      allowedWhen: hasAnyCroContextGuard,
      code: 'ReservationLookup',
    },
  ],
};
