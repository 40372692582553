import { GenericEntity, RawGenericEntity } from '@ac/library-api';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';

type SelectedWorkstation = RawGenericEntity & {
  descriptionMap?: Record<string, string>;
};

interface SelectedWorkstations {
  [userId: string]: {
    [workstationKey: string]: SelectedWorkstation | undefined;
  };
}

enum WorkstationType {
  Cashiering = 'cashiering',
  Default = 'default',
}

export const buildWorkstationName = (
  type: WorkstationType,
  cashierNumber?: string | number
): string => {
  return `${SessionService.getPropertyId() ?? ''}.${type}${
    cashierNumber ? `.${cashierNumber}` : ''
  }`;
};

export const getDefaultWorkstationId = (): string | undefined => {
  return getDefaultWorkstation()?.id;
};

export const getDefaultWorkstation = (): SelectedWorkstation | undefined => {
  const selectedWorkstations = getSelectedWorkstations() || {};
  const cashierNumber = SessionService.getCashierNumber();
  const userId = LoginService.authData()?.userId ?? '';

  const defaultWorkstation =
    selectedWorkstations?.[userId]?.[
      buildWorkstationName(WorkstationType.Default)
    ];

  const cashieringWorkstation =
    selectedWorkstations?.[userId]?.[
      buildWorkstationName(WorkstationType.Cashiering, cashierNumber)
    ];

  return cashieringWorkstation || defaultWorkstation;
};

export const setDefaultWorkstation = (
  value?: GenericEntity,
  cashierNumber?: number
): void => {
  const storedCashierNumber = SessionService.getCashierNumber();
  const cashierValue = cashierNumber || storedCashierNumber;

  const selectedWorkstations = getSelectedWorkstations() || {};
  const userId = LoginService.authData()?.userId ?? '';

  const workstationsDraft = {
    ...(selectedWorkstations?.[userId] ?? {}),
    [buildWorkstationName(WorkstationType.Default)]: value,
  };

  if (cashierValue) {
    const cashieringWorkstationName = buildWorkstationName(
      WorkstationType.Cashiering,
      cashierValue
    );
    workstationsDraft[cashieringWorkstationName] = value;
  }

  setSelectedWorkstations({
    [userId]: workstationsDraft,
  });
};

const setSelectedWorkstations = (value: SelectedWorkstations): void => {
  try {
    const stringifiedWorkstations = JSON.stringify(value);
    SessionService.setSelectedWorkstations(stringifiedWorkstations);
  } catch (error: unknown) {
    // noop
  }
};

const getSelectedWorkstations = (): SelectedWorkstations | undefined => {
  try {
    const stringifiedWorkstations = SessionService.getSelectedWorkstations();
    if (stringifiedWorkstations) {
      return JSON.parse(stringifiedWorkstations) as SelectedWorkstations;
    }

    return undefined;
  } catch (error: unknown) {
    return undefined;
  }
};
